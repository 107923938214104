import React, { useState } from 'react';
import classNames from 'classnames';

import { Icon, Select } from 'Permafrost/index';

import { StyledCardTable } from './CardTable.styles';

const createTitle = (item) => {
  return typeof item === 'string' || typeof item === 'number' ? item : null;
};

export const CardTable = (props) => {
  const [openRowOptions, changeOpenRowOptions] = useState([]);

  const handleRowClick = (link) => {
    if (!link) {
      return null;
    }
    return (e) => {
      e.stopPropagation();
      props.history.push(link);
    };
  };

  const handleRowOptionToggleClick = (id) => {
    if (openRowOptions.indexOf(id) === -1) {
      changeOpenRowOptions([].concat(openRowOptions, id));
    } else {
      changeOpenRowOptions(openRowOptions.filter((option) => option !== id));
    }
    if (props.openRowOptionHandler) {
      props.openRowOptionHandler(id);
    }
  };

  const headers = Object.keys(props.tableObjects[0].content);
  return (
    <StyledCardTable
      className={classNames('CardTable', props.className, { loading: props.loading })}
      data-cy={props['data-cy']}
      id={props.id}
    >
      {props.sortColumnHandler && props.sortedColumn && (
        <div className="mobile-sort">
          <Select
            onChange={(e) => props.sortColumnHandler(e.target.value)}
            value={props.sortedColumn}
            options={[].concat(
              { name: 'Choose Sorting Option', value: '' },
              headers.map((item) => {
                return { name: item, value: item };
              })
            )}
          />
        </div>
      )}
      <table {...props.tableProps}>
        <thead>
          <tr>
            {props.tableObjects[0].rowAccordionContent ? <th className="action-column" /> : null}
            {headers.map((header, i) => {
              return (
                <th
                  className={classNames({
                    'not-sortable':
                      props.sortableHeaders && props.sortableHeaders.indexOf(header) === -1,
                  })}
                  onClick={() => {
                    if (props.sortColumnHandler) {
                      if (props.sortableHeaders) {
                        if (props.sortableHeaders.indexOf(header) !== -1) {
                          props.sortColumnHandler(header);
                        }
                      } else {
                        props.sortColumnHandler(header);
                      }
                    }
                  }}
                  key={i}
                >
                  {props.headersContent ? props.headersContent[header] : header}{' '}
                  {props.sortedColumn === header && (
                    <Icon name={`fa-caret-${props.sortDirection}`} />
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={classNames({ 'CardTable--tbody-loading': props.loading })}>
          {props.loading
            ? Array.from({ length: 5 }, (x, i) => (
                <tr key={i}>
                  {props.tableObjects[0].rowAccordionContent ? (
                    <td colSpan={headers.length + 1} />
                  ) : (
                    <td colSpan={headers.length} />
                  )}
                </tr>
              ))
            : props.tableObjects.map((obj, i) => {
                let rowStyle = obj.rowStyleCondition;

                if (rowStyle) {
                  rowStyle = { [rowStyle.className]: rowStyle.condition };
                }

                if (obj.rowAccordionContent) {
                  return (
                    <tr key={i} className={classNames(rowStyle)} {...props.rowProps}>
                      <td
                        colSpan={headers.length + 1}
                        style={{ padding: '0' }}
                        className="outer-hover"
                      >
                        <table>
                          <thead>
                            <tr>
                              <th
                                className="action-column"
                                style={{
                                  padding: '0',
                                  height: '1px',
                                  border: 'none',
                                }}
                              />
                              {headers.map((e, i) => {
                                return (
                                  <th
                                    key={i}
                                    style={{
                                      padding: '0',
                                      height: '1px',
                                      border: 'none',
                                    }}
                                  />
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr onClick={() => handleRowOptionToggleClick(obj.id)}>
                              <td style={{ borderBottom: 'none' }} className="action-column">
                                <Icon
                                  name="fa-angle-down"
                                  style={{
                                    transform:
                                      openRowOptions.indexOf(obj.id) !== -1 ? 'rotate(180deg)' : '',
                                  }}
                                />
                              </td>
                              {Object.keys(obj.content).map((key, i) => {
                                return (
                                  <td
                                    key={i}
                                    style={{
                                      borderBottom: 'none',
                                    }}
                                    data-heading={headers[i]}
                                    title={createTitle(obj.content[key])}
                                    {...props?.headingProps?.[headers?.[i]]}
                                  >
                                    {obj.content[key]}
                                  </td>
                                );
                              })}
                            </tr>
                            <tr
                              className={classNames('row-option', {
                                open: openRowOptions.indexOf(obj.id) !== -1,
                              })}
                            >
                              <td className="inner-table-cell" colSpan={headers.length + 1}>
                                {obj.rowAccordionContent}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  );
                } else if (obj.lineItemLink) {
                  return (
                    <tr
                      key={i}
                      onClick={handleRowClick(obj.lineItemLink)}
                      className={classNames('hover', rowStyle)}
                      {...props.rowProps}
                    >
                      {Object.keys(obj.content).map((key, i) => {
                        return (
                          <td
                            key={i}
                            data-heading={headers[i]}
                            title={createTitle(obj.content[key])}
                            {...props?.headingProps?.[headers?.[i]]}
                          >
                            {obj.content[key]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                } else {
                  return (
                    <tr key={i} className={classNames(rowStyle)} {...props.rowProps}>
                      {Object.keys(obj.content).map((key, i) => {
                        return (
                          <td
                            key={i}
                            data-heading={headers[i]}
                            title={createTitle(obj.content[key])}
                            {...props?.headingProps?.[headers?.[i]]}
                          >
                            {obj.content[key]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                }
              })}
        </tbody>
      </table>
    </StyledCardTable>
  );
};
