import { randomNormal } from 'd3-random';
var random = randomNormal(0, 0.2);
var sqrt3 = Math.sqrt(3);

function range(length) {
  return new Array(length).fill(1);
}

export function genPointsRange(length, _ref) {
  var offsetX = _ref[0],
      offsetY = _ref[1],
      index = _ref[2];
  return range(length).map(function () {
    return [random() + offsetX, random() + offsetY, index];
  });
}
export default function genPoints(count) {
  if (count === void 0) {
    count = 300;
  }

  return [].concat(genPointsRange(count, [sqrt3, 1, 0]), genPointsRange(count, [-sqrt3, 1, 1]), genPointsRange(count, [0, -1, 2]));
}