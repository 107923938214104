import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

const cardSidePadding = '19px';

export const StyledCardBody = styled.div`
  background: ${COLORS.clay};
  border-radius: 4px;
  border: 1px solid #333d4c;
  padding: 0 ${cardSidePadding};

  .CardSection:last-of-type {
    .section-bottom-border {
      border-bottom: none;
    }
  }
`;
