import findIndex from 'lodash/findIndex';

export type Field = {
  clsId: number;
  clsName: string;
  color: string;
  position: number;
  removeLabel?: boolean;
};

// TODO: Eventually, let's reomve all instances of this in favor of the above, if possible.
export type Target = {
  id: number;
  text: string;
  color: string;
  removeLabel?: boolean;
};

export const assignHotKey = (field: Field | Target, index: number, array: any) => {
  if (field.removeLabel) {
    return 0;
  } else if (
    findIndex(array, (field: Field | Target) => field.removeLabel || false) > -1 &&
    index + 1 < 21
  ) {
    return index % 10;
  } else if (index + 1 < 20) {
    return (index + 1) % 10;
  } else {
    return null;
  }
};
