var cityTemperature = [{
  date: '2011-10-01',
  'New York': '63.4',
  'San Francisco': '62.7',
  Austin: '72.2'
}, {
  date: '2011-10-02',
  'New York': '58.0',
  'San Francisco': '59.9',
  Austin: '67.7'
}, {
  date: '2011-10-03',
  'New York': '53.3',
  'San Francisco': '59.1',
  Austin: '69.4'
}, {
  date: '2011-10-04',
  'New York': '55.7',
  'San Francisco': '58.8',
  Austin: '68.0'
}, {
  date: '2011-10-05',
  'New York': '64.2',
  'San Francisco': '58.7',
  Austin: '72.4'
}, {
  date: '2011-10-06',
  'New York': '58.8',
  'San Francisco': '57.0',
  Austin: '77.0'
}, {
  date: '2011-10-07',
  'New York': '57.9',
  'San Francisco': '56.7',
  Austin: '82.3'
}, {
  date: '2011-10-08',
  'New York': '61.8',
  'San Francisco': '56.8',
  Austin: '78.9'
}, {
  date: '2011-10-09',
  'New York': '69.3',
  'San Francisco': '56.7',
  Austin: '68.8'
}, {
  date: '2011-10-10',
  'New York': '71.2',
  'San Francisco': '60.1',
  Austin: '68.7'
}, {
  date: '2011-10-11',
  'New York': '68.7',
  'San Francisco': '61.1',
  Austin: '70.3'
}, {
  date: '2011-10-12',
  'New York': '61.8',
  'San Francisco': '61.5',
  Austin: '75.3'
}, {
  date: '2011-10-13',
  'New York': '63.0',
  'San Francisco': '64.3',
  Austin: '76.6'
}, {
  date: '2011-10-14',
  'New York': '66.9',
  'San Francisco': '67.1',
  Austin: '66.6'
}, {
  date: '2011-10-15',
  'New York': '61.7',
  'San Francisco': '64.6',
  Austin: '68.0'
}, {
  date: '2011-10-16',
  'New York': '61.8',
  'San Francisco': '61.6',
  Austin: '70.6'
}, {
  date: '2011-10-17',
  'New York': '62.8',
  'San Francisco': '61.1',
  Austin: '71.1'
}, {
  date: '2011-10-18',
  'New York': '60.8',
  'San Francisco': '59.2',
  Austin: '70.0'
}, {
  date: '2011-10-19',
  'New York': '62.1',
  'San Francisco': '58.9',
  Austin: '61.6'
}, {
  date: '2011-10-20',
  'New York': '65.1',
  'San Francisco': '57.2',
  Austin: '57.4'
}, {
  date: '2011-10-21',
  'New York': '55.6',
  'San Francisco': '56.4',
  Austin: '64.3'
}, {
  date: '2011-10-22',
  'New York': '54.4',
  'San Francisco': '60.7',
  Austin: '72.4'
}, {
  date: '2011-10-23',
  'New York': '54.4',
  'San Francisco': '65.1',
  Austin: '72.4'
}, {
  date: '2011-10-24',
  'New York': '54.8',
  'San Francisco': '60.9',
  Austin: '72.5'
}, {
  date: '2011-10-25',
  'New York': '57.9',
  'San Francisco': '56.1',
  Austin: '72.7'
}, {
  date: '2011-10-26',
  'New York': '54.6',
  'San Francisco': '54.6',
  Austin: '73.4'
}, {
  date: '2011-10-27',
  'New York': '54.4',
  'San Francisco': '56.1',
  Austin: '70.7'
}, {
  date: '2011-10-28',
  'New York': '42.5',
  'San Francisco': '58.1',
  Austin: '56.8'
}, {
  date: '2011-10-29',
  'New York': '40.9',
  'San Francisco': '57.5',
  Austin: '51.0'
}, {
  date: '2011-10-30',
  'New York': '38.6',
  'San Francisco': '57.7',
  Austin: '54.9'
}, {
  date: '2011-10-31',
  'New York': '44.2',
  'San Francisco': '55.1',
  Austin: '58.8'
}, {
  date: '2011-11-01',
  'New York': '49.6',
  'San Francisco': '57.9',
  Austin: '62.6'
}, {
  date: '2011-11-02',
  'New York': '47.2',
  'San Francisco': '64.6',
  Austin: '71.0'
}, {
  date: '2011-11-03',
  'New York': '50.1',
  'San Francisco': '56.2',
  Austin: '58.4'
}, {
  date: '2011-11-04',
  'New York': '50.1',
  'San Francisco': '50.5',
  Austin: '45.1'
}, {
  date: '2011-11-05',
  'New York': '43.5',
  'San Francisco': '51.3',
  Austin: '52.2'
}, {
  date: '2011-11-06',
  'New York': '43.8',
  'San Francisco': '52.6',
  Austin: '73.0'
}, {
  date: '2011-11-07',
  'New York': '48.9',
  'San Francisco': '51.4',
  Austin: '75.4'
}, {
  date: '2011-11-08',
  'New York': '55.5',
  'San Francisco': '50.6',
  Austin: '72.1'
}, {
  date: '2011-11-09',
  'New York': '53.7',
  'San Francisco': '54.6',
  Austin: '56.6'
}, {
  date: '2011-11-10',
  'New York': '57.7',
  'San Francisco': '55.6',
  Austin: '55.4'
}, {
  date: '2011-11-11',
  'New York': '48.5',
  'San Francisco': '53.9',
  Austin: '46.7'
}, {
  date: '2011-11-12',
  'New York': '46.8',
  'San Francisco': '54.0',
  Austin: '62.0'
}, {
  date: '2011-11-13',
  'New York': '51.1',
  'San Francisco': '53.8',
  Austin: '71.6'
}, {
  date: '2011-11-14',
  'New York': '56.8',
  'San Francisco': '53.5',
  Austin: '75.5'
}, {
  date: '2011-11-15',
  'New York': '59.7',
  'San Francisco': '53.4',
  Austin: '72.1'
}, {
  date: '2011-11-16',
  'New York': '56.5',
  'San Francisco': '52.2',
  Austin: '65.7'
}, {
  date: '2011-11-17',
  'New York': '49.6',
  'San Francisco': '52.7',
  Austin: '56.8'
}, {
  date: '2011-11-18',
  'New York': '41.5',
  'San Francisco': '53.1',
  Austin: '49.9'
}, {
  date: '2011-11-19',
  'New York': '44.3',
  'San Francisco': '49.0',
  Austin: '71.7'
}, {
  date: '2011-11-20',
  'New York': '54.0',
  'San Francisco': '50.4',
  Austin: '77.7'
}, {
  date: '2011-11-21',
  'New York': '54.1',
  'San Francisco': '51.1',
  Austin: '76.4'
}, {
  date: '2011-11-22',
  'New York': '49.4',
  'San Francisco': '52.3',
  Austin: '68.8'
}, {
  date: '2011-11-23',
  'New York': '50.0',
  'San Francisco': '54.6',
  Austin: '57.0'
}, {
  date: '2011-11-24',
  'New York': '44.0',
  'San Francisco': '55.1',
  Austin: '55.5'
}, {
  date: '2011-11-25',
  'New York': '50.3',
  'San Francisco': '51.5',
  Austin: '61.6'
}, {
  date: '2011-11-26',
  'New York': '52.1',
  'San Francisco': '53.6',
  Austin: '64.1'
}, {
  date: '2011-11-27',
  'New York': '49.6',
  'San Francisco': '52.3',
  Austin: '51.1'
}, {
  date: '2011-11-28',
  'New York': '57.2',
  'San Francisco': '51.0',
  Austin: '43.0'
}, {
  date: '2011-11-29',
  'New York': '59.1',
  'San Francisco': '49.5',
  Austin: '46.4'
}, {
  date: '2011-11-30',
  'New York': '50.6',
  'San Francisco': '49.8',
  Austin: '48.0'
}, {
  date: '2011-12-01',
  'New York': '44.3',
  'San Francisco': '60.4',
  Austin: '48.1'
}, {
  date: '2011-12-02',
  'New York': '43.9',
  'San Francisco': '62.2',
  Austin: '60.6'
}, {
  date: '2011-12-03',
  'New York': '42.1',
  'San Francisco': '58.3',
  Austin: '62.6'
}, {
  date: '2011-12-04',
  'New York': '43.9',
  'San Francisco': '52.7',
  Austin: '57.1'
}, {
  date: '2011-12-05',
  'New York': '50.2',
  'San Francisco': '51.5',
  Austin: '44.2'
}, {
  date: '2011-12-06',
  'New York': '54.2',
  'San Francisco': '49.9',
  Austin: '37.4'
}, {
  date: '2011-12-07',
  'New York': '54.6',
  'San Francisco': '48.6',
  Austin: '35.0'
}, {
  date: '2011-12-08',
  'New York': '43.4',
  'San Francisco': '46.4',
  Austin: '37.0'
}, {
  date: '2011-12-09',
  'New York': '42.2',
  'San Francisco': '49.8',
  Austin: '45.4'
}, {
  date: '2011-12-10',
  'New York': '45.0',
  'San Francisco': '52.1',
  Austin: '50.7'
}, {
  date: '2011-12-11',
  'New York': '33.8',
  'San Francisco': '48.8',
  Austin: '48.6'
}, {
  date: '2011-12-12',
  'New York': '36.8',
  'San Francisco': '47.4',
  Austin: '52.2'
}, {
  date: '2011-12-13',
  'New York': '38.6',
  'San Francisco': '47.2',
  Austin: '60.8'
}, {
  date: '2011-12-14',
  'New York': '41.9',
  'San Francisco': '46.1',
  Austin: '70.0'
}, {
  date: '2011-12-15',
  'New York': '49.6',
  'San Francisco': '48.8',
  Austin: '64.2'
}, {
  date: '2011-12-16',
  'New York': '50.2',
  'San Francisco': '47.9',
  Austin: '50.9'
}, {
  date: '2011-12-17',
  'New York': '40.6',
  'San Francisco': '49.8',
  Austin: '51.6'
}, {
  date: '2011-12-18',
  'New York': '29.1',
  'San Francisco': '49.1',
  Austin: '55.2'
}, {
  date: '2011-12-19',
  'New York': '33.7',
  'San Francisco': '48.3',
  Austin: '62.1'
}, {
  date: '2011-12-20',
  'New York': '45.8',
  'San Francisco': '49.3',
  Austin: '56.3'
}, {
  date: '2011-12-21',
  'New York': '47.4',
  'San Francisco': '48.4',
  Austin: '47.2'
}, {
  date: '2011-12-22',
  'New York': '54.4',
  'San Francisco': '53.3',
  Austin: '52.3'
}, {
  date: '2011-12-23',
  'New York': '47.8',
  'San Francisco': '47.5',
  Austin: '45.2'
}, {
  date: '2011-12-24',
  'New York': '34.9',
  'San Francisco': '47.9',
  Austin: '43.6'
}, {
  date: '2011-12-25',
  'New York': '35.9',
  'San Francisco': '48.9',
  Austin: '42.9'
}, {
  date: '2011-12-26',
  'New York': '43.6',
  'San Francisco': '45.9',
  Austin: '48.2'
}, {
  date: '2011-12-27',
  'New York': '42.9',
  'San Francisco': '47.2',
  Austin: '45.4'
}, {
  date: '2011-12-28',
  'New York': '46.2',
  'San Francisco': '48.9',
  Austin: '44.2'
}, {
  date: '2011-12-29',
  'New York': '30.8',
  'San Francisco': '50.9',
  Austin: '50.4'
}, {
  date: '2011-12-30',
  'New York': '40.8',
  'San Francisco': '52.9',
  Austin: '52.4'
}, {
  date: '2011-12-31',
  'New York': '49.8',
  'San Francisco': '50.1',
  Austin: '53.5'
}, {
  date: '2012-01-01',
  'New York': '46.3',
  'San Francisco': '53.9',
  Austin: '55.9'
}, {
  date: '2012-01-02',
  'New York': '43.2',
  'San Francisco': '53.1',
  Austin: '48.2'
}, {
  date: '2012-01-03',
  'New York': '30.3',
  'San Francisco': '49.7',
  Austin: '41.0'
}, {
  date: '2012-01-04',
  'New York': '19.2',
  'San Francisco': '52.7',
  Austin: '48.9'
}, {
  date: '2012-01-05',
  'New York': '32.1',
  'San Francisco': '52.6',
  Austin: '54.8'
}, {
  date: '2012-01-06',
  'New York': '41.2',
  'San Francisco': '49.0',
  Austin: '61.2'
}, {
  date: '2012-01-07',
  'New York': '47.0',
  'San Francisco': '51.0',
  Austin: '59.7'
}, {
  date: '2012-01-08',
  'New York': '46.0',
  'San Francisco': '56.8',
  Austin: '52.5'
}, {
  date: '2012-01-09',
  'New York': '34.7',
  'San Francisco': '52.3',
  Austin: '54.0'
}, {
  date: '2012-01-10',
  'New York': '39.4',
  'San Francisco': '51.6',
  Austin: '47.7'
}, {
  date: '2012-01-11',
  'New York': '40.4',
  'San Francisco': '49.8',
  Austin: '49.2'
}, {
  date: '2012-01-12',
  'New York': '45.4',
  'San Francisco': '51.9',
  Austin: '48.4'
}, {
  date: '2012-01-13',
  'New York': '40.7',
  'San Francisco': '53.7',
  Austin: '40.2'
}, {
  date: '2012-01-14',
  'New York': '30.4',
  'San Francisco': '52.9',
  Austin: '43.9'
}, {
  date: '2012-01-15',
  'New York': '23.9',
  'San Francisco': '49.7',
  Austin: '45.2'
}, {
  date: '2012-01-16',
  'New York': '22.6',
  'San Francisco': '45.3',
  Austin: '65.0'
}, {
  date: '2012-01-17',
  'New York': '39.8',
  'San Francisco': '43.6',
  Austin: '68.2'
}, {
  date: '2012-01-18',
  'New York': '43.2',
  'San Francisco': '45.0',
  Austin: '47.5'
}, {
  date: '2012-01-19',
  'New York': '26.3',
  'San Francisco': '47.3',
  Austin: '57.1'
}, {
  date: '2012-01-20',
  'New York': '32.8',
  'San Francisco': '51.4',
  Austin: '61.9'
}, {
  date: '2012-01-21',
  'New York': '27.4',
  'San Francisco': '53.7',
  Austin: '54.6'
}, {
  date: '2012-01-22',
  'New York': '25.0',
  'San Francisco': '48.3',
  Austin: '56.7'
}, {
  date: '2012-01-23',
  'New York': '39.4',
  'San Francisco': '52.9',
  Austin: '54.4'
}, {
  date: '2012-01-24',
  'New York': '48.7',
  'San Francisco': '49.1',
  Austin: '52.7'
}, {
  date: '2012-01-25',
  'New York': '43.0',
  'San Francisco': '52.1',
  Austin: '61.8'
}, {
  date: '2012-01-26',
  'New York': '37.1',
  'San Francisco': '53.6',
  Austin: '55.0'
}, {
  date: '2012-01-27',
  'New York': '48.2',
  'San Francisco': '50.4',
  Austin: '50.7'
}, {
  date: '2012-01-28',
  'New York': '43.7',
  'San Francisco': '50.3',
  Austin: '52.9'
}, {
  date: '2012-01-29',
  'New York': '40.1',
  'San Francisco': '53.8',
  Austin: '44.4'
}, {
  date: '2012-01-30',
  'New York': '38.0',
  'San Francisco': '51.9',
  Austin: '49.1'
}, {
  date: '2012-01-31',
  'New York': '43.5',
  'San Francisco': '50.0',
  Austin: '62.8'
}, {
  date: '2012-02-01',
  'New York': '50.4',
  'San Francisco': '50.0',
  Austin: '64.6'
}, {
  date: '2012-02-02',
  'New York': '45.8',
  'San Francisco': '51.3',
  Austin: '61.1'
}, {
  date: '2012-02-03',
  'New York': '37.5',
  'San Francisco': '51.5',
  Austin: '70.0'
}, {
  date: '2012-02-04',
  'New York': '40.8',
  'San Francisco': '52.0',
  Austin: '61.3'
}, {
  date: '2012-02-05',
  'New York': '36.5',
  'San Francisco': '53.8',
  Austin: '48.2'
}, {
  date: '2012-02-06',
  'New York': '39.1',
  'San Francisco': '54.6',
  Austin: '44.2'
}, {
  date: '2012-02-07',
  'New York': '43.2',
  'San Francisco': '54.3',
  Austin: '51.3'
}, {
  date: '2012-02-08',
  'New York': '36.5',
  'San Francisco': '51.9',
  Austin: '49.2'
}, {
  date: '2012-02-09',
  'New York': '36.5',
  'San Francisco': '53.8',
  Austin: '45.7'
}, {
  date: '2012-02-10',
  'New York': '38.3',
  'San Francisco': '53.9',
  Austin: '54.1'
}, {
  date: '2012-02-11',
  'New York': '36.9',
  'San Francisco': '52.3',
  Austin: '44.9'
}, {
  date: '2012-02-12',
  'New York': '29.7',
  'San Francisco': '50.1',
  Austin: '36.5'
}, {
  date: '2012-02-13',
  'New York': '33.1',
  'San Francisco': '49.5',
  Austin: '44.8'
}, {
  date: '2012-02-14',
  'New York': '39.6',
  'San Francisco': '48.6',
  Austin: '52.3'
}, {
  date: '2012-02-15',
  'New York': '42.3',
  'San Francisco': '49.9',
  Austin: '68.0'
}, {
  date: '2012-02-16',
  'New York': '39.7',
  'San Francisco': '52.4',
  Austin: '54.6'
}, {
  date: '2012-02-17',
  'New York': '46.0',
  'San Francisco': '49.9',
  Austin: '53.8'
}, {
  date: '2012-02-18',
  'New York': '41.2',
  'San Francisco': '51.6',
  Austin: '56.2'
}, {
  date: '2012-02-19',
  'New York': '39.8',
  'San Francisco': '47.8',
  Austin: '50.8'
}, {
  date: '2012-02-20',
  'New York': '38.1',
  'San Francisco': '48.7',
  Austin: '53.0'
}, {
  date: '2012-02-21',
  'New York': '37.1',
  'San Francisco': '49.7',
  Austin: '61.0'
}, {
  date: '2012-02-22',
  'New York': '45.5',
  'San Francisco': '53.4',
  Austin: '68.8'
}, {
  date: '2012-02-23',
  'New York': '50.6',
  'San Francisco': '54.1',
  Austin: '69.4'
}, {
  date: '2012-02-24',
  'New York': '42.7',
  'San Francisco': '55.9',
  Austin: '59.3'
}, {
  date: '2012-02-25',
  'New York': '42.6',
  'San Francisco': '51.7',
  Austin: '47.2'
}, {
  date: '2012-02-26',
  'New York': '36.9',
  'San Francisco': '47.7',
  Austin: '47.7'
}, {
  date: '2012-02-27',
  'New York': '40.9',
  'San Francisco': '45.4',
  Austin: '61.9'
}, {
  date: '2012-02-28',
  'New York': '45.9',
  'San Francisco': '47.0',
  Austin: '67.2'
}, {
  date: '2012-02-29',
  'New York': '40.7',
  'San Francisco': '49.8',
  Austin: '70.1'
}, {
  date: '2012-03-01',
  'New York': '41.3',
  'San Francisco': '48.9',
  Austin: '62.1'
}, {
  date: '2012-03-02',
  'New York': '36.8',
  'San Francisco': '48.1',
  Austin: '72.7'
}, {
  date: '2012-03-03',
  'New York': '47.6',
  'San Francisco': '50.7',
  Austin: '59.0'
}, {
  date: '2012-03-04',
  'New York': '44.2',
  'San Francisco': '55.0',
  Austin: '51.8'
}, {
  date: '2012-03-05',
  'New York': '38.5',
  'San Francisco': '48.8',
  Austin: '55.0'
}, {
  date: '2012-03-06',
  'New York': '32.9',
  'San Francisco': '48.4',
  Austin: '61.8'
}, {
  date: '2012-03-07',
  'New York': '43.3',
  'San Francisco': '49.9',
  Austin: '67.1'
}, {
  date: '2012-03-08',
  'New York': '51.2',
  'San Francisco': '49.2',
  Austin: '72.0'
}, {
  date: '2012-03-09',
  'New York': '47.8',
  'San Francisco': '51.7',
  Austin: '46.4'
}, {
  date: '2012-03-10',
  'New York': '37.2',
  'San Francisco': '49.3',
  Austin: '46.7'
}, {
  date: '2012-03-11',
  'New York': '42.9',
  'San Francisco': '50.0',
  Austin: '56.9'
}, {
  date: '2012-03-12',
  'New York': '48.8',
  'San Francisco': '48.6',
  Austin: '61.9'
}, {
  date: '2012-03-13',
  'New York': '52.6',
  'San Francisco': '53.9',
  Austin: '68.8'
}, {
  date: '2012-03-14',
  'New York': '60.5',
  'San Francisco': '55.2',
  Austin: '71.9'
}, {
  date: '2012-03-15',
  'New York': '47.2',
  'San Francisco': '55.9',
  Austin: '72.0'
}, {
  date: '2012-03-16',
  'New York': '44.7',
  'San Francisco': '54.6',
  Austin: '72.5'
}, {
  date: '2012-03-17',
  'New York': '48.2',
  'San Francisco': '48.2',
  Austin: '71.7'
}, {
  date: '2012-03-18',
  'New York': '48.2',
  'San Francisco': '47.1',
  Austin: '71.1'
}, {
  date: '2012-03-19',
  'New York': '53.1',
  'San Francisco': '45.8',
  Austin: '73.0'
}, {
  date: '2012-03-20',
  'New York': '57.8',
  'San Francisco': '49.7',
  Austin: '63.8'
}, {
  date: '2012-03-21',
  'New York': '57.5',
  'San Francisco': '51.4',
  Austin: '60.0'
}, {
  date: '2012-03-22',
  'New York': '57.3',
  'San Francisco': '51.4',
  Austin: '62.3'
}, {
  date: '2012-03-23',
  'New York': '61.7',
  'San Francisco': '48.4',
  Austin: '61.1'
}, {
  date: '2012-03-24',
  'New York': '55.8',
  'San Francisco': '49.0',
  Austin: '62.0'
}, {
  date: '2012-03-25',
  'New York': '48.4',
  'San Francisco': '46.4',
  Austin: '64.6'
}, {
  date: '2012-03-26',
  'New York': '49.8',
  'San Francisco': '49.7',
  Austin: '66.0'
}, {
  date: '2012-03-27',
  'New York': '39.6',
  'San Francisco': '54.1',
  Austin: '65.8'
}, {
  date: '2012-03-28',
  'New York': '49.7',
  'San Francisco': '54.6',
  Austin: '69.2'
}, {
  date: '2012-03-29',
  'New York': '56.8',
  'San Francisco': '52.3',
  Austin: '69.5'
}, {
  date: '2012-03-30',
  'New York': '46.5',
  'San Francisco': '54.5',
  Austin: '73.5'
}, {
  date: '2012-03-31',
  'New York': '42.2',
  'San Francisco': '56.2',
  Austin: '73.9'
}, {
  date: '2012-04-01',
  'New York': '45.3',
  'San Francisco': '51.1',
  Austin: '75.3'
}, {
  date: '2012-04-02',
  'New York': '48.1',
  'San Francisco': '50.5',
  Austin: '75.4'
}, {
  date: '2012-04-03',
  'New York': '51.2',
  'San Francisco': '52.2',
  Austin: '77.3'
}, {
  date: '2012-04-04',
  'New York': '61.0',
  'San Francisco': '50.6',
  Austin: '67.0'
}, {
  date: '2012-04-05',
  'New York': '50.7',
  'San Francisco': '47.9',
  Austin: '71.1'
}, {
  date: '2012-04-06',
  'New York': '48.0',
  'San Francisco': '47.4',
  Austin: '70.4'
}, {
  date: '2012-04-07',
  'New York': '51.1',
  'San Francisco': '49.4',
  Austin: '73.6'
}, {
  date: '2012-04-08',
  'New York': '55.7',
  'San Francisco': '50.0',
  Austin: '71.1'
}, {
  date: '2012-04-09',
  'New York': '58.3',
  'San Francisco': '51.3',
  Austin: '70.0'
}, {
  date: '2012-04-10',
  'New York': '55.0',
  'San Francisco': '53.8',
  Austin: '69.0'
}, {
  date: '2012-04-11',
  'New York': '49.0',
  'San Francisco': '52.9',
  Austin: '69.2'
}, {
  date: '2012-04-12',
  'New York': '51.7',
  'San Francisco': '53.9',
  Austin: '74.5'
}, {
  date: '2012-04-13',
  'New York': '53.1',
  'San Francisco': '50.2',
  Austin: '73.4'
}, {
  date: '2012-04-14',
  'New York': '55.2',
  'San Francisco': '50.9',
  Austin: '76.0'
}, {
  date: '2012-04-15',
  'New York': '62.3',
  'San Francisco': '51.5',
  Austin: '74.5'
}, {
  date: '2012-04-16',
  'New York': '62.9',
  'San Francisco': '51.9',
  Austin: '63.6'
}, {
  date: '2012-04-17',
  'New York': '69.3',
  'San Francisco': '53.2',
  Austin: '67.3'
}, {
  date: '2012-04-18',
  'New York': '59.0',
  'San Francisco': '53.0',
  Austin: '65.1'
}, {
  date: '2012-04-19',
  'New York': '54.1',
  'San Francisco': '55.1',
  Austin: '67.9'
}, {
  date: '2012-04-20',
  'New York': '56.5',
  'San Francisco': '55.8',
  Austin: '68.9'
}, {
  date: '2012-04-21',
  'New York': '58.2',
  'San Francisco': '58.0',
  Austin: '65.1'
}, {
  date: '2012-04-22',
  'New York': '52.4',
  'San Francisco': '52.8',
  Austin: '65.4'
}, {
  date: '2012-04-23',
  'New York': '51.6',
  'San Francisco': '55.1',
  Austin: '70.1'
}, {
  date: '2012-04-24',
  'New York': '49.3',
  'San Francisco': '57.9',
  Austin: '67.0'
}, {
  date: '2012-04-25',
  'New York': '52.5',
  'San Francisco': '57.5',
  Austin: '75.4'
}, {
  date: '2012-04-26',
  'New York': '50.5',
  'San Francisco': '55.3',
  Austin: '77.5'
}, {
  date: '2012-04-27',
  'New York': '51.9',
  'San Francisco': '53.5',
  Austin: '77.0'
}, {
  date: '2012-04-28',
  'New York': '47.4',
  'San Francisco': '54.7',
  Austin: '77.7'
}, {
  date: '2012-04-29',
  'New York': '54.1',
  'San Francisco': '54.0',
  Austin: '77.7'
}, {
  date: '2012-04-30',
  'New York': '51.9',
  'San Francisco': '53.4',
  Austin: '77.7'
}, {
  date: '2012-05-01',
  'New York': '57.4',
  'San Francisco': '52.7',
  Austin: '77.0'
}, {
  date: '2012-05-02',
  'New York': '53.7',
  'San Francisco': '50.7',
  Austin: '77.9'
}, {
  date: '2012-05-03',
  'New York': '53.1',
  'San Francisco': '52.6',
  Austin: '79.1'
}, {
  date: '2012-05-04',
  'New York': '57.2',
  'San Francisco': '53.4',
  Austin: '80.1'
}, {
  date: '2012-05-05',
  'New York': '57.0',
  'San Francisco': '53.1',
  Austin: '82.1'
}, {
  date: '2012-05-06',
  'New York': '56.6',
  'San Francisco': '56.5',
  Austin: '79.0'
}, {
  date: '2012-05-07',
  'New York': '54.6',
  'San Francisco': '55.3',
  Austin: '79.8'
}, {
  date: '2012-05-08',
  'New York': '57.9',
  'San Francisco': '52.0',
  Austin: '70.0'
}, {
  date: '2012-05-09',
  'New York': '59.2',
  'San Francisco': '52.4',
  Austin: '69.8'
}, {
  date: '2012-05-10',
  'New York': '61.1',
  'San Francisco': '53.4',
  Austin: '71.3'
}, {
  date: '2012-05-11',
  'New York': '59.7',
  'San Francisco': '53.1',
  Austin: '69.4'
}, {
  date: '2012-05-12',
  'New York': '64.1',
  'San Francisco': '49.9',
  Austin: '72.0'
}, {
  date: '2012-05-13',
  'New York': '65.3',
  'San Francisco': '52.0',
  Austin: '72.4'
}, {
  date: '2012-05-14',
  'New York': '64.2',
  'San Francisco': '56.0',
  Austin: '72.5'
}, {
  date: '2012-05-15',
  'New York': '62.0',
  'San Francisco': '53.0',
  Austin: '67.6'
}, {
  date: '2012-05-16',
  'New York': '63.8',
  'San Francisco': '51.0',
  Austin: '69.0'
}, {
  date: '2012-05-17',
  'New York': '64.5',
  'San Francisco': '51.4',
  Austin: '72.7'
}, {
  date: '2012-05-18',
  'New York': '61.0',
  'San Francisco': '52.2',
  Austin: '73.7'
}, {
  date: '2012-05-19',
  'New York': '62.6',
  'San Francisco': '52.4',
  Austin: '77.5'
}, {
  date: '2012-05-20',
  'New York': '66.2',
  'San Francisco': '54.5',
  Austin: '75.8'
}, {
  date: '2012-05-21',
  'New York': '62.7',
  'San Francisco': '52.8',
  Austin: '76.9'
}, {
  date: '2012-05-22',
  'New York': '63.7',
  'San Francisco': '53.9',
  Austin: '78.8'
}, {
  date: '2012-05-23',
  'New York': '66.4',
  'San Francisco': '56.5',
  Austin: '77.7'
}, {
  date: '2012-05-24',
  'New York': '64.5',
  'San Francisco': '54.7',
  Austin: '80.6'
}, {
  date: '2012-05-25',
  'New York': '65.4',
  'San Francisco': '52.5',
  Austin: '81.4'
}, {
  date: '2012-05-26',
  'New York': '69.4',
  'San Francisco': '52.1',
  Austin: '82.3'
}, {
  date: '2012-05-27',
  'New York': '71.9',
  'San Francisco': '52.2',
  Austin: '80.3'
}, {
  date: '2012-05-28',
  'New York': '74.4',
  'San Francisco': '52.9',
  Austin: '80.3'
}, {
  date: '2012-05-29',
  'New York': '75.9',
  'San Francisco': '52.1',
  Austin: '82.2'
}, {
  date: '2012-05-30',
  'New York': '72.9',
  'San Francisco': '52.1',
  Austin: '81.9'
}, {
  date: '2012-05-31',
  'New York': '72.5',
  'San Francisco': '53.3',
  Austin: '82.4'
}, {
  date: '2012-06-01',
  'New York': '67.2',
  'San Francisco': '54.8',
  Austin: '77.9'
}, {
  date: '2012-06-02',
  'New York': '68.3',
  'San Francisco': '54.0',
  Austin: '81.1'
}, {
  date: '2012-06-03',
  'New York': '67.7',
  'San Francisco': '52.3',
  Austin: '82.2'
}, {
  date: '2012-06-04',
  'New York': '61.9',
  'San Francisco': '55.3',
  Austin: '81.2'
}, {
  date: '2012-06-05',
  'New York': '58.3',
  'San Francisco': '53.5',
  Austin: '83.0'
}, {
  date: '2012-06-06',
  'New York': '61.7',
  'San Francisco': '54.1',
  Austin: '83.2'
}, {
  date: '2012-06-07',
  'New York': '66.7',
  'San Francisco': '53.9',
  Austin: '82.1'
}, {
  date: '2012-06-08',
  'New York': '68.7',
  'San Francisco': '54.4',
  Austin: '77.5'
}, {
  date: '2012-06-09',
  'New York': '72.2',
  'San Francisco': '55.0',
  Austin: '77.9'
}, {
  date: '2012-06-10',
  'New York': '72.6',
  'San Francisco': '60.0',
  Austin: '82.9'
}, {
  date: '2012-06-11',
  'New York': '69.2',
  'San Francisco': '57.2',
  Austin: '86.8'
}, {
  date: '2012-06-12',
  'New York': '66.9',
  'San Francisco': '55.1',
  Austin: '85.3'
}, {
  date: '2012-06-13',
  'New York': '66.7',
  'San Francisco': '53.3',
  Austin: '76.9'
}, {
  date: '2012-06-14',
  'New York': '67.7',
  'San Francisco': '53.4',
  Austin: '84.5'
}, {
  date: '2012-06-15',
  'New York': '68.5',
  'San Francisco': '54.6',
  Austin: '84.4'
}, {
  date: '2012-06-16',
  'New York': '67.5',
  'San Francisco': '57.0',
  Austin: '83.8'
}, {
  date: '2012-06-17',
  'New York': '64.2',
  'San Francisco': '55.6',
  Austin: '82.5'
}, {
  date: '2012-06-18',
  'New York': '61.7',
  'San Francisco': '52.5',
  Austin: '82.9'
}, {
  date: '2012-06-19',
  'New York': '66.4',
  'San Francisco': '53.9',
  Austin: '82.5'
}, {
  date: '2012-06-20',
  'New York': '77.9',
  'San Francisco': '55.3',
  Austin: '81.3'
}, {
  date: '2012-06-21',
  'New York': '88.3',
  'San Francisco': '53.3',
  Austin: '80.8'
}, {
  date: '2012-06-22',
  'New York': '82.2',
  'San Francisco': '54.1',
  Austin: '81.7'
}, {
  date: '2012-06-23',
  'New York': '77.0',
  'San Francisco': '55.2',
  Austin: '83.9'
}, {
  date: '2012-06-24',
  'New York': '75.4',
  'San Francisco': '55.8',
  Austin: '85.5'
}, {
  date: '2012-06-25',
  'New York': '70.9',
  'San Francisco': '56.8',
  Austin: '87.2'
}, {
  date: '2012-06-26',
  'New York': '65.9',
  'San Francisco': '57.5',
  Austin: '88.0'
}, {
  date: '2012-06-27',
  'New York': '73.5',
  'San Francisco': '57.7',
  Austin: '89.6'
}, {
  date: '2012-06-28',
  'New York': '77.4',
  'San Francisco': '56.6',
  Austin: '86.7'
}, {
  date: '2012-06-29',
  'New York': '79.6',
  'San Francisco': '56.4',
  Austin: '85.3'
}, {
  date: '2012-06-30',
  'New York': '84.2',
  'San Francisco': '58.4',
  Austin: '81.7'
}, {
  date: '2012-07-01',
  'New York': '81.8',
  'San Francisco': '58.8',
  Austin: '78.5'
}, {
  date: '2012-07-02',
  'New York': '82.5',
  'San Francisco': '56.4',
  Austin: '83.1'
}, {
  date: '2012-07-03',
  'New York': '80.2',
  'San Francisco': '56.5',
  Austin: '83.1'
}, {
  date: '2012-07-04',
  'New York': '77.8',
  'San Francisco': '55.8',
  Austin: '84.5'
}, {
  date: '2012-07-05',
  'New York': '86.1',
  'San Francisco': '54.8',
  Austin: '84.6'
}, {
  date: '2012-07-06',
  'New York': '79.9',
  'San Francisco': '54.9',
  Austin: '84.2'
}, {
  date: '2012-07-07',
  'New York': '83.5',
  'San Francisco': '54.7',
  Austin: '86.7'
}, {
  date: '2012-07-08',
  'New York': '81.5',
  'San Francisco': '52.8',
  Austin: '84.3'
}, {
  date: '2012-07-09',
  'New York': '77.8',
  'San Francisco': '53.7',
  Austin: '83.7'
}, {
  date: '2012-07-10',
  'New York': '76.1',
  'San Francisco': '53.1',
  Austin: '77.1'
}, {
  date: '2012-07-11',
  'New York': '76.3',
  'San Francisco': '52.7',
  Austin: '77.4'
}, {
  date: '2012-07-12',
  'New York': '75.8',
  'San Francisco': '52.0',
  Austin: '80.6'
}, {
  date: '2012-07-13',
  'New York': '77.2',
  'San Francisco': '53.4',
  Austin: '81.4'
}, {
  date: '2012-07-14',
  'New York': '79.3',
  'San Francisco': '54.0',
  Austin: '80.2'
}, {
  date: '2012-07-15',
  'New York': '78.9',
  'San Francisco': '54.0',
  Austin: '81.8'
}, {
  date: '2012-07-16',
  'New York': '79.6',
  'San Francisco': '54.5',
  Austin: '77.3'
}, {
  date: '2012-07-17',
  'New York': '83.3',
  'San Francisco': '56.7',
  Austin: '80.8'
}, {
  date: '2012-07-18',
  'New York': '84.3',
  'San Francisco': '57.5',
  Austin: '81.6'
}, {
  date: '2012-07-19',
  'New York': '75.1',
  'San Francisco': '57.1',
  Austin: '80.9'
}, {
  date: '2012-07-20',
  'New York': '68.4',
  'San Francisco': '58.1',
  Austin: '83.9'
}, {
  date: '2012-07-21',
  'New York': '68.4',
  'San Francisco': '57.6',
  Austin: '85.6'
}, {
  date: '2012-07-22',
  'New York': '72.2',
  'San Francisco': '56.0',
  Austin: '83.6'
}, {
  date: '2012-07-23',
  'New York': '75.6',
  'San Francisco': '56.6',
  Austin: '84.0'
}, {
  date: '2012-07-24',
  'New York': '82.6',
  'San Francisco': '57.8',
  Austin: '83.0'
}, {
  date: '2012-07-25',
  'New York': '78.4',
  'San Francisco': '57.5',
  Austin: '84.8'
}, {
  date: '2012-07-26',
  'New York': '77.0',
  'San Francisco': '56.4',
  Austin: '84.4'
}, {
  date: '2012-07-27',
  'New York': '79.4',
  'San Francisco': '55.3',
  Austin: '84.3'
}, {
  date: '2012-07-28',
  'New York': '77.4',
  'San Francisco': '55.0',
  Austin: '83.9'
}, {
  date: '2012-07-29',
  'New York': '72.5',
  'San Francisco': '55.6',
  Austin: '85.0'
}, {
  date: '2012-07-30',
  'New York': '72.9',
  'San Francisco': '55.6',
  Austin: '84.9'
}, {
  date: '2012-07-31',
  'New York': '73.6',
  'San Francisco': '55.9',
  Austin: '86.3'
}, {
  date: '2012-08-01',
  'New York': '75.0',
  'San Francisco': '55.4',
  Austin: '86.5'
}, {
  date: '2012-08-02',
  'New York': '77.7',
  'San Francisco': '54.4',
  Austin: '85.8'
}, {
  date: '2012-08-03',
  'New York': '79.7',
  'San Francisco': '53.7',
  Austin: '85.3'
}, {
  date: '2012-08-04',
  'New York': '79.6',
  'San Francisco': '54.1',
  Austin: '86.0'
}, {
  date: '2012-08-05',
  'New York': '81.5',
  'San Francisco': '57.8',
  Austin: '84.2'
}, {
  date: '2012-08-06',
  'New York': '80.0',
  'San Francisco': '58.2',
  Austin: '81.9'
}, {
  date: '2012-08-07',
  'New York': '75.7',
  'San Francisco': '58.0',
  Austin: '86.5'
}, {
  date: '2012-08-08',
  'New York': '77.8',
  'San Francisco': '57.0',
  Austin: '86.1'
}, {
  date: '2012-08-09',
  'New York': '78.6',
  'San Francisco': '55.0',
  Austin: '86.8'
}, {
  date: '2012-08-10',
  'New York': '77.8',
  'San Francisco': '54.8',
  Austin: '88.0'
}, {
  date: '2012-08-11',
  'New York': '78.5',
  'San Francisco': '53.0',
  Austin: '85.1'
}, {
  date: '2012-08-12',
  'New York': '78.8',
  'San Francisco': '52.5',
  Austin: '87.4'
}, {
  date: '2012-08-13',
  'New York': '78.6',
  'San Francisco': '53.3',
  Austin: '88.0'
}, {
  date: '2012-08-14',
  'New York': '76.8',
  'San Francisco': '53.9',
  Austin: '88.0'
}, {
  date: '2012-08-15',
  'New York': '76.7',
  'San Francisco': '56.2',
  Austin: '87.2'
}, {
  date: '2012-08-16',
  'New York': '75.9',
  'San Francisco': '57.1',
  Austin: '86.1'
}, {
  date: '2012-08-17',
  'New York': '77.6',
  'San Francisco': '55.3',
  Austin: '86.8'
}, {
  date: '2012-08-18',
  'New York': '72.6',
  'San Francisco': '56.2',
  Austin: '84.9'
}, {
  date: '2012-08-19',
  'New York': '70.4',
  'San Francisco': '54.3',
  Austin: '76.8'
}, {
  date: '2012-08-20',
  'New York': '71.8',
  'San Francisco': '53.1',
  Austin: '80.6'
}, {
  date: '2012-08-21',
  'New York': '73.6',
  'San Francisco': '53.4',
  Austin: '80.0'
}, {
  date: '2012-08-22',
  'New York': '74.7',
  'San Francisco': '54.5',
  Austin: '78.2'
}, {
  date: '2012-08-23',
  'New York': '74.6',
  'San Francisco': '55.7',
  Austin: '79.1'
}, {
  date: '2012-08-24',
  'New York': '76.0',
  'San Francisco': '54.8',
  Austin: '81.9'
}, {
  date: '2012-08-25',
  'New York': '76.2',
  'San Francisco': '53.8',
  Austin: '84.7'
}, {
  date: '2012-08-26',
  'New York': '73.4',
  'San Francisco': '56.5',
  Austin: '83.5'
}, {
  date: '2012-08-27',
  'New York': '74.6',
  'San Francisco': '58.3',
  Austin: '82.1'
}, {
  date: '2012-08-28',
  'New York': '79.4',
  'San Francisco': '58.7',
  Austin: '84.0'
}, {
  date: '2012-08-29',
  'New York': '74.7',
  'San Francisco': '57.5',
  Austin: '85.7'
}, {
  date: '2012-08-30',
  'New York': '73.5',
  'San Francisco': '55.9',
  Austin: '87.2'
}, {
  date: '2012-08-31',
  'New York': '77.9',
  'San Francisco': '55.4',
  Austin: '82.9'
}, {
  date: '2012-09-01',
  'New York': '80.7',
  'San Francisco': '55.7',
  Austin: '84.8'
}, {
  date: '2012-09-02',
  'New York': '75.1',
  'San Francisco': '53.1',
  Austin: '83.9'
}, {
  date: '2012-09-03',
  'New York': '73.5',
  'San Francisco': '53.5',
  Austin: '85.5'
}, {
  date: '2012-09-04',
  'New York': '73.5',
  'San Francisco': '52.5',
  Austin: '86.4'
}, {
  date: '2012-09-05',
  'New York': '77.7',
  'San Francisco': '54.5',
  Austin: '85.8'
}, {
  date: '2012-09-06',
  'New York': '74.2',
  'San Francisco': '56.3',
  Austin: '85.4'
}, {
  date: '2012-09-07',
  'New York': '76.0',
  'San Francisco': '56.4',
  Austin: '85.3'
}, {
  date: '2012-09-08',
  'New York': '77.1',
  'San Francisco': '56.5',
  Austin: '81.9'
}, {
  date: '2012-09-09',
  'New York': '69.7',
  'San Francisco': '56.4',
  Austin: '74.8'
}, {
  date: '2012-09-10',
  'New York': '67.8',
  'San Francisco': '55.4',
  Austin: '71.6'
}, {
  date: '2012-09-11',
  'New York': '64.0',
  'San Francisco': '56.2',
  Austin: '75.9'
}, {
  date: '2012-09-12',
  'New York': '68.1',
  'San Francisco': '55.7',
  Austin: '82.1'
}, {
  date: '2012-09-13',
  'New York': '69.3',
  'San Francisco': '54.3',
  Austin: '80.5'
}, {
  date: '2012-09-14',
  'New York': '70.0',
  'San Francisco': '55.2',
  Austin: '70.0'
}, {
  date: '2012-09-15',
  'New York': '69.3',
  'San Francisco': '54.3',
  Austin: '71.2'
}, {
  date: '2012-09-16',
  'New York': '66.3',
  'San Francisco': '52.9',
  Austin: '70.3'
}, {
  date: '2012-09-17',
  'New York': '67.0',
  'San Francisco': '54.8',
  Austin: '72.1'
}, {
  date: '2012-09-18',
  'New York': '72.8',
  'San Francisco': '54.8',
  Austin: '73.7'
}, {
  date: '2012-09-19',
  'New York': '67.2',
  'San Francisco': '56.8',
  Austin: '72.7'
}, {
  date: '2012-09-20',
  'New York': '62.1',
  'San Francisco': '55.4',
  Austin: '71.7'
}, {
  date: '2012-09-21',
  'New York': '64.0',
  'San Francisco': '55.8',
  Austin: '72.9'
}, {
  date: '2012-09-22',
  'New York': '65.5',
  'San Francisco': '55.9',
  Austin: '73.1'
}, {
  date: '2012-09-23',
  'New York': '65.7',
  'San Francisco': '52.8',
  Austin: '75.6'
}, {
  date: '2012-09-24',
  'New York': '60.4',
  'San Francisco': '54.5',
  Austin: '78.3'
}, {
  date: '2012-09-25',
  'New York': '63.2',
  'San Francisco': '53.3',
  Austin: '78.3'
}, {
  date: '2012-09-26',
  'New York': '68.5',
  'San Francisco': '53.6',
  Austin: '79.6'
}, {
  date: '2012-09-27',
  'New York': '69.2',
  'San Francisco': '52.1',
  Austin: '76.4'
}, {
  date: '2012-09-28',
  'New York': '68.7',
  'San Francisco': '52.6',
  Austin: '77.2'
}, {
  date: '2012-09-29',
  'New York': '62.5',
  'San Francisco': '53.9',
  Austin: '75.2'
}, {
  date: '2012-09-30',
  'New York': '62.3',
  'San Francisco': '55.1',
  Austin: '71.9'
}];
export default cityTemperature;