var exoplanets = [{
  name: 'Jupiter',
  radius: 10.97,
  distance: 0
}, {
  name: 'Saturn',
  radius: 9.14,
  distance: 0
}, {
  name: 'Uranus',
  radius: 3.98,
  distance: 0
}, {
  name: 'Neptune',
  radius: 3.86,
  distance: 0
}, {
  name: 'Earth',
  radius: 1,
  distance: 0
}, {
  name: 'Venus',
  radius: 0.95,
  distance: 0
}, {
  name: 'Mars',
  radius: 0.532,
  distance: 0
}, {
  name: 'Mercury',
  radius: 0.383,
  distance: 0
}, {
  name: 'Pluto',
  radius: 0.181,
  distance: 0
}, {
  name: '11 Com b',
  radius: 12.64,
  distance: 110.6
}, {
  name: '11 UMi b',
  radius: 12.89,
  distance: 119.5
}, {
  name: '14 And b',
  radius: 13.18,
  distance: 76.4
}, {
  name: '14 Her b',
  radius: 13.24,
  distance: 18.1
}, {
  name: '16 Cyg B b',
  radius: 13.69,
  distance: 21.41
}, {
  name: '18 Del b',
  radius: 12.9,
  distance: 73.1
}, {
  name: '1RXS1609 b',
  radius: 19.04,
  distance: 145
}, {
  name: '24 Sex b',
  radius: 13.62,
  distance: 74.8
}, {
  name: '24 Sex c',
  radius: 13.99,
  distance: 74.8
}, {
  name: '2M 0103(AB) b',
  radius: 12.74,
  distance: 47.2
}, {
  name: '2M 0122-2439 b',
  radius: 12.74,
  distance: 36
}, {
  name: '2M 044144 b',
  radius: 13.04,
  distance: 140
}, {
  name: '2M 0746+20 b',
  radius: 10.86,
  distance: 12.21
}, {
  name: '2M 2140+16 b',
  radius: 10.3,
  distance: 25
}, {
  name: '2M 2206-20 b',
  radius: 14.56,
  distance: 26.67
}, {
  name: '2M1207 b',
  radius: 13.31,
  distance: 52.4
}, {
  name: '30 Ari B b',
  radius: 12.92,
  distance: 39.4
}, {
  name: '4 Uma b',
  radius: 13.06,
  distance: 78.5
}, {
  name: '42 Dra b',
  radius: 13.32,
  distance: 97.3
}, {
  name: '47 Uma b',
  radius: 13.51,
  distance: 13.97
}, {
  name: '47 Uma c',
  radius: 14.21,
  distance: 13.97
}, {
  name: '47 Uma d',
  radius: 13.7,
  distance: 13.97
}, {
  name: '51 Peg b',
  radius: 14.28,
  distance: 14.7
}, {
  name: '55 Cnc b',
  radius: 14.03,
  distance: 12.34
}, {
  name: '55 Cnc c',
  radius: 8.48,
  distance: 12.34
}, {
  name: '55 Cnc d',
  radius: 13.33,
  distance: 12.34
}, {
  name: '55 Cnc e',
  radius: 1.99,
  distance: 12.34
}, {
  name: '55 Cnc f',
  radius: 7.75,
  distance: 12.34
}, {
  name: '6 Lyn b',
  radius: 13.53,
  distance: 56.9
}, {
  name: '61 Vir b',
  radius: 1.89,
  distance: 8.52
}, {
  name: '61 Vir c',
  radius: 4.63,
  distance: 8.52
}, {
  name: '61 Vir d',
  radius: 5.26,
  distance: 8.52
}, {
  name: '7 CMa b',
  radius: 13.5,
  distance: 19.75
}, {
  name: '70 Vir b',
  radius: 13.09,
  distance: 22
}, {
  name: '75 Cet b',
  radius: 13.37,
  distance: 81.5
}, {
  name: '81 Cet b',
  radius: 13.19,
  distance: 97.2
}, {
  name: '91 Aqr b',
  radius: 13.41,
  distance: 45.9
}, {
  name: 'AB Pic b',
  radius: 12.79,
  distance: 47.3
}, {
  name: 'alf Ari b',
  radius: 13.66,
  distance: 20.2
}, {
  name: 'alf Cen B b',
  radius: 1.1,
  distance: 1.3
}, {
  name: 'BD +48 738 b',
  radius: 13.9,
  distance: null
}, {
  name: 'BD+15 2940 b',
  radius: 13.81,
  distance: 585
}, {
  name: 'BD+20 274 c',
  radius: 13.22,
  distance: null
}, {
  name: 'BD-061339 b',
  radius: 3.29,
  distance: 20
}, {
  name: 'BD-061339 c',
  radius: 9.22,
  distance: 20
}, {
  name: 'BD-082823 b',
  radius: 4.04,
  distance: 42.2
}, {
  name: 'BD-082823 c',
  radius: 14.44,
  distance: 42.2
}, {
  name: 'BD-10 3166 b',
  radius: 14.26,
  distance: 66
}, {
  name: 'BD-17 63 b',
  radius: 13.2,
  distance: 34.6
}, {
  name: 'BD14 4559 b',
  radius: 13.75,
  distance: 50
}, {
  name: 'BD20 2457 b',
  radius: 12.6,
  distance: 200
}, {
  name: 'BD20 2457 c',
  radius: 12.82,
  distance: 200
}, {
  name: 'beta Pic b',
  radius: 13.07,
  distance: 19.3
}, {
  name: 'CD-35 2722 b',
  radius: 12.44,
  distance: 21.3
}, {
  name: 'CFBDS 1458 b',
  radius: 13.1,
  distance: 23.1
}, {
  name: 'CHXR 73 b',
  radius: 12.78,
  distance: null
}, {
  name: 'CoRoT-1 b',
  radius: 16.69,
  distance: 460
}, {
  name: 'CoRoT-10 b',
  radius: 10.86,
  distance: 345
}, {
  name: 'CoRoT-11 b',
  radius: 16.02,
  distance: 560
}, {
  name: 'CoRoT-12 b',
  radius: 16.13,
  distance: 1150
}, {
  name: 'CoRoT-13 b',
  radius: 9.91,
  distance: 1310
}, {
  name: 'CoRoT-14 b',
  radius: 12.21,
  distance: 1340
}, {
  name: 'CoRoT-16 b',
  radius: 13.1,
  distance: 840
}, {
  name: 'CoRoT-17 b',
  radius: 11.42,
  distance: 920
}, {
  name: 'CoRoT-18 b',
  radius: 14.67,
  distance: 870
}, {
  name: 'CoRoT-19 b',
  radius: 14.45,
  distance: 800
}, {
  name: 'CoRoT-2 b',
  radius: 16.41,
  distance: 300
}, {
  name: 'CoRoT-20 b',
  radius: 9.41,
  distance: 1230
}, {
  name: 'CoRoT-21 b',
  radius: 14.56,
  distance: null
}, {
  name: 'CoRoT-23 b',
  radius: 12.1,
  distance: 600
}, {
  name: 'CoRoT-25 b',
  radius: 12.1,
  distance: 1000
}, {
  name: 'CoRoT-26 b',
  radius: 14.11,
  distance: 1670
}, {
  name: 'CoRoT-3 b',
  radius: 11.31,
  distance: 680
}, {
  name: 'CoRoT-4 b',
  radius: 13.33,
  distance: null
}, {
  name: 'CoRoT-5 b',
  radius: 14.9,
  distance: 400
}, {
  name: 'CoRoT-6 b',
  radius: 13.06,
  distance: null
}, {
  name: 'CoRoT-7 b',
  radius: 1.67,
  distance: 150
}, {
  name: 'CoRoT-7 c',
  radius: 2.43,
  distance: 150
}, {
  name: 'CoRoT-8 b',
  radius: 6.37,
  distance: 380
}, {
  name: 'CoRoT-9 b',
  radius: 10.53,
  distance: 460
}, {
  name: 'CT Cha b',
  radius: 24.64,
  distance: 165
}, {
  name: 'DH Tau b',
  radius: 12.81,
  distance: null
}, {
  name: 'DP Leo b',
  radius: 13.07,
  distance: null
}, {
  name: 'eps CrB b',
  radius: 13.02,
  distance: 67.9
}, {
  name: 'eps Eridani b',
  radius: 13.73,
  distance: 3.2
}, {
  name: 'eps Tau b',
  radius: 13.03,
  distance: 45
}, {
  name: 'Fomalhaut b',
  radius: 13.61,
  distance: 7.7
}, {
  name: 'FU Tau b',
  radius: 12.74,
  distance: 140
}, {
  name: 'gamma 1 Leo b',
  radius: 12.97,
  distance: 38.5
}, {
  name: 'gamma Cephei b',
  radius: 13.65,
  distance: 13.79
}, {
  name: 'GJ 1214 b',
  radius: 2.67,
  distance: 13
}, {
  name: 'GJ 163 b',
  radius: 3.71,
  distance: 15
}, {
  name: 'GJ 163 c',
  radius: 2.43,
  distance: 15
}, {
  name: 'Gj 163 d',
  radius: 5.58,
  distance: 15
}, {
  name: 'GJ 176 b',
  radius: 2.43,
  distance: 9.42
}, {
  name: 'GJ 3021 b',
  radius: 13.38,
  distance: 17.62
}, {
  name: 'GJ 317 b',
  radius: 13.66,
  distance: 15.1
}, {
  name: 'GJ 317 c',
  radius: 13.61,
  distance: 15.1
}, {
  name: 'GJ 328 b',
  radius: 13.49,
  distance: 19.8
}, {
  name: 'GJ 3470 b',
  radius: 4.19,
  distance: 30.7
}, {
  name: 'GJ 3634 b',
  radius: 2.2,
  distance: 19.8
}, {
  name: 'GJ 433 b',
  radius: 2.01,
  distance: 9.04
}, {
  name: 'GJ 433 c',
  radius: 7.63,
  distance: 9.04
}, {
  name: 'GJ 436 b',
  radius: 4.09,
  distance: 10.2
}, {
  name: 'GJ 504 b',
  radius: 13.24,
  distance: 17.56
}, {
  name: 'GJ 667C b',
  radius: 1.95,
  distance: 7.23
}, {
  name: 'GJ 667C c',
  radius: 1.74,
  distance: 7.23
}, {
  name: 'GJ 667C d',
  radius: 2.19,
  distance: 7.23
}, {
  name: 'GJ 667C e',
  radius: 1.52,
  distance: 7.23
}, {
  name: 'GJ 667C f',
  radius: 1.52,
  distance: 7.23
}, {
  name: 'GJ 667C g',
  radius: 1.93,
  distance: 7.23
}, {
  name: 'GJ 674 b',
  radius: 3.62,
  distance: 4.54
}, {
  name: 'GJ 676A b',
  radius: 13.21,
  distance: 16.45
}, {
  name: 'GJ 676A c',
  radius: 13.37,
  distance: 16.45
}, {
  name: 'GJ 676A d',
  radius: 1.9,
  distance: 16.45
}, {
  name: 'GJ 676A e',
  radius: 3.87,
  distance: 16.45
}, {
  name: 'GJ 832 b',
  radius: 14.13,
  distance: 4.94
}, {
  name: 'GJ 849 b',
  radius: 13.97,
  distance: 9.1
}, {
  name: 'GJ 849 c',
  radius: 13.98,
  distance: 9.1
}, {
  name: 'Gl 179 b',
  radius: 14.02,
  distance: 12.3
}, {
  name: 'Gl 581 b',
  radius: 4.29,
  distance: 6.21
}, {
  name: 'Gl 581 c',
  radius: 1.94,
  distance: 6.21
}, {
  name: 'Gl 581 d',
  radius: 2.05,
  distance: 6.21
}, {
  name: 'Gl 581 e',
  radius: 1.27,
  distance: 6.21
}, {
  name: 'Gl 649 b',
  radius: 14.44,
  distance: 10.34
}, {
  name: 'Gl 649 c',
  radius: 3.49,
  distance: 10.34
}, {
  name: 'Gl 86 b',
  radius: 13.31,
  distance: 10.9
}, {
  name: 'Gliese 876 b',
  radius: 13.56,
  distance: 4.7
}, {
  name: 'Gliese 876 c',
  radius: 14.08,
  distance: 4.7
}, {
  name: 'Gliese 876 d',
  radius: 2.15,
  distance: 4.7
}, {
  name: 'Gliese 876 e',
  radius: 4.09,
  distance: 4.7
}, {
  name: 'GQ Lup b',
  radius: 20.16,
  distance: 140
}, {
  name: 'GSC 06214-00210 b',
  radius: 12.69,
  distance: 145
}, {
  name: 'HAT-P-1 b',
  radius: 14.77,
  distance: 139
}, {
  name: 'HAT-P-11 b',
  radius: 4.73,
  distance: 38
}, {
  name: 'HAT-P-12 b',
  radius: 10.69,
  distance: 142.5
}, {
  name: 'HAT-P-13 b',
  radius: 14.34,
  distance: 214
}, {
  name: 'HAT-P-13 c',
  radius: 12.76,
  distance: 214
}, {
  name: 'HAT-P-14 b',
  radius: 13.44,
  distance: 205
}, {
  name: 'HAT-P-15 b',
  radius: 12.01,
  distance: 190
}, {
  name: 'HAT-P-16 b',
  radius: 14.44,
  distance: 235
}, {
  name: 'HAT-P-17 b',
  radius: 11.31,
  distance: 90
}, {
  name: 'HAT-P-17 c',
  radius: 13.77,
  distance: 90
}, {
  name: 'HAT-P-18 b',
  radius: 11.09,
  distance: 166
}, {
  name: 'HAT-P-19 b',
  radius: 12.68,
  distance: 215
}, {
  name: 'HAT-P-2 b',
  radius: 10.65,
  distance: 118
}, {
  name: 'HAT-P-20 b',
  radius: 9.71,
  distance: 70
}, {
  name: 'HAT-P-21 b',
  radius: 11.47,
  distance: 254
}, {
  name: 'HAT-P-22 b',
  radius: 12.1,
  distance: 82
}, {
  name: 'HAT-P-23 b',
  radius: 15.32,
  distance: 393
}, {
  name: 'HAT-P-24 b',
  radius: 13.91,
  distance: 306
}, {
  name: 'HAT-P-25 b',
  radius: 13.33,
  distance: 297
}, {
  name: 'HAT-P-26 b',
  radius: 6.32,
  distance: 134
}, {
  name: 'HAT-P-27-WASP-40 b',
  radius: 11.82,
  distance: 204
}, {
  name: 'HAT-P-28 b',
  radius: 13.57,
  distance: 395
}, {
  name: 'HAT-P-29 b',
  radius: 12.4,
  distance: 322
}, {
  name: 'HAT-P-3 b',
  radius: 9.26,
  distance: 130
}, {
  name: 'HAT-P-30-WASP-51 b',
  radius: 15.01,
  distance: 193
}, {
  name: 'HAT-P-31 b',
  radius: 11.98,
  distance: 354
}, {
  name: 'HAT-P-32 b',
  radius: 22.81,
  distance: 320
}, {
  name: 'HAT-P-33 b',
  radius: 20.46,
  distance: 419
}, {
  name: 'HAT-P-34 b',
  radius: 12.4,
  distance: 257
}, {
  name: 'HAT-P-35 b',
  radius: 14.92,
  distance: 535
}, {
  name: 'HAT-P-36 b',
  radius: 14.16,
  distance: 317
}, {
  name: 'HAT-P-37 b',
  radius: 13.19,
  distance: 411
}, {
  name: 'HAT-P-38 b',
  radius: 9.24,
  distance: 249
}, {
  name: 'HAT-P-39 b',
  radius: 17.6,
  distance: 642
}, {
  name: 'HAT-P-4 b',
  radius: 14.22,
  distance: 310
}, {
  name: 'HAT-P-40 b',
  radius: 19.38,
  distance: 501
}, {
  name: 'HAT-P-41 b',
  radius: 17.12,
  distance: 311
}, {
  name: 'HAT-P-42 b',
  radius: 14.3,
  distance: 447
}, {
  name: 'HAT-P-43 b',
  radius: 14.37,
  distance: 543
}, {
  name: 'HAT-P-44 b',
  radius: 14.34,
  distance: 374
}, {
  name: 'HAT-P-44 c',
  radius: 13.65,
  distance: 374
}, {
  name: 'HAT-P-45 b',
  radius: 15.97,
  distance: 305
}, {
  name: 'HAT-P-46 b',
  radius: 14.38,
  distance: 296
}, {
  name: 'HAT-P-46 c',
  radius: 13.55,
  distance: 296
}, {
  name: 'HAT-P-5 b',
  radius: 14.02,
  distance: 340
}, {
  name: 'HAT-P-6 b',
  radius: 14.9,
  distance: 200
}, {
  name: 'HAT-P-7 b',
  radius: 15.92,
  distance: 320
}, {
  name: 'HAT-P-8 b',
  radius: 16.8,
  distance: 230
}, {
  name: 'HAT-P-9 b',
  radius: 15.68,
  distance: 480
}, {
  name: 'HATS-1 b',
  radius: 14.58,
  distance: 303
}, {
  name: 'HATS-2 b',
  radius: 13.08,
  distance: 360
}, {
  name: 'HATS-3 b',
  radius: 15.47,
  distance: 453
}, {
  name: 'HD 100655 b',
  radius: 13.69,
  distance: 122
}, {
  name: 'HD 100777 b',
  radius: 13.86,
  distance: 52.8
}, {
  name: 'HD 10180 c',
  radius: 3.85,
  distance: 39.4
}, {
  name: 'HD 10180 d',
  radius: 3.62,
  distance: 39.4
}, {
  name: 'HD 10180 e',
  radius: 5.54,
  distance: 39.4
}, {
  name: 'HD 10180 f',
  radius: 5.39,
  distance: 39.4
}, {
  name: 'HD 10180 g',
  radius: 5.06,
  distance: 39.4
}, {
  name: 'HD 10180 h',
  radius: 9.38,
  distance: 39.4
}, {
  name: 'HD 101930 b',
  radius: 11.69,
  distance: 30.49
}, {
  name: 'HD 102117 b',
  radius: 8.56,
  distance: 42
}, {
  name: 'HD 102195 b',
  radius: 14.3,
  distance: 28.98
}, {
  name: 'HD 102272 b',
  radius: 13.14,
  distance: 360
}, {
  name: 'HD 102272 c',
  radius: 13.5,
  distance: 360
}, {
  name: 'HD 102329 b',
  radius: 13.14,
  distance: 158
}, {
  name: 'HD 102365 b',
  radius: 4.29,
  distance: 9.24
}, {
  name: 'HD 102956 b',
  radius: 13.94,
  distance: 126
}, {
  name: 'HD 103197 b',
  radius: 6.25,
  distance: 49.3
}, {
  name: 'HD 103774 b',
  radius: 14.32,
  distance: 55
}, {
  name: 'HD 104067 b',
  radius: 8.95,
  distance: 20.8
}, {
  name: 'HD 104985 b',
  radius: 13.11,
  distance: 102
}, {
  name: 'HD 106252 b',
  radius: 13.03,
  distance: 37.44
}, {
  name: 'HD 106270 b',
  radius: 12.87,
  distance: 84.9
}, {
  name: 'HD 10647 b',
  radius: 13.96,
  distance: 17.3
}, {
  name: 'HD 106515A b',
  radius: 12.87,
  distance: 35.2
}, {
  name: 'HD 10697 b',
  radius: 13.11,
  distance: 32.56
}, {
  name: 'HD 107148 b',
  radius: 9.57,
  distance: 51.3
}, {
  name: 'HD 108147 b',
  radius: 10.81,
  distance: 38.57
}, {
  name: 'HD 108863 b',
  radius: 13.5,
  distance: 139
}, {
  name: 'HD 108874 b',
  radius: 13.79,
  distance: 68.5
}, {
  name: 'HD 108874 c',
  radius: 13.92,
  distance: 68.5
}, {
  name: 'HD 109246 b',
  radius: 14.05,
  distance: 65.6
}, {
  name: 'HD 109271 b',
  radius: 4.85,
  distance: 62
}, {
  name: 'HD 109271 c',
  radius: 5.87,
  distance: 62
}, {
  name: 'HD 109749 b',
  radius: 11.25,
  distance: 59
}, {
  name: 'HD 110014 b',
  radius: 12.87,
  distance: 90
}, {
  name: 'HD 111232 b',
  radius: 13.08,
  distance: 29
}, {
  name: 'HD 113337 b',
  radius: 13.4,
  distance: 36.9
}, {
  name: 'HD 113538 b',
  radius: 11.02,
  distance: 15.8
}, {
  name: 'HD 113538 c',
  radius: 14.08,
  distance: 15.8
}, {
  name: 'HD 114386 b',
  radius: 13.83,
  distance: 28
}, {
  name: 'HD 114386 c',
  radius: 13.78,
  distance: 28
}, {
  name: 'HD 114729 b',
  radius: 14.01,
  distance: 35
}, {
  name: 'HD 114762 b',
  radius: 12.87,
  distance: 39.46
}, {
  name: 'HD 114783 b',
  radius: 13.93,
  distance: 20.4
}, {
  name: 'HD 11506 b',
  radius: 13.37,
  distance: 53.82
}, {
  name: 'HD 11506 c',
  radius: 14.02,
  distance: 53.82
}, {
  name: 'HD 116029 b',
  radius: 13.59,
  distance: 123.2
}, {
  name: 'HD 117207 b',
  radius: 13.6,
  distance: 33
}, {
  name: 'HD 117618 b',
  radius: 8.73,
  distance: 38
}, {
  name: 'HD 117618 c',
  radius: 10.1,
  distance: 38
}, {
  name: 'HD 118203 b',
  radius: 13.59,
  distance: 88.6
}, {
  name: 'HD 11964 b',
  radius: 14.14,
  distance: 33.98
}, {
  name: 'HD 11964 c',
  radius: 5.54,
  distance: 33.98
}, {
  name: 'HD 11977 b',
  radius: 13.09,
  distance: 66.5
}, {
  name: 'HD 120084 b',
  radius: 13.19,
  distance: 97.7
}, {
  name: 'HD 121504 b',
  radius: 13.84,
  distance: 44.37
}, {
  name: 'HD 122430 b',
  radius: 13.34,
  distance: 135
}, {
  name: 'HD 125595 b',
  radius: 3.89,
  distance: 27.4
}, {
  name: 'HD 125612 b',
  radius: 13.43,
  distance: 52.82
}, {
  name: 'HD 125612 c',
  radius: 4.66,
  distance: 52.82
}, {
  name: 'HD 125612 d',
  radius: 13.05,
  distance: 52.82
}, {
  name: 'HD 126525 b',
  radius: 9.93,
  distance: 38.1
}, {
  name: 'HD 12661 b',
  radius: 13.55,
  distance: 37.16
}, {
  name: 'HD 12661 c',
  radius: 13.72,
  distance: 37.16
}, {
  name: 'HD 126614 b',
  radius: 14.37,
  distance: 72.4
}, {
  name: 'HD 128311 b',
  radius: 13.57,
  distance: 16.6
}, {
  name: 'HD 128311 c',
  radius: 13.4,
  distance: 16.6
}, {
  name: 'HD 129445 b',
  radius: 13.71,
  distance: 67.61
}, {
  name: 'HD 130322 b',
  radius: 13.92,
  distance: 30
}, {
  name: 'HD 131496 b',
  radius: 13.57,
  distance: 110
}, {
  name: 'HD 13189 b',
  radius: 12.77,
  distance: 185
}, {
  name: 'HD 132406 b',
  radius: 13.16,
  distance: 71
}, {
  name: 'HD 132563B b',
  radius: 13.75,
  distance: 96
}, {
  name: 'HD 134060 b',
  radius: 3.52,
  distance: 24.2
}, {
  name: 'HD 134060 c',
  radius: 7.95,
  distance: 24.2
}, {
  name: 'HD 134606 b',
  radius: 2.57,
  distance: 26.5
}, {
  name: 'HD 134606 c',
  radius: 3.69,
  distance: 26.5
}, {
  name: 'HD 134606 d',
  radius: 7.03,
  distance: 26.5
}, {
  name: 'HD 134987 b',
  radius: 13.72,
  distance: 22.2
}, {
  name: 'HD 134987 c',
  radius: 14.02,
  distance: 22.2
}, {
  name: 'HD 136352 b',
  radius: 1.92,
  distance: 14.8
}, {
  name: 'HD 136352 c',
  radius: 3.56,
  distance: 14.8
}, {
  name: 'HD 136352 d',
  radius: 3.22,
  distance: 14.8
}, {
  name: 'HD 136418 b',
  radius: 13.61,
  distance: 98.2
}, {
  name: 'HD 137388 b',
  radius: 9.9,
  distance: 38
}, {
  name: 'HD 13808 b',
  radius: 3.37,
  distance: 28.6
}, {
  name: 'HD 13808 c',
  radius: 3.57,
  distance: 28.6
}, {
  name: 'HD 13908 b',
  radius: 13.93,
  distance: 71.2
}, {
  name: 'HD 13908 c',
  radius: 13.14,
  distance: 71.2
}, {
  name: 'HD 13931 b',
  radius: 13.64,
  distance: 44.2
}, {
  name: 'HD 139357 b',
  radius: 12.92,
  distance: 121.4
}, {
  name: 'HD 141937 b',
  radius: 12.93,
  distance: 33.46
}, {
  name: 'HD 142 b',
  radius: 13.82,
  distance: 20.6
}, {
  name: 'HD 142 c',
  radius: 13.19,
  distance: 20.6
}, {
  name: 'HD 142022 A b',
  radius: 13.2,
  distance: 35.87
}, {
  name: 'HD 142245 b',
  radius: 13.64,
  distance: 109.5
}, {
  name: 'HD 142415 b',
  radius: 13.71,
  distance: 34.2
}, {
  name: 'HD 143361 b',
  radius: 13.42,
  distance: 59.35
}, {
  name: 'HD 145377 b',
  radius: 13.15,
  distance: 57.7
}, {
  name: 'HD 145457 b',
  radius: 13.45,
  distance: 126
}, {
  name: 'HD 1461 b',
  radius: 2.3,
  distance: 23.4
}, {
  name: 'HD 1461 c',
  radius: 2.03,
  distance: 23.4
}, {
  name: 'HD 147018 b',
  radius: 13.59,
  distance: 42.96
}, {
  name: 'HD 147018 c',
  radius: 13.09,
  distance: 42.96
}, {
  name: 'HD 147513 b',
  radius: 13.84,
  distance: 12.9
}, {
  name: 'HD 148156 b',
  radius: 14,
  distance: 53.05
}, {
  name: 'HD 148427 b',
  radius: 13.94,
  distance: 59.3
}, {
  name: 'HD 149026 b',
  radius: 8.04,
  distance: 78.9
}, {
  name: 'HD 149143 b',
  radius: 13.79,
  distance: 63
}, {
  name: 'HD 1502 b',
  radius: 13.42,
  distance: 159
}, {
  name: 'HD 150433 b',
  radius: 8.45,
  distance: 29.6
}, {
  name: 'HD 150706 b',
  radius: 13.48,
  distance: 27.2
}, {
  name: 'HD 152079 b',
  radius: 13.43,
  distance: 85.17
}, {
  name: 'HD 152581 b',
  radius: 13.74,
  distance: 186
}, {
  name: 'HD 153950 b',
  radius: 13.48,
  distance: 49.6
}, {
  name: 'HD 154088 b',
  radius: 2.06,
  distance: 17.8
}, {
  name: 'HD 154345 b',
  radius: 13.93,
  distance: 18.06
}, {
  name: 'HD 154672 b',
  radius: 13.21,
  distance: 65.8
}, {
  name: 'HD 154857 b',
  radius: 13.66,
  distance: 68.5
}, {
  name: 'HD 155358 b',
  radius: 14,
  distance: 43
}, {
  name: 'HD 155358 c',
  radius: 14.02,
  distance: 43
}, {
  name: 'HD 156279 b',
  radius: 12.93,
  distance: 36.6
}, {
  name: 'HD 156411 b',
  radius: 14.06,
  distance: 55.1
}, {
  name: 'HD 156668 b',
  radius: 1.72,
  distance: 24.5
}, {
  name: 'HD 157172 b',
  radius: 7,
  distance: 31.9
}, {
  name: 'HD 158038 b',
  radius: 13.66,
  distance: 103.6
}, {
  name: 'HD 159243 b',
  radius: 13.81,
  distance: 69.2
}, {
  name: 'HD 159243 c',
  radius: 13.57,
  distance: 69.2
}, {
  name: 'HD 159868 b',
  radius: 13.59,
  distance: 52.7
}, {
  name: 'HD 159868 c',
  radius: 14.07,
  distance: 52.7
}, {
  name: 'HD 16141 b',
  radius: 9.7,
  distance: 35.9
}, {
  name: 'HD 16175 b',
  radius: 13.27,
  distance: 59.8
}, {
  name: 'HD 162020 b',
  radius: 12.76,
  distance: 31.26
}, {
  name: 'HD 163607 b',
  radius: 14.05,
  distance: 69
}, {
  name: 'HD 163607 c',
  radius: 13.55,
  distance: 69
}, {
  name: 'HD 16417 b',
  radius: 5.13,
  distance: 25.5
}, {
  name: 'HD 164509 b',
  radius: 14.26,
  distance: 52
}, {
  name: 'HD 164604 b',
  radius: 13.48,
  distance: 38
}, {
  name: 'HD 164922 b',
  radius: 14.4,
  distance: 21.93
}, {
  name: 'HD 166724 b',
  radius: 13.3,
  distance: 42.3
}, {
  name: 'HD 167042 b',
  radius: 13.71,
  distance: 50
}, {
  name: 'HD 168443 b',
  radius: 13.03,
  distance: 37.38
}, {
  name: 'HD 168443 c',
  radius: 12.69,
  distance: 37.38
}, {
  name: 'HD 168746 b',
  radius: 10.07,
  distance: 43.12
}, {
  name: 'HD 1690 b',
  radius: 13.12,
  distance: 319
}, {
  name: 'HD 169830 b',
  radius: 13.45,
  distance: 36.32
}, {
  name: 'HD 169830 c',
  radius: 13.3,
  distance: 36.32
}, {
  name: 'HD 170469 b',
  radius: 14.11,
  distance: 64.97
}, {
  name: 'HD 17092 b',
  radius: 13.25,
  distance: 109
}, {
  name: 'HD 171028 b',
  radius: 13.62,
  distance: 90
}, {
  name: 'HD 171238 b',
  radius: 13.5,
  distance: 50.28
}, {
  name: 'HD 17156 b',
  radius: 12.26,
  distance: 78.24
}, {
  name: 'HD 173416 b',
  radius: 13.48,
  distance: 135
}, {
  name: 'HD 175167 b',
  radius: 13.02,
  distance: 67.02
}, {
  name: 'HD 175541 b',
  radius: 14.15,
  distance: 128
}, {
  name: 'HD 176051 b',
  radius: 13.74,
  distance: 14.99
}, {
  name: 'HD 177830 b',
  radius: 13.75,
  distance: 59
}, {
  name: 'HD 177830 c',
  radius: 7.93,
  distance: 59
}, {
  name: 'HD 178911 B b',
  radius: 13.11,
  distance: 46.73
}, {
  name: 'HD 179079 b',
  radius: 5.58,
  distance: 63.69
}, {
  name: 'HD 179949 b',
  radius: 13.95,
  distance: 27
}, {
  name: 'HD 180314 b',
  radius: 12.58,
  distance: 132
}, {
  name: 'HD 180902 b',
  radius: 13.71,
  distance: 110
}, {
  name: 'HD 181342 b',
  radius: 13.39,
  distance: 110.6
}, {
  name: 'HD 181433 b',
  radius: 2.3,
  distance: 26.15
}, {
  name: 'HD 181433 c',
  radius: 14.13,
  distance: 26.15
}, {
  name: 'HD 181433 d',
  radius: 14.21,
  distance: 26.15
}, {
  name: 'HD 181720 b',
  radius: 14.39,
  distance: 56
}, {
  name: 'HD 183263 b',
  radius: 13.35,
  distance: 53
}, {
  name: 'HD 183263 c',
  radius: 13.33,
  distance: 53
}, {
  name: 'HD 185269 b',
  radius: 13.95,
  distance: 47
}, {
  name: 'HD 187085 b',
  radius: 14.06,
  distance: 44.98
}, {
  name: 'HD 187123 b',
  radius: 14.23,
  distance: 50
}, {
  name: 'HD 187123 c',
  radius: 13.62,
  distance: 50
}, {
  name: 'HD 18742 b',
  radius: 13.48,
  distance: 135
}, {
  name: 'HD 188015 b',
  radius: 13.82,
  distance: 52.6
}, {
  name: 'HD 189567 b',
  radius: 3.32,
  distance: 17.7
}, {
  name: 'HD 189733 b',
  radius: 12.75,
  distance: 19.3
}, {
  name: 'HD 190360 b',
  radius: 13.74,
  distance: 15.89
}, {
  name: 'HD 190360 c',
  radius: 4.61,
  distance: 15.89
}, {
  name: 'HD 190647 b',
  radius: 13.64,
  distance: 54.2
}, {
  name: 'HD 190984 b',
  radius: 13.42,
  distance: 103
}, {
  name: 'HD 192263 b',
  radius: 14.07,
  distance: 19.9
}, {
  name: 'HD 192310 b',
  radius: 4.43,
  distance: 8.82
}, {
  name: 'HD 192310 c',
  radius: 5.38,
  distance: 8.82
}, {
  name: 'HD 192699 b',
  radius: 13.51,
  distance: 67
}, {
  name: 'HD 195019 b',
  radius: 13.34,
  distance: 37.36
}, {
  name: 'HD 196050 b',
  radius: 13.46,
  distance: 46.9
}, {
  name: 'HD 196067 b',
  radius: 13.01,
  distance: 44.3
}, {
  name: 'HD 196885 A b',
  radius: 13.44,
  distance: 33
}, {
  name: 'HD 197037 b',
  radius: 14.03,
  distance: 33
}, {
  name: 'HD 19994 b',
  radius: 13.69,
  distance: 22.38
}, {
  name: 'HD 20003 b',
  radius: 3.67,
  distance: 43.8
}, {
  name: 'HD 20003 c',
  radius: 3.9,
  distance: 43.8
}, {
  name: 'HD 200964 b',
  radius: 13.65,
  distance: 68.4
}, {
  name: 'HD 200964 c',
  radius: 13.97,
  distance: 68.4
}, {
  name: 'HD 202206 b',
  radius: 12.68,
  distance: 46.34
}, {
  name: 'HD 202206 c',
  radius: 13.52,
  distance: 46.34
}, {
  name: 'HD 20367 b',
  radius: 13.9,
  distance: 27
}, {
  name: 'HD 2039 b',
  radius: 13.22,
  distance: 89.8
}, {
  name: 'HD 204313 b',
  radius: 13.36,
  distance: 47.37
}, {
  name: 'HD 204313 c',
  radius: 4.48,
  distance: 47.37
}, {
  name: 'HD 204313 d',
  radius: 13.69,
  distance: 47.37
}, {
  name: 'HD 204941 b',
  radius: 10.93,
  distance: 27
}, {
  name: 'HD 205739 b',
  radius: 13.78,
  distance: 90.3
}, {
  name: 'HD 206610 b',
  radius: 13.57,
  distance: 194
}, {
  name: 'HD 20781 b',
  radius: 3.67,
  distance: 35.4
}, {
  name: 'HD 20781 c',
  radius: 4.27,
  distance: 35.4
}, {
  name: 'HD 20782 b',
  radius: 13.64,
  distance: 36.02
}, {
  name: 'HD 207832 b',
  radius: 14.13,
  distance: 54.4
}, {
  name: 'HD 207832 c',
  radius: 14,
  distance: 54.4
}, {
  name: 'HD 20794 b',
  radius: 1.44,
  distance: 6.06
}, {
  name: 'HD 20794 c',
  radius: 1.38,
  distance: 6.06
}, {
  name: 'HD 20794 d',
  radius: 1.83,
  distance: 6.06
}, {
  name: 'HD 208487 b',
  radius: 14.34,
  distance: 45
}, {
  name: 'HD 208527 b',
  radius: 12.86,
  distance: 320.2
}, {
  name: 'HD 20868 b',
  radius: 13.62,
  distance: 48.9
}, {
  name: 'HD 209458 b',
  radius: 15.46,
  distance: 47
}, {
  name: 'HD 210277 b',
  radius: 13.83,
  distance: 21.29
}, {
  name: 'HD 210702 b',
  radius: 13.64,
  distance: 56
}, {
  name: 'HD 212301 b',
  radius: 14.3,
  distance: 52.7
}, {
  name: 'HD 212771 b',
  radius: 13.55,
  distance: 131
}, {
  name: 'HD 213240 b',
  radius: 13.26,
  distance: 40.75
}, {
  name: 'HD 215152 b',
  radius: 1.45,
  distance: 21.5
}, {
  name: 'HD 215152 c',
  radius: 1.52,
  distance: 21.5
}, {
  name: 'HD 215456 b',
  radius: 6.35,
  distance: 38
}, {
  name: 'HD 215456 c',
  radius: 10.46,
  distance: 38
}, {
  name: 'HD 215497 b',
  radius: 2.1,
  distance: 44
}, {
  name: 'HD 215497 c',
  radius: 14.44,
  distance: 44
}, {
  name: 'HD 216435 b',
  radius: 13.82,
  distance: 33.3
}, {
  name: 'HD 216437 b',
  radius: 13.66,
  distance: 26.5
}, {
  name: 'HD 216770 b',
  radius: 14.12,
  distance: 38
}, {
  name: 'HD 21693 b',
  radius: 3.35,
  distance: 32.4
}, {
  name: 'HD 21693 c',
  radius: 4.95,
  distance: 32.4
}, {
  name: 'HD 217107 b',
  radius: 13.8,
  distance: 19.72
}, {
  name: 'HD 217107 c',
  radius: 13.52,
  distance: 19.72
}, {
  name: 'HD 217786 b',
  radius: 12.8,
  distance: 54.8
}, {
  name: 'HD 218566 b',
  radius: 9.57,
  distance: 29.94
}, {
  name: 'HD 219077 b',
  radius: 12.84,
  distance: 29.35
}, {
  name: 'HD 219415 b',
  radius: 13.86,
  distance: null
}, {
  name: 'HD 219828 b',
  radius: 5.01,
  distance: 81.1
}, {
  name: 'HD 220074 b',
  radius: 12.81,
  distance: 290.2
}, {
  name: 'HD 220689 b',
  radius: 13.83,
  distance: 44.6
}, {
  name: 'HD 220773 b',
  radius: 13.76,
  distance: 49
}, {
  name: 'HD 221287 b',
  radius: 13.42,
  distance: 52.9
}, {
  name: 'HD 222155 b',
  radius: 13.64,
  distance: 49.1
}, {
  name: 'HD 222582 b',
  radius: 13.02,
  distance: 42
}, {
  name: 'HD 224693 b',
  radius: 14.08,
  distance: 94
}, {
  name: 'HD 22781 b',
  radius: 12.78,
  distance: 31.79
}, {
  name: 'HD 23079 b',
  radius: 13.51,
  distance: 34.8
}, {
  name: 'HD 23127 b',
  radius: 13.74,
  distance: 89.1
}, {
  name: 'HD 231701 b',
  radius: 13.89,
  distance: 108.4
}, {
  name: 'HD 233604 b',
  radius: 13.03,
  distance: null
}, {
  name: 'HD 23596 b',
  radius: 13,
  distance: 52
}, {
  name: 'HD 240210 b',
  radius: 13.07,
  distance: 143
}, {
  name: 'HD 240237 b',
  radius: 13.19,
  distance: 1500
}, {
  name: 'HD 24040 b',
  radius: 13.31,
  distance: 46.51
}, {
  name: 'HD 25171 b',
  radius: 13.95,
  distance: 55
}, {
  name: 'HD 2638 b',
  radius: 14.26,
  distance: 53.71
}, {
  name: 'HD 27442 b',
  radius: 13.79,
  distance: 18.1
}, {
  name: 'HD 27631 b',
  radius: 13.69,
  distance: 45.5
}, {
  name: 'HD 27894 b',
  radius: 14.15,
  distance: 42.37
}, {
  name: 'HD 28185 b',
  radius: 13.15,
  distance: 39.4
}, {
  name: 'HD 28254 b',
  radius: 13.86,
  distance: 56.2
}, {
  name: 'HD 285507 b',
  radius: 13.9,
  distance: 41.3
}, {
  name: 'HD 28678 b',
  radius: 13.69,
  distance: 227
}, {
  name: 'HD 290327 b',
  radius: 13.51,
  distance: 54.9
}, {
  name: 'HD 2952 b',
  radius: 13.65,
  distance: 115.2
}, {
  name: 'HD 30177 b',
  radius: 13.02,
  distance: 55
}, {
  name: 'HD 30562 b',
  radius: 13.81,
  distance: 26.5
}, {
  name: 'HD 30856 b',
  radius: 13.66,
  distance: 118.1
}, {
  name: 'HD 31253 b',
  radius: 14.25,
  distance: 53.82
}, {
  name: 'HD 31527 b',
  radius: 3.58,
  distance: 38.6
}, {
  name: 'HD 31527 c',
  radius: 4.28,
  distance: 38.6
}, {
  name: 'HD 31527 d',
  radius: 4.38,
  distance: 38.6
}, {
  name: 'HD 32518 b',
  radius: 13.43,
  distance: 117.4
}, {
  name: 'HD 330075 b',
  radius: 14.15,
  distance: 50.2
}, {
  name: 'HD 33142 b',
  radius: 13.81,
  distance: 126
}, {
  name: 'HD 33283 b',
  radius: 14.44,
  distance: 86
}, {
  name: 'HD 33564 b',
  radius: 12.95,
  distance: 20.98
}, {
  name: 'HD 34445 b',
  radius: 14.03,
  distance: 46.5
}, {
  name: 'HD 3651 b',
  radius: 9.32,
  distance: 11
}, {
  name: 'HD 3651 c',
  radius: 6.46,
  distance: 11
}, {
  name: 'HD 37124 b',
  radius: 14.11,
  distance: 33
}, {
  name: 'HD 37124 c',
  radius: 14.12,
  distance: 33
}, {
  name: 'HD 37124 d',
  radius: 14.09,
  distance: 33
}, {
  name: 'HD 37605 b',
  radius: 13.46,
  distance: 44
}, {
  name: 'HD 37605 c',
  radius: 13.32,
  distance: 44
}, {
  name: 'HD 38283 b',
  radius: 14.43,
  distance: 37.7
}, {
  name: 'HD 38529 b',
  radius: 14.04,
  distance: 39.28
}, {
  name: 'HD 38529 c',
  radius: 12.67,
  distance: 39.28
}, {
  name: 'HD 38801 b',
  radius: 12.89,
  distance: 99.4
}, {
  name: 'HD 38858 b',
  radius: 6.18,
  distance: 15.2
}, {
  name: 'HD 39091 b',
  radius: 12.9,
  distance: 18.32
}, {
  name: 'HD 39194 b',
  radius: 1.64,
  distance: 25.9
}, {
  name: 'HD 39194 c',
  radius: 2.03,
  distance: 25.9
}, {
  name: 'HD 39194 d',
  radius: 1.9,
  distance: 25.9
}, {
  name: 'HD 40307 b',
  radius: 1.69,
  distance: 12.8
}, {
  name: 'HD 40307 c',
  radius: 2.14,
  distance: 12.8
}, {
  name: 'HD 40307 d',
  radius: 3.21,
  distance: 12.8
}, {
  name: 'HD 40307 e',
  radius: 1.7,
  distance: 12.8
}, {
  name: 'HD 40307 f',
  radius: 2.04,
  distance: 12.8
}, {
  name: 'HD 40307 g',
  radius: 2.39,
  distance: 12.8
}, {
  name: 'HD 40979 b',
  radius: 13.39,
  distance: 33.3
}, {
  name: 'HD 41004 A b',
  radius: 13.51,
  distance: 42.5
}, {
  name: 'HD 41004 B b',
  radius: 12.66,
  distance: 43.03
}, {
  name: 'HD 4113 b',
  radius: 13.72,
  distance: 44
}, {
  name: 'HD 41248 b',
  radius: 4.03,
  distance: 52.38
}, {
  name: 'HD 41248 c',
  radius: 3.3,
  distance: 52.38
}, {
  name: 'HD 4203 b',
  radius: 13.6,
  distance: 77.5
}, {
  name: 'HD 4208 b',
  radius: 14.03,
  distance: 33.9
}, {
  name: 'HD 4308 b',
  radius: 3.81,
  distance: 21.9
}, {
  name: 'HD 4313 b',
  radius: 13.55,
  distance: 137
}, {
  name: 'HD 43197 b',
  radius: 14.16,
  distance: 54.9
}, {
  name: 'HD 43691 b',
  radius: 13.52,
  distance: 93.2
}, {
  name: 'HD 44219 b',
  radius: 14.18,
  distance: 50.43
}, {
  name: 'HD 45184 b',
  radius: 3.78,
  distance: 21.9
}, {
  name: 'HD 45350 b',
  radius: 13.66,
  distance: 49
}, {
  name: 'HD 45364 b',
  radius: 8.98,
  distance: 32.6
}, {
  name: 'HD 45364 c',
  radius: 14.12,
  distance: 32.6
}, {
  name: 'HD 45652 b',
  radius: 14.27,
  distance: 36
}, {
  name: 'HD 46375 b',
  radius: 10.53,
  distance: 33.4
}, {
  name: 'HD 47186 b',
  radius: 5.24,
  distance: 37.84
}, {
  name: 'HD 47186 c',
  radius: 14.41,
  distance: 37.84
}, {
  name: 'HD 47536 b',
  radius: 13.21,
  distance: 121.36
}, {
  name: 'HD 47536 c',
  radius: 13,
  distance: 121.36
}, {
  name: 'HD 48265 b',
  radius: 13.86,
  distance: 87.4
}, {
  name: 'HD 49674 b',
  radius: 6.83,
  distance: 40.7
}, {
  name: 'HD 50499 b',
  radius: 13.68,
  distance: 47.26
}, {
  name: 'HD 50554 b',
  radius: 13.2,
  distance: 31.03
}, {
  name: 'HD 51608 b',
  radius: 3.85,
  distance: 34.8
}, {
  name: 'HD 51608 c',
  radius: 4.59,
  distance: 34.8
}, {
  name: 'HD 52265 b',
  radius: 13.9,
  distance: 28
}, {
  name: 'HD 52265 c',
  radius: 14.35,
  distance: 28
}, {
  name: 'HD 5319 b',
  radius: 13.63,
  distance: 100
}, {
  name: 'HD 5608 b',
  radius: 13.71,
  distance: 58.2
}, {
  name: 'HD 5891 b',
  radius: 13.03,
  distance: 251
}, {
  name: 'HD 59686 b',
  radius: 13.19,
  distance: 92
}, {
  name: 'HD 60532 b',
  radius: 13.41,
  distance: 25.7
}, {
  name: 'HD 60532 c',
  radius: 13.04,
  distance: 25.7
}, {
  name: 'HD 62509 b',
  radius: 13.45,
  distance: 10.34
}, {
  name: 'HD 63454 b',
  radius: 14.37,
  distance: 35.8
}, {
  name: 'HD 63765 b',
  radius: 14.13,
  distance: 32.6
}, {
  name: 'HD 6434 b',
  radius: 14.36,
  distance: 40.32
}, {
  name: 'HD 65216 b',
  radius: 13.84,
  distance: 34.3
}, {
  name: 'HD 65216 c',
  radius: 9.22,
  distance: 34.3
}, {
  name: 'HD 66141 b',
  radius: 13.07,
  distance: 80.9
}, {
  name: 'HD 66428 b',
  radius: 13.46,
  distance: 55
}, {
  name: 'HD 6718 b',
  radius: 13.72,
  distance: 55.9
}, {
  name: 'HD 68988 b',
  radius: 13.64,
  distance: 58
}, {
  name: 'HD 69830 b',
  radius: 3.4,
  distance: 12.6
}, {
  name: 'HD 69830 c',
  radius: 3.68,
  distance: 12.6
}, {
  name: 'HD 69830 d',
  radius: 4.66,
  distance: 12.6
}, {
  name: 'HD 70573 b',
  radius: 13.12,
  distance: 45.7
}, {
  name: 'HD 70642 b',
  radius: 13.61,
  distance: 28.8
}, {
  name: 'HD 7199 b',
  radius: 11.47,
  distance: 36
}, {
  name: 'HD 72659 b',
  radius: 13.41,
  distance: 49.8
}, {
  name: 'HD 73256 b',
  radius: 13.64,
  distance: 36.5
}, {
  name: 'HD 73267 b',
  radius: 13.42,
  distance: 54.91
}, {
  name: 'HD 73526 b',
  radius: 13.45,
  distance: 99
}, {
  name: 'HD 73526 c',
  radius: 13.51,
  distance: 99
}, {
  name: 'HD 73534 b',
  radius: 13.86,
  distance: 96.99
}, {
  name: 'HD 74156 b',
  radius: 13.64,
  distance: 64.56
}, {
  name: 'HD 74156 c',
  radius: 13.01,
  distance: 64.56
}, {
  name: 'HD 7449 b',
  radius: 13.88,
  distance: 39
}, {
  name: 'HD 7449 c',
  radius: 13.61,
  distance: 39
}, {
  name: 'HD 75289 b',
  radius: 14.33,
  distance: 28.94
}, {
  name: 'HD 75898 b',
  radius: 13.51,
  distance: 80.58
}, {
  name: 'HD 76700 b',
  radius: 9.24,
  distance: 59.7
}, {
  name: 'HD 77338 b',
  radius: 14.18,
  distance: 40.75
}, {
  name: 'HD 7924 b',
  radius: 2.56,
  distance: 16.8
}, {
  name: 'HD 79498 b',
  radius: 13.79,
  distance: 49
}, {
  name: 'HD 80606 b',
  radius: 10.32,
  distance: 58.4
}, {
  name: 'HD 81040 b',
  radius: 13.07,
  distance: 32.56
}, {
  name: 'HD 81688 b',
  radius: 13.48,
  distance: 88.26
}, {
  name: 'HD 82886 b',
  radius: 13.81,
  distance: 125
}, {
  name: 'HD 82943 b',
  radius: 13.23,
  distance: 27.46
}, {
  name: 'HD 82943 c',
  radius: 13.23,
  distance: 27.46
}, {
  name: 'HD 82943 d',
  radius: 14.43,
  distance: 27.46
}, {
  name: 'HD 83443 b',
  radius: 14.35,
  distance: 43.54
}, {
  name: 'HD 8535 b',
  radius: 14.1,
  distance: 52.5
}, {
  name: 'HD 85390 b',
  radius: 7.38,
  distance: 33.96
}, {
  name: 'HD 85390 c',
  radius: 10.1,
  distance: 33.96
}, {
  name: 'HD 85512 b',
  radius: 1.6,
  distance: 11.15
}, {
  name: 'HD 8574 b',
  radius: 13.59,
  distance: 44.2
}, {
  name: 'HD 86081 b',
  radius: 13.74,
  distance: 91
}, {
  name: 'HD 86226 b',
  radius: 13.74,
  distance: 42.48
}, {
  name: 'HD 86264 b',
  radius: 13.07,
  distance: 72.6
}, {
  name: 'HD 8673 b',
  radius: 12.77,
  distance: 38.25
}, {
  name: 'HD 87883 b',
  radius: 12.83,
  distance: 18.1
}, {
  name: 'HD 88133 b',
  radius: 9.83,
  distance: 74.5
}, {
  name: 'HD 89307 b',
  radius: 13.61,
  distance: 30.9
}, {
  name: 'HD 89744 b',
  radius: 13.05,
  distance: 40
}, {
  name: 'HD 89744 c',
  radius: 13.34,
  distance: 40
}, {
  name: 'HD 90156 b',
  radius: 4.61,
  distance: 39.6
}, {
  name: 'HD 92788 b',
  radius: 13.32,
  distance: 32.82
}, {
  name: 'HD 92788 c',
  radius: 13.91,
  distance: 32.82
}, {
  name: 'HD 93083 b',
  radius: 14.39,
  distance: 28.9
}, {
  name: 'HD 93385 b',
  radius: 2.42,
  distance: 42.2
}, {
  name: 'HD 93385 c',
  radius: 3.33,
  distance: 42.2
}, {
  name: 'HD 9446 b',
  radius: 14.09,
  distance: 53
}, {
  name: 'HD 9446 c',
  radius: 13.66,
  distance: 53
}, {
  name: 'HD 95086 b',
  radius: 13.19,
  distance: 90.4
}, {
  name: 'HD 95089 b',
  radius: 13.84,
  distance: 139
}, {
  name: 'HD 9578 b',
  radius: 14.08,
  distance: 57.24
}, {
  name: 'HD 96063 b',
  radius: 13.97,
  distance: 158
}, {
  name: 'HD 96127 b',
  radius: 13.31,
  distance: 540
}, {
  name: 'HD 96167 b',
  radius: 14.1,
  distance: 84
}, {
  name: 'HD 96700 b',
  radius: 2.53,
  distance: 25.6
}, {
  name: 'HD 96700 c',
  radius: 3.78,
  distance: 25.6
}, {
  name: 'HD 97658 b',
  radius: 2.34,
  distance: 21.1
}, {
  name: 'HD 98219 b',
  radius: 13.66,
  distance: 134
}, {
  name: 'HD 98649 b',
  radius: 13.02,
  distance: 40.3
}, {
  name: 'HD 99109 b',
  radius: 14.24,
  distance: 60.5
}, {
  name: 'HD 99492 b',
  radius: 6.63,
  distance: 18
}, {
  name: 'HD 99492 c',
  radius: 14.4,
  distance: 18
}, {
  name: 'HD 99706 b',
  radius: 13.77,
  distance: 129
}, {
  name: 'HIP 12961 b',
  radius: 14.41,
  distance: 23
}, {
  name: 'HIP 13044 b',
  radius: 13.82,
  distance: 701
}, {
  name: 'HIP 14810 b',
  radius: 13.32,
  distance: 52.9
}, {
  name: 'HIP 14810 c',
  radius: 13.81,
  distance: 52.9
}, {
  name: 'HIP 14810 d',
  radius: 14.18,
  distance: 52.9
}, {
  name: 'HIP 5158 b',
  radius: 13.76,
  distance: 45
}, {
  name: 'HIP 5158 c',
  radius: 12.74,
  distance: 45
}, {
  name: 'HIP 57050 b',
  radius: 11.65,
  distance: 11
}, {
  name: 'HIP 57274 b',
  radius: 3.57,
  distance: 25.92
}, {
  name: 'HIP 57274 c',
  radius: 14.34,
  distance: 25.92
}, {
  name: 'HIP 57274 d',
  radius: 14.22,
  distance: 25.92
}, {
  name: 'HIP 63242 b',
  radius: 12.89,
  distance: 135
}, {
  name: 'HIP 70849 b',
  radius: 12.96,
  distance: 24
}, {
  name: 'HIP 75458 b',
  radius: 12.97,
  distance: 31.5
}, {
  name: 'HIP 77900 b',
  radius: 12.56,
  distance: null
}, {
  name: 'HIP 78530 b',
  radius: 12.57,
  distance: 156.7
}, {
  name: 'HIP 79431 b',
  radius: 13.59,
  distance: 14.4
}, {
  name: 'HIP 91258 b',
  radius: 13.83,
  distance: 44.9
}, {
  name: 'HN Peg b',
  radius: 12.32,
  distance: 18.4
}, {
  name: 'HR 228 b',
  radius: 13.47,
  distance: null
}, {
  name: 'HR 228 c',
  radius: 13.47,
  distance: null
}, {
  name: 'HR 810 b',
  radius: 13.56,
  distance: 17.17
}, {
  name: 'HR 8799 b',
  radius: 12.32,
  distance: 39.4
}, {
  name: 'HR 8799 c',
  radius: 14.56,
  distance: 39.4
}, {
  name: 'HR 8799 d',
  radius: 13.44,
  distance: 39.4
}, {
  name: 'HR 8799 e',
  radius: 12.96,
  distance: 39.4
}, {
  name: 'HU Aqr(AB) c',
  radius: 13.08,
  distance: null
}, {
  name: 'HW Vir(AB) b',
  radius: 12.76,
  distance: 181
}, {
  name: 'kappa And b',
  radius: 12.74,
  distance: 51.6
}, {
  name: 'kappa CrB b',
  radius: 13.71,
  distance: 31.1
}, {
  name: 'KELT-1 b',
  radius: 12.5,
  distance: 263
}, {
  name: 'KELT-2A b',
  radius: 14.63,
  distance: 128.9
}, {
  name: 'KELT-3 b',
  radius: 14.93,
  distance: 178
}, {
  name: 'KELT-6 b',
  radius: 13.33,
  distance: null
}, {
  name: 'Kepler-10 b',
  radius: 1.41,
  distance: 173
}, {
  name: 'Kepler-10 c',
  radius: 2.23,
  distance: 173
}, {
  name: 'Kepler-11 b',
  radius: 1.8,
  distance: null
}, {
  name: 'Kepler-11 c',
  radius: 2.87,
  distance: null
}, {
  name: 'Kepler-11 d',
  radius: 3.12,
  distance: null
}, {
  name: 'Kepler-11 e',
  radius: 4.53,
  distance: null
}, {
  name: 'Kepler-11 f',
  radius: 2.49,
  distance: null
}, {
  name: 'Kepler-11 g',
  radius: 3.67,
  distance: null
}, {
  name: 'Kepler-12 b',
  radius: 18.98,
  distance: null
}, {
  name: 'Kepler-13 b',
  radius: 20.5,
  distance: null
}, {
  name: 'Kepler-14 b',
  radius: 12.72,
  distance: 980
}, {
  name: 'Kepler-15 b',
  radius: 10.75,
  distance: null
}, {
  name: 'Kepler-16(AB) b',
  radius: 8.44,
  distance: null
}, {
  name: 'Kepler-17 b',
  radius: 14.69,
  distance: 800
}, {
  name: 'Kepler-18 b',
  radius: 2,
  distance: null
}, {
  name: 'Kepler-18 c',
  radius: 5.48,
  distance: null
}, {
  name: 'Kepler-18 d',
  radius: 6.96,
  distance: null
}, {
  name: 'Kepler-19 b',
  radius: 2.22,
  distance: null
}, {
  name: 'Kepler-20 b',
  radius: 1.9,
  distance: 290
}, {
  name: 'Kepler-20 c',
  radius: 3.06,
  distance: 290
}, {
  name: 'Kepler-20 d',
  radius: 2.8,
  distance: 290
}, {
  name: 'Kepler-20 e',
  radius: 0.87,
  distance: 290
}, {
  name: 'Kepler-20 f',
  radius: 1.01,
  distance: 290
}, {
  name: 'Kepler-21 b',
  radius: 1.63,
  distance: 108
}, {
  name: 'Kepler-22 b',
  radius: 2.35,
  distance: 190
}, {
  name: 'Kepler-23 b',
  radius: 1.9,
  distance: null
}, {
  name: 'Kepler-23 c',
  radius: 3.25,
  distance: null
}, {
  name: 'Kepler-24 b',
  radius: 2.35,
  distance: null
}, {
  name: 'Kepler-24 c',
  radius: 2.8,
  distance: null
}, {
  name: 'Kepler-25 b',
  radius: 2.58,
  distance: null
}, {
  name: 'Kepler-25 c',
  radius: 4.48,
  distance: null
}, {
  name: 'Kepler-26 b',
  radius: 3.58,
  distance: null
}, {
  name: 'Kepler-26 c',
  radius: 3.58,
  distance: null
}, {
  name: 'Kepler-27 b',
  radius: 4.03,
  distance: null
}, {
  name: 'Kepler-27 c',
  radius: 4.93,
  distance: null
}, {
  name: 'Kepler-28 b',
  radius: 3.58,
  distance: null
}, {
  name: 'Kepler-28 c',
  radius: 3.36,
  distance: null
}, {
  name: 'Kepler-29 b',
  radius: 3.58,
  distance: null
}, {
  name: 'Kepler-29 c',
  radius: 2.91,
  distance: null
}, {
  name: 'Kepler-30 b',
  radius: 3.92,
  distance: null
}, {
  name: 'Kepler-30 c',
  radius: 12.32,
  distance: null
}, {
  name: 'Kepler-30 d',
  radius: 8.85,
  distance: null
}, {
  name: 'Kepler-31 b',
  radius: 4.26,
  distance: null
}, {
  name: 'Kepler-31 c',
  radius: 4.26,
  distance: null
}, {
  name: 'Kepler-32 b',
  radius: 4.14,
  distance: null
}, {
  name: 'Kepler-32 c',
  radius: 3.7,
  distance: null
}, {
  name: 'Kepler-33 b',
  radius: 1.79,
  distance: null
}, {
  name: 'Kepler-33 c',
  radius: 3.25,
  distance: null
}, {
  name: 'Kepler-33 d',
  radius: 5.38,
  distance: null
}, {
  name: 'Kepler-33 e',
  radius: 4.03,
  distance: null
}, {
  name: 'Kepler-33 f',
  radius: 4.48,
  distance: null
}, {
  name: 'Kepler-34(AB)  b',
  radius: 8.55,
  distance: 1499
}, {
  name: 'Kepler-35(AB) b',
  radius: 8.15,
  distance: 1645
}, {
  name: 'Kepler-36 b',
  radius: 1.48,
  distance: null
}, {
  name: 'Kepler-36 c',
  radius: 3.68,
  distance: null
}, {
  name: 'Kepler-37 b',
  radius: 0.3,
  distance: 66
}, {
  name: 'Kepler-37 c',
  radius: 0.74,
  distance: 66
}, {
  name: 'Kepler-37 d',
  radius: 1.98,
  distance: 66
}, {
  name: 'Kepler-38(AB) b',
  radius: 4.37,
  distance: null
}, {
  name: 'Kepler-39 b',
  radius: 13.66,
  distance: 1200
}, {
  name: 'Kepler-4 b',
  radius: 4,
  distance: 550
}, {
  name: 'Kepler-40 b',
  radius: 13.1,
  distance: 2700
}, {
  name: 'Kepler-41  b',
  radius: 9.42,
  distance: 730
}, {
  name: 'Kepler-42 b',
  radius: 0.78,
  distance: 38.7
}, {
  name: 'Kepler-42 c',
  radius: 0.73,
  distance: 38.7
}, {
  name: 'Kepler-42 d',
  radius: 0.57,
  distance: 38.7
}, {
  name: 'Kepler-43 b',
  radius: 13.44,
  distance: 1950
}, {
  name: 'Kepler-44 b',
  radius: 13.89,
  distance: 2250
}, {
  name: 'Kepler-45 b',
  radius: 10.75,
  distance: 333
}, {
  name: 'Kepler-46 b',
  radius: 9.09,
  distance: 857
}, {
  name: 'Kepler-46 c',
  radius: 14.38,
  distance: 857
}, {
  name: 'Kepler-47(AB) b',
  radius: 3.02,
  distance: null
}, {
  name: 'Kepler-47(AB) c',
  radius: 4.59,
  distance: null
}, {
  name: 'Kepler-48 b',
  radius: 2.02,
  distance: null
}, {
  name: 'Kepler-48 c',
  radius: 3.36,
  distance: null
}, {
  name: 'Kepler-49 b',
  radius: 2.69,
  distance: null
}, {
  name: 'Kepler-49 c',
  radius: 2.58,
  distance: null
}, {
  name: 'Kepler-5 b',
  radius: 16.03,
  distance: null
}, {
  name: 'Kepler-50 b',
  radius: 2.24,
  distance: null
}, {
  name: 'Kepler-50 c',
  radius: 2.8,
  distance: null
}, {
  name: 'Kepler-51 b',
  radius: 7.06,
  distance: null
}, {
  name: 'Kepler-51 c',
  radius: 5.71,
  distance: null
}, {
  name: 'Kepler-52 b',
  radius: 2.13,
  distance: null
}, {
  name: 'Kepler-52 c',
  radius: 1.79,
  distance: null
}, {
  name: 'Kepler-53 b',
  radius: 2.91,
  distance: null
}, {
  name: 'Kepler-53 c',
  radius: 3.14,
  distance: null
}, {
  name: 'Kepler-54 b',
  radius: 2.13,
  distance: null
}, {
  name: 'Kepler-54 c',
  radius: 1.23,
  distance: null
}, {
  name: 'Kepler-55 b',
  radius: 2.46,
  distance: null
}, {
  name: 'Kepler-55 c',
  radius: 2.24,
  distance: null
}, {
  name: 'Kepler-56 b',
  radius: 6.5,
  distance: null
}, {
  name: 'Kepler-56 c',
  radius: 9.86,
  distance: null
}, {
  name: 'Kepler-56 d',
  radius: 13.33,
  distance: null
}, {
  name: 'Kepler-57 b',
  radius: 2.24,
  distance: null
}, {
  name: 'Kepler-57 c',
  radius: 1.57,
  distance: null
}, {
  name: 'Kepler-58 b',
  radius: 2.8,
  distance: null
}, {
  name: 'Kepler-58 c',
  radius: 2.91,
  distance: null
}, {
  name: 'Kepler-59 b',
  radius: 1.1,
  distance: null
}, {
  name: 'Kepler-59 c',
  radius: 2.02,
  distance: null
}, {
  name: 'Kepler-6 b',
  radius: 14.82,
  distance: null
}, {
  name: 'Kepler-60 b',
  radius: 2.24,
  distance: null
}, {
  name: 'Kepler-60 c',
  radius: 2.46,
  distance: null
}, {
  name: 'Kepler-60 d',
  radius: 2.58,
  distance: null
}, {
  name: 'Kepler-61 b',
  radius: 2.15,
  distance: 326
}, {
  name: 'Kepler-62 b',
  radius: 1.31,
  distance: 368
}, {
  name: 'Kepler-62 c',
  radius: 0.54,
  distance: 368
}, {
  name: 'Kepler-62 d',
  radius: 1.95,
  distance: 368
}, {
  name: 'Kepler-62 e',
  radius: 1.61,
  distance: 368
}, {
  name: 'Kepler-62 f',
  radius: 1.41,
  distance: 368
}, {
  name: 'Kepler-63 b',
  radius: 6.1,
  distance: 200
}, {
  name: 'Kepler-65 b',
  radius: 1.42,
  distance: null
}, {
  name: 'Kepler-65 c',
  radius: 2.58,
  distance: null
}, {
  name: 'Kepler-65 d',
  radius: 1.51,
  distance: null
}, {
  name: 'Kepler-66 b',
  radius: 2.8,
  distance: 1107
}, {
  name: 'Kepler-67 b',
  radius: 2.91,
  distance: 1107
}, {
  name: 'Kepler-68 b',
  radius: 2.3,
  distance: 135
}, {
  name: 'Kepler-68 c',
  radius: 0.91,
  distance: 135
}, {
  name: 'Kepler-68 d',
  radius: 13.89,
  distance: 135
}, {
  name: 'Kepler-69 b',
  radius: 2.24,
  distance: null
}, {
  name: 'Kepler-69 c',
  radius: 1.71,
  distance: null
}, {
  name: 'Kepler-7 b',
  radius: 18.08,
  distance: null
}, {
  name: 'Kepler-70  b',
  radius: 0.76,
  distance: 1180
}, {
  name: 'Kepler-70 c',
  radius: 0.87,
  distance: 1180
}, {
  name: 'Kepler-71 b',
  radius: 11.71,
  distance: null
}, {
  name: 'Kepler-74 b',
  radius: 14.78,
  distance: 1330
}, {
  name: 'Kepler-75 b',
  radius: 11.54,
  distance: 1140
}, {
  name: 'Kepler-76 b',
  radius: 14,
  distance: null
}, {
  name: 'Kepler-77 b',
  radius: 10.75,
  distance: 570
}, {
  name: 'Kepler-78 b',
  radius: 1.2,
  distance: null
}, {
  name: 'Kepler-79 b',
  radius: 3.3,
  distance: null
}, {
  name: 'Kepler-79 c',
  radius: 3.73,
  distance: null
}, {
  name: 'Kepler-79 d',
  radius: 7.17,
  distance: null
}, {
  name: 'Kepler-79 e',
  radius: 3.49,
  distance: null
}, {
  name: 'Kepler-8 b',
  radius: 15.89,
  distance: 1330
}, {
  name: 'Kepler-80 b',
  radius: 2.35,
  distance: null
}, {
  name: 'Kepler-80 c',
  radius: 2.58,
  distance: null
}, {
  name: 'Kepler-80 d',
  radius: 1.4,
  distance: null
}, {
  name: 'Kepler-80 e',
  radius: 1.5,
  distance: null
}, {
  name: 'Kepler-80 f',
  radius: 1.3,
  distance: null
}, {
  name: 'Kepler-81 b',
  radius: 2.54,
  distance: null
}, {
  name: 'Kepler-81 c',
  radius: 2.45,
  distance: null
}, {
  name: 'Kepler-82 b',
  radius: 4,
  distance: null
}, {
  name: 'Kepler-82 c',
  radius: 5.3,
  distance: null
}, {
  name: 'Kepler-83 b',
  radius: 2.34,
  distance: null
}, {
  name: 'Kepler-83 c',
  radius: 1.94,
  distance: null
}, {
  name: 'Kepler-84 b',
  radius: 2.2,
  distance: null
}, {
  name: 'Kepler-84 c',
  radius: 2.4,
  distance: null
}, {
  name: 'Kepler-85 b',
  radius: 1.99,
  distance: null
}, {
  name: 'Kepler-85 c',
  radius: 2.21,
  distance: null
}, {
  name: 'Kepler-86 b',
  radius: 10.14,
  distance: null
}, {
  name: 'Kepler-87 b',
  radius: 13.55,
  distance: null
}, {
  name: 'Kepler-87 c',
  radius: 6.15,
  distance: null
}, {
  name: 'Kepler-88 b',
  radius: 4.22,
  distance: 385
}, {
  name: 'Kepler-88 c',
  radius: 14.04,
  distance: 385
}, {
  name: 'Kepler-89 b',
  radius: 1.71,
  distance: null
}, {
  name: 'Kepler-89 c',
  radius: 4.32,
  distance: null
}, {
  name: 'Kepler-89 d',
  radius: 11.2,
  distance: null
}, {
  name: 'Kepler-89 e',
  radius: 6.16,
  distance: null
}, {
  name: 'Kepler-9 b',
  radius: 9.42,
  distance: null
}, {
  name: 'Kepler-9 c',
  radius: 9.21,
  distance: null
}, {
  name: 'Kepler-9 d',
  radius: 1.65,
  distance: null
}, {
  name: 'Kepler-90 b',
  radius: 1.31,
  distance: null
}, {
  name: 'Kepler-90 c',
  radius: 1.19,
  distance: null
}, {
  name: 'Kepler-90 d',
  radius: 2.88,
  distance: null
}, {
  name: 'Kepler-90 e',
  radius: 2.67,
  distance: null
}, {
  name: 'Kepler-90 f',
  radius: 2.89,
  distance: null
}, {
  name: 'Kepler-90 g',
  radius: 8.12,
  distance: null
}, {
  name: 'Kepler-90 h',
  radius: 11.31,
  distance: null
}, {
  name: 'KIC-10255705 b',
  radius: 7.28,
  distance: null
}, {
  name: 'KIC-10905746 b',
  radius: 2.65,
  distance: null
}, {
  name: 'KIC-11152511 b',
  radius: 4.03,
  distance: null
}, {
  name: 'KIC-12351927(AB) b',
  radius: 4.37,
  distance: null
}, {
  name: 'KIC-12454613 b',
  radius: 2.69,
  distance: null
}, {
  name: 'KIC-12557548 b',
  radius: 0.85,
  distance: 470
}, {
  name: 'KIC-5010054 b',
  radius: 6.94,
  distance: null
}, {
  name: 'KIC-5094412 b',
  radius: 5.71,
  distance: null
}, {
  name: 'KIC-5522786 b',
  radius: 1.23,
  distance: null
}, {
  name: 'KIC-5732155 b',
  radius: 12.77,
  distance: null
}, {
  name: 'KIC-6185331 b',
  radius: 8.06,
  distance: null
}, {
  name: 'KIC-6372194 b',
  radius: 8.29,
  distance: null
}, {
  name: 'KIC-6436029 c',
  radius: 3.07,
  distance: null
}, {
  name: 'KIC-8852719 b',
  radius: 3.53,
  distance: null
}, {
  name: 'KIC-9662267 b',
  radius: 3.81,
  distance: null
}, {
  name: 'KIC-9704149 b',
  radius: 4.48,
  distance: null
}, {
  name: 'KOI-111 b',
  radius: 2.14,
  distance: null
}, {
  name: 'KOI-111 c',
  radius: 2.05,
  distance: null
}, {
  name: 'KOI-115 b',
  radius: 4.82,
  distance: null
}, {
  name: 'KOI-115 c',
  radius: 1.91,
  distance: null
}, {
  name: 'KOI-117 b',
  radius: 1.58,
  distance: null
}, {
  name: 'KOI-117 c',
  radius: 1.71,
  distance: null
}, {
  name: 'KOI-1203 b',
  radius: 2.9,
  distance: null
}, {
  name: 'KOI-1203 c',
  radius: 2.8,
  distance: null
}, {
  name: 'KOI-1215 b',
  radius: 2.92,
  distance: null
}, {
  name: 'KOI-1215 c',
  radius: 3.36,
  distance: null
}, {
  name: 'KOI-1236 b',
  radius: 4.31,
  distance: null
}, {
  name: 'KOI-1236 c',
  radius: 3.1,
  distance: null
}, {
  name: 'KOI-1278 b',
  radius: 2.46,
  distance: null
}, {
  name: 'KOI-1278 c',
  radius: 3.1,
  distance: null
}, {
  name: 'KOI-156 b',
  radius: 1.18,
  distance: null
}, {
  name: 'KOI-156 c',
  radius: 1.6,
  distance: null
}, {
  name: 'KOI-156 d',
  radius: 2.53,
  distance: null
}, {
  name: 'KOI-1563 b',
  radius: 3.61,
  distance: null
}, {
  name: 'KOI-1563 c',
  radius: 3.3,
  distance: null
}, {
  name: 'KOI-1576 c',
  radius: 2.8,
  distance: null
}, {
  name: 'KOI-1676 b',
  radius: 3.2,
  distance: null
}, {
  name: 'KOI-1781 b',
  radius: 1.88,
  distance: null
}, {
  name: 'KOI-1843 b',
  radius: 0.58,
  distance: null
}, {
  name: 'KOI-1873 b',
  radius: 2.31,
  distance: null
}, {
  name: 'KOI-1873 c',
  radius: 5.41,
  distance: null
}, {
  name: 'KOI-202 b',
  radius: 11.42,
  distance: null
}, {
  name: 'KOI-2025 b',
  radius: 3.1,
  distance: null
}, {
  name: 'KOI-2025 c',
  radius: 2.8,
  distance: null
}, {
  name: 'KOI-206 b',
  radius: 7.84,
  distance: null
}, {
  name: 'KOI-2672 b',
  radius: 3.47,
  distance: null
}, {
  name: 'KOI-2672 c',
  radius: 5.26,
  distance: null
}, {
  name: 'KOI-274 b',
  radius: 1.13,
  distance: null
}, {
  name: 'KOI-274 c',
  radius: 1.13,
  distance: null
}, {
  name: 'KOI-282 b',
  radius: 2.9,
  distance: null
}, {
  name: 'KOI-285 b',
  radius: 3.52,
  distance: null
}, {
  name: 'KOI-285 c',
  radius: 2.61,
  distance: null
}, {
  name: 'KOI-370 b',
  radius: 2.65,
  distance: null
}, {
  name: 'KOI-370 c',
  radius: 4.42,
  distance: null
}, {
  name: 'KOI-523 b',
  radius: 2.9,
  distance: null
}, {
  name: 'KOI-523 c',
  radius: 7.11,
  distance: null
}, {
  name: 'KOI-680 b',
  radius: 7.28,
  distance: null
}, {
  name: 'KOI-730 b',
  radius: 3.47,
  distance: null
}, {
  name: 'KOI-730 c',
  radius: 2.58,
  distance: null
}, {
  name: 'KOI-730 d',
  radius: 2.8,
  distance: null
}, {
  name: 'KOI-730 e',
  radius: 2.02,
  distance: null
}, {
  name: 'KOI-82 b',
  radius: 2.2,
  distance: null
}, {
  name: 'KOI-82 c',
  radius: 1.34,
  distance: null
}, {
  name: 'KOI-82 d',
  radius: 0.69,
  distance: null
}, {
  name: 'KOI-834 b',
  radius: 5.61,
  distance: null
}, {
  name: 'KOI-834 c',
  radius: 2,
  distance: null
}, {
  name: 'ksi Aql b',
  radius: 13.46,
  distance: 62.7
}, {
  name: 'LKCA 15 b',
  radius: 13.13,
  distance: 145
}, {
  name: 'MOA-2007-BLG-192-L b',
  radius: 1.54,
  distance: 700
}, {
  name: 'MOA-2007-BLG-400-L b',
  radius: 13.97,
  distance: 6000
}, {
  name: 'MOA-2008-BLG-310-L b',
  radius: 10.07,
  distance: 6000
}, {
  name: 'MOA-2008-BLG-379L b',
  radius: 13.17,
  distance: 3600
}, {
  name: 'MOA-2009-BLG-266L b',
  radius: 3.38,
  distance: 3040
}, {
  name: 'MOA-2009-BLG-319 b',
  radius: 8.14,
  distance: 6100
}, {
  name: 'MOA-2009-BLG-387L b',
  radius: 13.5,
  distance: 5700
}, {
  name: 'MOA-2010-BLG-328L b',
  radius: 3.42,
  distance: 810
}, {
  name: 'MOA-2010-BLG-477L b',
  radius: 13.74,
  distance: 2300
}, {
  name: 'MOA-2011-BLG-293L b',
  radius: 13.23,
  distance: 7700
}, {
  name: 'MOA-2011-BLG-322 b',
  radius: 12.96,
  distance: 7740
}, {
  name: 'MOA-bin-1 b',
  radius: 13.34,
  distance: 5100
}, {
  name: 'mu Ara b',
  radius: 13.69,
  distance: 15.3
}, {
  name: 'mu Ara c',
  radius: 3.41,
  distance: 15.3
}, {
  name: 'mu Ara d',
  radius: 14.23,
  distance: 15.3
}, {
  name: 'mu Ara e',
  radius: 13.66,
  distance: 15.3
}, {
  name: 'NGC 2423 3 b',
  radius: 12.89,
  distance: 766
}, {
  name: 'NGC 4349 No 127 b',
  radius: 12.63,
  distance: 2176
}, {
  name: 'NN Ser(AB) c',
  radius: 13.07,
  distance: 500
}, {
  name: 'NN Ser(AB) d',
  radius: 13.55,
  distance: 500
}, {
  name: 'nu Oph b',
  radius: 12.55,
  distance: 46.8
}, {
  name: 'nu Oph c',
  radius: 12.5,
  distance: 46.8
}, {
  name: 'NY Vir b',
  radius: 13.49,
  distance: null
}, {
  name: 'OGLE-05-071L b',
  radius: 13.37,
  distance: 3300
}, {
  name: 'OGLE-05-169L b',
  radius: 3.78,
  distance: 2700
}, {
  name: 'OGLE-05-390L b',
  radius: 1.94,
  distance: 6500
}, {
  name: 'OGLE-06-109L b',
  radius: 14.07,
  distance: 1510
}, {
  name: 'OGLE-06-109L c',
  radius: 11.04,
  distance: 1510
}, {
  name: 'OGLE-2007-BLG-368L b',
  radius: 5.15,
  distance: 5900
}, {
  name: 'OGLE-2009-BLG-151_MOA-2009-232 b',
  radius: 12.97,
  distance: 390
}, {
  name: 'OGLE-2011-BLG-0251 b',
  radius: 13.88,
  distance: 4090
}, {
  name: 'OGLE-2011-BLG-0420 b',
  radius: 12.88,
  distance: 1990
}, {
  name: 'OGLE-2012-BLG-0026L b',
  radius: 7.22,
  distance: 4080
}, {
  name: 'OGLE-2012-BLG-0026L c',
  radius: 14.04,
  distance: 4080
}, {
  name: 'OGLE-2012-BLG-0358L b',
  radius: 13.58,
  distance: 1760
}, {
  name: 'OGLE-2012-BLG-0406L b',
  radius: 13.41,
  distance: 4970
}, {
  name: 'OGLE-TR-10 b',
  radius: 19.26,
  distance: 1500
}, {
  name: 'OGLE-TR-111 b',
  radius: 12.06,
  distance: 1500
}, {
  name: 'OGLE-TR-113 b',
  radius: 12.43,
  distance: 1500
}, {
  name: 'OGLE-TR-132 b',
  radius: 13.78,
  distance: 1500
}, {
  name: 'OGLE-TR-182 b',
  radius: 16.46,
  distance: null
}, {
  name: 'OGLE-TR-211 b',
  radius: 14.11,
  distance: null
}, {
  name: 'OGLE-TR-56 b',
  radius: 13.44,
  distance: 1500
}, {
  name: 'OGLE2-TR-L9 b',
  radius: 18.08,
  distance: 900
}, {
  name: 'OGLE235-MOA53 b',
  radius: 13.5,
  distance: 5200
}, {
  name: 'ome Ser b',
  radius: 13.62,
  distance: 80.6
}, {
  name: 'omi CrB b',
  radius: 13.68,
  distance: 84
}, {
  name: 'omi UMa b',
  radius: 13.23,
  distance: 56.3
}, {
  name: 'Oph 11 b',
  radius: 12.6,
  distance: 145
}, {
  name: 'PH1-Kepler-64 b',
  radius: 6.19,
  distance: null
}, {
  name: 'POTS-1 b',
  radius: 10.54,
  distance: null
}, {
  name: 'Pr0201 c',
  radius: 14.14,
  distance: null
}, {
  name: 'Pr0211 b',
  radius: 13.59,
  distance: null
}, {
  name: 'PSR 1257 12 b',
  radius: 0.3,
  distance: 710
}, {
  name: 'PSR 1257 12 c',
  radius: 1.72,
  distance: 710
}, {
  name: 'PSR 1257 12 d',
  radius: 1.66,
  distance: 710
}, {
  name: 'PSR 1719-14 b',
  radius: 4.48,
  distance: 1200
}, {
  name: 'PSR B1620-26 b',
  radius: 13.51,
  distance: 3800
}, {
  name: 'Qatar-1 b',
  radius: 13.04,
  distance: null
}, {
  name: 'Qatar-2 b',
  radius: 12.81,
  distance: null
}, {
  name: 'Ross 458(AB) c',
  radius: 12.98,
  distance: 114
}, {
  name: 'ROXs 42B b',
  radius: 12.83,
  distance: 135
}, {
  name: 'RR Cae b',
  radius: 13.22,
  distance: null
}, {
  name: 'SR 12 AB c',
  radius: 12.8,
  distance: 125
}, {
  name: 'SWEEPS-04b',
  radius: 9.07,
  distance: 8500
}, {
  name: 'SWEEPS-11b',
  radius: 12.66,
  distance: 8500
}, {
  name: 'tau Boo b',
  radius: 13.14,
  distance: 15.6
}, {
  name: 'tau Gem b',
  radius: 12.55,
  distance: 98.4
}, {
  name: 'TrES-1b',
  radius: 12.31,
  distance: 157
}, {
  name: 'TrES-2b',
  radius: 13.09,
  distance: 220
}, {
  name: 'TrES-3b',
  radius: 14.62,
  distance: null
}, {
  name: 'TrES-4b',
  radius: 19.11,
  distance: 479
}, {
  name: 'TrES-5b',
  radius: 13.54,
  distance: 360
}, {
  name: 'ups And b',
  radius: 14.15,
  distance: 13.47
}, {
  name: 'ups And c',
  radius: 13.66,
  distance: 13.47
}, {
  name: 'ups And d',
  radius: 12.91,
  distance: 13.47
}, {
  name: 'ups And e',
  radius: 13.9,
  distance: 13.47
}, {
  name: 'USco1602-2401 b',
  radius: 12.22,
  distance: null
}, {
  name: 'USco1610-1913 b',
  radius: 12.56,
  distance: null
}, {
  name: 'USco1612-1800 b',
  radius: 12.46,
  distance: null
}, {
  name: 'UScoCTIO 108 b',
  radius: 12.72,
  distance: 145
}, {
  name: 'UZ For(ab) d',
  radius: 12.96,
  distance: null
}, {
  name: 'V391 Peg b',
  radius: 13.41,
  distance: 1400
}, {
  name: 'WASP-1 b',
  radius: 16.62,
  distance: null
}, {
  name: 'WASP-10 b',
  radius: 12.1,
  distance: 90
}, {
  name: 'WASP-100 b',
  radius: 18.93,
  distance: null
}, {
  name: 'WASP-101 b',
  radius: 15.79,
  distance: null
}, {
  name: 'WASP-11-HAT-P-10 b',
  radius: 11.7,
  distance: 125
}, {
  name: 'WASP-12 b',
  radius: 19.44,
  distance: 427
}, {
  name: 'WASP-13 b',
  radius: 15.29,
  distance: 156
}, {
  name: 'WASP-14 b',
  radius: 14.35,
  distance: 160
}, {
  name: 'WASP-15 b',
  radius: 15.99,
  distance: 308
}, {
  name: 'WASP-16 b',
  radius: 11.29,
  distance: null
}, {
  name: 'WASP-17 b',
  radius: 22.3,
  distance: null
}, {
  name: 'WASP-18 b',
  radius: 13.05,
  distance: 100
}, {
  name: 'WASP-19 b',
  radius: 15.62,
  distance: null
}, {
  name: 'WASP-2 b',
  radius: 12.08,
  distance: 144
}, {
  name: 'WASP-20 b',
  radius: 10.08,
  distance: null
}, {
  name: 'WASP-21 b',
  radius: 13.55,
  distance: 230
}, {
  name: 'WASP-22 b',
  radius: 12.97,
  distance: 300
}, {
  name: 'WASP-23 b',
  radius: 10.77,
  distance: null
}, {
  name: 'WASP-24 b',
  radius: 12.36,
  distance: 330
}, {
  name: 'WASP-25 b',
  radius: 14.11,
  distance: 169
}, {
  name: 'WASP-26 b',
  radius: 14.35,
  distance: 250
}, {
  name: 'WASP-28 b',
  radius: 12.54,
  distance: 334
}, {
  name: 'WASP-29 b',
  radius: 9.42,
  distance: 80
}, {
  name: 'WASP-3 b',
  radius: 16.28,
  distance: 223
}, {
  name: 'WASP-31 b',
  radius: 17.21,
  distance: 400
}, {
  name: 'WASP-32 b',
  radius: 13.22,
  distance: null
}, {
  name: 'WASP-33 b',
  radius: 16.11,
  distance: 116
}, {
  name: 'WASP-34 b',
  radius: 13.66,
  distance: 120
}, {
  name: 'WASP-35 b',
  radius: 14.78,
  distance: null
}, {
  name: 'WASP-36 b',
  radius: 14.21,
  distance: 450
}, {
  name: 'WASP-37 b',
  radius: 12.72,
  distance: 338
}, {
  name: 'WASP-38 b',
  radius: 12.08,
  distance: 110
}, {
  name: 'WASP-39 b',
  radius: 14.22,
  distance: 230
}, {
  name: 'WASP-4 b',
  radius: 15.62,
  distance: 300
}, {
  name: 'WASP-41 b',
  radius: 13.55,
  distance: 180
}, {
  name: 'WASP-42 b',
  radius: 12.1,
  distance: null
}, {
  name: 'WASP-43 b',
  radius: 11.6,
  distance: null
}, {
  name: 'WASP-44 b',
  radius: 12.77,
  distance: null
}, {
  name: 'WASP-45 b',
  radius: 12.99,
  distance: null
}, {
  name: 'WASP-46 b',
  radius: 14.67,
  distance: null
}, {
  name: 'WASP-47 b',
  radius: 12.88,
  distance: 200
}, {
  name: 'WASP-48 b',
  radius: 18.7,
  distance: null
}, {
  name: 'WASP-49 b',
  radius: 12.49,
  distance: null
}, {
  name: 'WASP-5 b',
  radius: 13.12,
  distance: 297
}, {
  name: 'WASP-50 b',
  radius: 12.75,
  distance: 230
}, {
  name: 'WASP-52 b',
  radius: 14.22,
  distance: 140
}, {
  name: 'WASP-53 b',
  radius: 13.44,
  distance: null
}, {
  name: 'WASP-54 b',
  radius: 15.68,
  distance: null
}, {
  name: 'WASP-55 b',
  radius: 14.56,
  distance: 330
}, {
  name: 'WASP-56 b',
  radius: 13.44,
  distance: null
}, {
  name: 'WASP-57 b',
  radius: 12.32,
  distance: null
}, {
  name: 'WASP-58 b',
  radius: 15.34,
  distance: 300
}, {
  name: 'WASP-59 b',
  radius: 10.08,
  distance: 125
}, {
  name: 'WASP-6 b',
  radius: 13.71,
  distance: 307
}, {
  name: 'WASP-60 b',
  radius: 10.08,
  distance: 400
}, {
  name: 'WASP-61 b',
  radius: 13.89,
  distance: 480
}, {
  name: 'WASP-62 b',
  radius: 15.57,
  distance: 160
}, {
  name: 'WASP-63 b',
  radius: 16.02,
  distance: 330
}, {
  name: 'WASP-64 b',
  radius: 7.84,
  distance: null
}, {
  name: 'WASP-65 b',
  radius: 12.45,
  distance: 310
}, {
  name: 'WASP-66 b',
  radius: 15.57,
  distance: 380
}, {
  name: 'WASP-67 b',
  radius: 15.68,
  distance: 225
}, {
  name: 'WASP-68 b',
  radius: 10.08,
  distance: null
}, {
  name: 'WASP-69 b',
  radius: 11.2,
  distance: null
}, {
  name: 'WASP-7 b',
  radius: 14.9,
  distance: 140
}, {
  name: 'WASP-70 b',
  radius: 8.96,
  distance: null
}, {
  name: 'WASP-71 b',
  radius: 16.8,
  distance: 200
}, {
  name: 'WASP-72 b',
  radius: 14.22,
  distance: null
}, {
  name: 'WASP-75 b',
  radius: 14.22,
  distance: 260
}, {
  name: 'WASP-76 b',
  radius: 20.5,
  distance: 120
}, {
  name: 'WASP-77A b',
  radius: 13.55,
  distance: null
}, {
  name: 'WASP-78 b',
  radius: 19.6,
  distance: 550
}, {
  name: 'WASP-79 b',
  radius: 19.04,
  distance: 240
}, {
  name: 'WASP-8 b',
  radius: 11.63,
  distance: 87
}, {
  name: 'WASP-80 b',
  radius: 10.66,
  distance: 60
}, {
  name: 'WASP-82 b',
  radius: 18.7,
  distance: 200
}, {
  name: 'WASP-84 b',
  radius: 10.55,
  distance: 120
}, {
  name: 'WASP-90 b',
  radius: 18.26,
  distance: 340
}, {
  name: 'WASP-95 b',
  radius: 13.55,
  distance: null
}, {
  name: 'WASP-96 b',
  radius: 13.44,
  distance: null
}, {
  name: 'WASP-97 b',
  radius: 12.66,
  distance: null
}, {
  name: 'WASP-98 b',
  radius: 12.32,
  distance: null
}, {
  name: 'WASP-99 b',
  radius: 12.32,
  distance: null
}, {
  name: 'WD 0806-661B b',
  radius: 13.01,
  distance: 19.2
}, {
  name: 'WISE 1217+16A b',
  radius: 11.2,
  distance: 10
}, {
  name: 'WISE 1711+3500 b',
  radius: 12.74,
  distance: 19
}, {
  name: 'WTS-1 b',
  radius: 16.69,
  distance: 3200
}, {
  name: 'WTS-2 b',
  radius: 14.56,
  distance: 1000
}, {
  name: 'XO-1 b',
  radius: 13.26,
  distance: 200
}, {
  name: 'XO-2 b',
  radius: 10.9,
  distance: 149
}, {
  name: 'XO-3 b',
  radius: 13.63,
  distance: 260
}, {
  name: 'XO-4 b',
  radius: 15.01,
  distance: 293
}, {
  name: 'XO-5 b',
  radius: 11.54,
  distance: 255
}];
export default exoplanets;