import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledValue = styled.li`
  span:first-of-type {
    font-weight: bold;
  }
`;

export const StyledHeaderValues = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li:not(:last-of-type) {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid ${COLORS.blueBayoux};
  }
`;
