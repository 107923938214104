import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledDragDropList = styled.div`
  border-left: solid 3px ${COLORS.defaultFontColor};
  padding: 8px;
  margin-bottom: 20px;

  .list-header {
    margin-bottom: 15px;
  }
`;

export const StyledDragDropListItem = styled.div`
  position: relative;
  display: flex;

  align-items: center;
  justify-content: space-between;

  height: 36px;
  transition: background-color 0.3s, transform 0.25s;

  &.dragging,
  &.active {
    background-color: ${COLORS.mediumGray} !important;
  }

  // 39px = item height + vertical margin
  &.animate-up {
    transform: translate(0, -39px);
  }

  &.animate-down {
    transform: translate(0, 39px);
  }

  &.animate-up,
  &.animate-down {
    &:not(.active) {
      // ensures clicked item remains visually on top during movement
      z-index: -1;
    }
  }

  .list-item-text {
    margin-left: 16px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .confirm-delete-container {
    display: flex;
    align-items: center;

    .confirm,
    .cancel {
      color: white;
      box-shadow: none;
      height: 36px;
    }

    .confirm {
      margin-left: 16px;
      background-color: #1777c2;
    }

    .cancel {
      margin-left: 8px;
      background-color: ${COLORS.red};
    }
  }

  .list-buttons {
    display: flex;
    margin-left: 10px;
    justify-content: flex-end;

    Button {
      padding: 0;
      background: none;
      box-shadow: none;
      color: ${COLORS.defaultFontColor};

      &:disabled {
        opacity: 0.2;
      }
    }

    .arrow-down {
      margin-left: 8px;
      margin-right: 12px;
    }

    .arrow-up {
      margin-left: 12px;
    }

    .edit {
      margin-left: 18px;
    }
  }
`;

export const StyledAddItemContainer = styled.div`
  display: flex;

  // visually 3px, but it abuts a negative margin, so...
  margin-top: 6px;

  .add-item-input {
    background-color: ${COLORS.oxfordBlue};
    margin: 0;
  }

  .add-btn,
  .confirm,
  .cancel {
    height: 30px;
    margin-top: 2px;
    padding: 0;
  }

  .confirm,
  .cancel {
    width: 33px;
    border-radius: 50%;
    margin-right: 6px;
    color: ${COLORS.white};
  }

  .confirm {
    background-color: ${COLORS.green};

    &:disabled {
      opacity: 0.5;
    }
  }

  .cancel {
    background-color: ${COLORS.red};
  }

  .add-btn {
    width: 100%;
    color: ${COLORS.midFontColor};
    background: none;
    box-shadow: none;
  }

  &.dash-border {
    border: 1px dashed ${COLORS.midFontColor};
    border-radius: 3px;
  }

  &.solid-back {
    background-color: ${COLORS.oxfordBlue};
  }
`;
