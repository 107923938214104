export { animation as ANIMATION } from './animation';
export { breakpoints as BREAKPOINT, mediaQueries as MEDIA_QUERIES } from './breakpoints';
export { typography as TYPOGRAPHY, fontSize as FONT_SIZE } from './typography';
export { spacings as SPACING } from './spacings';
export { MATH } from './numbers';

export {
  allColors as COLORS,
  baseColors as BASE_COLORS,
  fieldColors as LABEL_COLORS, // TODO: update this to FIELD_COLORS across the codebase
  layoutColors as LAYOUT_COLORS,
  grayColors as GRAY_COLORS,
  fontColors as FONT_COLORS,
  buttonColors as BUTTON_COLORS,
  newButtonColors as NEW_BUTTON_COLORS,
  vizColors as VIZ_COLORS,
  vizColorsArray as VIZ_COLORS_ARRAY,
} from './colors';
