import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { Button } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledTabs } from './TabbedInterface.styles';

type Tab = { id: string; name: string };

export type NavigationTab = Tab & { route: string };
export type SectionTab = Tab;

type Props = PermafrostComponent & {
  children: React.ReactNode;
  currentTab?: string;
  fullWidthTabs?: boolean;
  onTabSelect?(id: string): void;
  tabType: 'navigation' | 'section';
  tabs: Array<NavigationTab | SectionTab>;
};

/**
 * Abstract/utility component which should **not** be used directly. Instead, use components:
 * - `TabNavigation` to navigate between URLs
 * - `TabbedContainer` to switch between blocks of content
 */
export function TabbedInterface(props: Props) {
  const { children, className, currentTab, fullWidthTabs, id, onTabSelect, tabs, tabType } = props;

  const [current, setCurrent] = useState<string>(currentTab || tabs[0].id);

  const handleTabSelect = (id: string) => {
    if (!!onTabSelect) {
      onTabSelect(id);
    }

    setCurrent(id);
  };

  const navigationTab = (props: NavigationTab): React.ReactElement => {
    const { id, name, route } = props;

    return (
      <Link
        to={route}
        role="tab"
        aria-selected={id === current}
        id={id}
        onClick={() => handleTabSelect(id)}
      >
        {name}
      </Link>
    );
  };

  const sectionTab = (props: SectionTab): React.ReactElement => {
    const { id, name } = props;

    return (
      <Button
        role="tab"
        variant="no-style"
        aria-selected={id === current}
        id={id}
        onClick={() => handleTabSelect(id)}
        className={fullWidthTabs ? 'fullWidthTabs' : ''}
      >
        {name}
      </Button>
    );
  };

  const tabStyle = (tabQuantity: number) => {
    return { flexBasis: `${100 / tabQuantity}%` };
  };

  return (
    <div
      className={classNames('tabbedInterface', className)}
      data-cy={props['data-cy']}
      id={id}
      style={{ paddingBottom: '2em' }}
    >
      <StyledTabs role="tablist">
        {tabs.map((tab: Tab) => (
          <li key={tab.id} role="presentation" style={fullWidthTabs ? tabStyle(tabs.length) : {}}>
            {tabType === 'navigation'
              ? navigationTab(tab as NavigationTab)
              : sectionTab(tab as SectionTab)}
          </li>
        ))}
      </StyledTabs>

      <div role="tabpanel" aria-labelledby={current}>
        {children}
      </div>
    </div>
  );
}
