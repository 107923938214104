import React from 'react';

import classNames from 'classnames';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledCard } from './Card.styles';

type Props = PermafrostComponent & {
  style?: object;
  children: React.ReactNode;
};

export const Card = (props: Props) => {
  const { className, style = {}, children, id, ...restOfProps } = props;

  return (
    <StyledCard
      className={classNames('Card', className)}
      data-cy={props['data-cy']}
      id={id}
      style={style}
      {...restOfProps}
    >
      {children}
    </StyledCard>
  );
};
