var appleStock = [{
  date: '2007-04-24T07:00:00.000Z',
  close: 93.24
}, {
  date: '2007-04-25T07:00:00.000Z',
  close: 95.35
}, {
  date: '2007-04-26T07:00:00.000Z',
  close: 98.84
}, {
  date: '2007-04-27T07:00:00.000Z',
  close: 99.92
}, {
  date: '2007-04-30T07:00:00.000Z',
  close: 99.8
}, {
  date: '2007-05-01T07:00:00.000Z',
  close: 99.47
}, {
  date: '2007-05-02T07:00:00.000Z',
  close: 100.39
}, {
  date: '2007-05-03T07:00:00.000Z',
  close: 100.4
}, {
  date: '2007-05-04T07:00:00.000Z',
  close: 100.81
}, {
  date: '2007-05-07T07:00:00.000Z',
  close: 103.92
}, {
  date: '2007-05-08T07:00:00.000Z',
  close: 105.06
}, {
  date: '2007-05-09T07:00:00.000Z',
  close: 106.88
}, {
  date: '2007-05-10T07:00:00.000Z',
  close: 107.34
}, {
  date: '2007-05-11T07:00:00.000Z',
  close: 108.74
}, {
  date: '2007-05-14T07:00:00.000Z',
  close: 109.36
}, {
  date: '2007-05-15T07:00:00.000Z',
  close: 107.52
}, {
  date: '2007-05-16T07:00:00.000Z',
  close: 107.34
}, {
  date: '2007-05-17T07:00:00.000Z',
  close: 109.44
}, {
  date: '2007-05-18T07:00:00.000Z',
  close: 110.02
}, {
  date: '2007-05-21T07:00:00.000Z',
  close: 111.98
}, {
  date: '2007-05-22T07:00:00.000Z',
  close: 113.54
}, {
  date: '2007-05-23T07:00:00.000Z',
  close: 112.89
}, {
  date: '2007-05-24T07:00:00.000Z',
  close: 110.69
}, {
  date: '2007-05-25T07:00:00.000Z',
  close: 113.62
}, {
  date: '2007-05-29T07:00:00.000Z',
  close: 114.35
}, {
  date: '2007-05-30T07:00:00.000Z',
  close: 118.77
}, {
  date: '2007-05-31T07:00:00.000Z',
  close: 121.19
}, {
  date: '2007-06-01T07:00:00.000Z',
  close: 118.4
}, {
  date: '2007-06-04T07:00:00.000Z',
  close: 121.33
}, {
  date: '2007-06-05T07:00:00.000Z',
  close: 122.67
}, {
  date: '2007-06-06T07:00:00.000Z',
  close: 123.64
}, {
  date: '2007-06-07T07:00:00.000Z',
  close: 124.07
}, {
  date: '2007-06-08T07:00:00.000Z',
  close: 124.49
}, {
  date: '2007-06-11T07:00:00.000Z',
  close: 120.19
}, {
  date: '2007-06-12T07:00:00.000Z',
  close: 120.38
}, {
  date: '2007-06-13T07:00:00.000Z',
  close: 117.5
}, {
  date: '2007-06-14T07:00:00.000Z',
  close: 118.75
}, {
  date: '2007-06-15T07:00:00.000Z',
  close: 120.5
}, {
  date: '2007-06-18T07:00:00.000Z',
  close: 125.09
}, {
  date: '2007-06-19T07:00:00.000Z',
  close: 123.66
}, {
  date: '2007-06-20T07:00:00.000Z',
  close: 121.55
}, {
  date: '2007-06-21T07:00:00.000Z',
  close: 123.9
}, {
  date: '2007-06-22T07:00:00.000Z',
  close: 123
}, {
  date: '2007-06-25T07:00:00.000Z',
  close: 122.34
}, {
  date: '2007-06-26T07:00:00.000Z',
  close: 119.65
}, {
  date: '2007-06-27T07:00:00.000Z',
  close: 121.89
}, {
  date: '2007-06-28T07:00:00.000Z',
  close: 120.56
}, {
  date: '2007-06-29T07:00:00.000Z',
  close: 122.04
}, {
  date: '2007-07-02T07:00:00.000Z',
  close: 121.26
}, {
  date: '2007-07-03T07:00:00.000Z',
  close: 127.17
}, {
  date: '2007-07-05T07:00:00.000Z',
  close: 132.75
}, {
  date: '2007-07-06T07:00:00.000Z',
  close: 132.3
}, {
  date: '2007-07-09T07:00:00.000Z',
  close: 130.33
}, {
  date: '2007-07-10T07:00:00.000Z',
  close: 132.35
}, {
  date: '2007-07-11T07:00:00.000Z',
  close: 132.39
}, {
  date: '2007-07-12T07:00:00.000Z',
  close: 134.07
}, {
  date: '2007-07-13T07:00:00.000Z',
  close: 137.73
}, {
  date: '2007-07-16T07:00:00.000Z',
  close: 138.1
}, {
  date: '2007-07-17T07:00:00.000Z',
  close: 138.91
}, {
  date: '2007-07-18T07:00:00.000Z',
  close: 138.12
}, {
  date: '2007-07-19T07:00:00.000Z',
  close: 140
}, {
  date: '2007-07-20T07:00:00.000Z',
  close: 143.75
}, {
  date: '2007-07-23T07:00:00.000Z',
  close: 143.7
}, {
  date: '2007-07-24T07:00:00.000Z',
  close: 134.89
}, {
  date: '2007-07-25T07:00:00.000Z',
  close: 137.26
}, {
  date: '2007-07-26T07:00:00.000Z',
  close: 146
}, {
  date: '2007-07-27T07:00:00.000Z',
  close: 143.85
}, {
  date: '2007-07-30T07:00:00.000Z',
  close: 141.43
}, {
  date: '2007-07-31T07:00:00.000Z',
  close: 131.76
}, {
  date: '2007-08-01T07:00:00.000Z',
  close: 135
}, {
  date: '2007-08-02T07:00:00.000Z',
  close: 136.49
}, {
  date: '2007-08-03T07:00:00.000Z',
  close: 131.85
}, {
  date: '2007-08-06T07:00:00.000Z',
  close: 135.25
}, {
  date: '2007-08-07T07:00:00.000Z',
  close: 135.03
}, {
  date: '2007-08-08T07:00:00.000Z',
  close: 134.01
}, {
  date: '2007-08-09T07:00:00.000Z',
  close: 126.39
}, {
  date: '2007-08-10T07:00:00.000Z',
  close: 125
}, {
  date: '2007-08-13T07:00:00.000Z',
  close: 127.79
}, {
  date: '2007-08-14T07:00:00.000Z',
  close: 124.03
}, {
  date: '2007-08-15T07:00:00.000Z',
  close: 119.9
}, {
  date: '2007-08-16T07:00:00.000Z',
  close: 117.05
}, {
  date: '2007-08-17T07:00:00.000Z',
  close: 122.06
}, {
  date: '2007-08-20T07:00:00.000Z',
  close: 122.22
}, {
  date: '2007-08-21T07:00:00.000Z',
  close: 127.57
}, {
  date: '2007-08-22T07:00:00.000Z',
  close: 132.51
}, {
  date: '2007-08-23T07:00:00.000Z',
  close: 131.07
}, {
  date: '2007-08-24T07:00:00.000Z',
  close: 135.3
}, {
  date: '2007-08-27T07:00:00.000Z',
  close: 132.25
}, {
  date: '2007-08-28T07:00:00.000Z',
  close: 126.82
}, {
  date: '2007-08-29T07:00:00.000Z',
  close: 134.08
}, {
  date: '2007-08-30T07:00:00.000Z',
  close: 136.25
}, {
  date: '2007-08-31T07:00:00.000Z',
  close: 138.48
}, {
  date: '2007-09-04T07:00:00.000Z',
  close: 144.16
}, {
  date: '2007-09-05T07:00:00.000Z',
  close: 136.76
}, {
  date: '2007-09-06T07:00:00.000Z',
  close: 135.01
}, {
  date: '2007-09-07T07:00:00.000Z',
  close: 131.77
}, {
  date: '2007-09-10T07:00:00.000Z',
  close: 136.71
}, {
  date: '2007-09-11T07:00:00.000Z',
  close: 135.49
}, {
  date: '2007-09-12T07:00:00.000Z',
  close: 136.85
}, {
  date: '2007-09-13T07:00:00.000Z',
  close: 137.2
}, {
  date: '2007-09-14T07:00:00.000Z',
  close: 138.81
}, {
  date: '2007-09-17T07:00:00.000Z',
  close: 138.41
}, {
  date: '2007-09-18T07:00:00.000Z',
  close: 140.92
}, {
  date: '2007-09-19T07:00:00.000Z',
  close: 140.77
}, {
  date: '2007-09-20T07:00:00.000Z',
  close: 140.31
}, {
  date: '2007-09-21T07:00:00.000Z',
  close: 144.15
}, {
  date: '2007-09-24T07:00:00.000Z',
  close: 148.28
}, {
  date: '2007-09-25T07:00:00.000Z',
  close: 153.18
}, {
  date: '2007-09-26T07:00:00.000Z',
  close: 152.77
}, {
  date: '2007-09-27T07:00:00.000Z',
  close: 154.5
}, {
  date: '2007-09-28T07:00:00.000Z',
  close: 153.47
}, {
  date: '2007-10-01T07:00:00.000Z',
  close: 156.34
}, {
  date: '2007-10-02T07:00:00.000Z',
  close: 158.45
}, {
  date: '2007-10-03T07:00:00.000Z',
  close: 157.92
}, {
  date: '2007-10-04T07:00:00.000Z',
  close: 156.24
}, {
  date: '2007-10-05T07:00:00.000Z',
  close: 161.45
}, {
  date: '2007-10-08T07:00:00.000Z',
  close: 167.91
}, {
  date: '2007-10-09T07:00:00.000Z',
  close: 167.86
}, {
  date: '2007-10-10T07:00:00.000Z',
  close: 166.79
}, {
  date: '2007-10-11T07:00:00.000Z',
  close: 162.23
}, {
  date: '2007-10-12T07:00:00.000Z',
  close: 167.25
}, {
  date: '2007-10-15T07:00:00.000Z',
  close: 166.98
}, {
  date: '2007-10-16T07:00:00.000Z',
  close: 169.58
}, {
  date: '2007-10-17T07:00:00.000Z',
  close: 172.75
}, {
  date: '2007-10-18T07:00:00.000Z',
  close: 173.5
}, {
  date: '2007-10-19T07:00:00.000Z',
  close: 170.42
}, {
  date: '2007-10-22T07:00:00.000Z',
  close: 174.36
}, {
  date: '2007-10-23T07:00:00.000Z',
  close: 186.16
}, {
  date: '2007-10-24T07:00:00.000Z',
  close: 185.93
}, {
  date: '2007-10-25T07:00:00.000Z',
  close: 182.78
}, {
  date: '2007-10-26T07:00:00.000Z',
  close: 184.7
}, {
  date: '2007-10-29T07:00:00.000Z',
  close: 185.09
}, {
  date: '2007-10-30T07:00:00.000Z',
  close: 187
}, {
  date: '2007-10-31T07:00:00.000Z',
  close: 189.95
}, {
  date: '2007-11-01T07:00:00.000Z',
  close: 187.44
}, {
  date: '2007-11-02T07:00:00.000Z',
  close: 187.87
}, {
  date: '2007-11-05T08:00:00.000Z',
  close: 186.18
}, {
  date: '2007-11-06T08:00:00.000Z',
  close: 191.79
}, {
  date: '2007-11-07T08:00:00.000Z',
  close: 186.3
}, {
  date: '2007-11-08T08:00:00.000Z',
  close: 175.47
}, {
  date: '2007-11-09T08:00:00.000Z',
  close: 165.37
}, {
  date: '2007-11-12T08:00:00.000Z',
  close: 153.76
}, {
  date: '2007-11-13T08:00:00.000Z',
  close: 169.96
}, {
  date: '2007-11-14T08:00:00.000Z',
  close: 166.11
}, {
  date: '2007-11-15T08:00:00.000Z',
  close: 164.3
}, {
  date: '2007-11-16T08:00:00.000Z',
  close: 166.39
}, {
  date: '2007-11-19T08:00:00.000Z',
  close: 163.95
}, {
  date: '2007-11-20T08:00:00.000Z',
  close: 168.85
}, {
  date: '2007-11-21T08:00:00.000Z',
  close: 168.46
}, {
  date: '2007-11-23T08:00:00.000Z',
  close: 171.54
}, {
  date: '2007-11-26T08:00:00.000Z',
  close: 172.54
}, {
  date: '2007-11-27T08:00:00.000Z',
  close: 174.81
}, {
  date: '2007-11-28T08:00:00.000Z',
  close: 180.22
}, {
  date: '2007-11-29T08:00:00.000Z',
  close: 184.29
}, {
  date: '2007-11-30T08:00:00.000Z',
  close: 182.22
}, {
  date: '2007-12-03T08:00:00.000Z',
  close: 178.86
}, {
  date: '2007-12-04T08:00:00.000Z',
  close: 179.81
}, {
  date: '2007-12-05T08:00:00.000Z',
  close: 185.5
}, {
  date: '2007-12-06T08:00:00.000Z',
  close: 189.95
}, {
  date: '2007-12-07T08:00:00.000Z',
  close: 194.3
}, {
  date: '2007-12-10T08:00:00.000Z',
  close: 194.21
}, {
  date: '2007-12-11T08:00:00.000Z',
  close: 188.54
}, {
  date: '2007-12-12T08:00:00.000Z',
  close: 190.86
}, {
  date: '2007-12-13T08:00:00.000Z',
  close: 191.83
}, {
  date: '2007-12-14T08:00:00.000Z',
  close: 190.39
}, {
  date: '2007-12-17T08:00:00.000Z',
  close: 184.4
}, {
  date: '2007-12-18T08:00:00.000Z',
  close: 182.98
}, {
  date: '2007-12-19T08:00:00.000Z',
  close: 183.12
}, {
  date: '2007-12-20T08:00:00.000Z',
  close: 187.21
}, {
  date: '2007-12-21T08:00:00.000Z',
  close: 193.91
}, {
  date: '2007-12-24T08:00:00.000Z',
  close: 198.8
}, {
  date: '2007-12-26T08:00:00.000Z',
  close: 198.95
}, {
  date: '2007-12-27T08:00:00.000Z',
  close: 198.57
}, {
  date: '2007-12-28T08:00:00.000Z',
  close: 199.83
}, {
  date: '2007-12-31T08:00:00.000Z',
  close: 198.08
}, {
  date: '2008-01-02T08:00:00.000Z',
  close: 194.84
}, {
  date: '2008-01-03T08:00:00.000Z',
  close: 194.93
}, {
  date: '2008-01-04T08:00:00.000Z',
  close: 180.05
}, {
  date: '2008-01-07T08:00:00.000Z',
  close: 177.64
}, {
  date: '2008-01-08T08:00:00.000Z',
  close: 171.25
}, {
  date: '2008-01-09T08:00:00.000Z',
  close: 179.4
}, {
  date: '2008-01-10T08:00:00.000Z',
  close: 178.02
}, {
  date: '2008-01-11T08:00:00.000Z',
  close: 172.69
}, {
  date: '2008-01-14T08:00:00.000Z',
  close: 178.78
}, {
  date: '2008-01-15T08:00:00.000Z',
  close: 169.04
}, {
  date: '2008-01-16T08:00:00.000Z',
  close: 159.64
}, {
  date: '2008-01-17T08:00:00.000Z',
  close: 160.89
}, {
  date: '2008-01-18T08:00:00.000Z',
  close: 161.36
}, {
  date: '2008-01-22T08:00:00.000Z',
  close: 155.64
}, {
  date: '2008-01-23T08:00:00.000Z',
  close: 139.07
}, {
  date: '2008-01-24T08:00:00.000Z',
  close: 135.6
}, {
  date: '2008-01-25T08:00:00.000Z',
  close: 130.01
}, {
  date: '2008-01-28T08:00:00.000Z',
  close: 130.01
}, {
  date: '2008-01-29T08:00:00.000Z',
  close: 131.54
}, {
  date: '2008-01-30T08:00:00.000Z',
  close: 132.18
}, {
  date: '2008-01-31T08:00:00.000Z',
  close: 135.36
}, {
  date: '2008-02-01T08:00:00.000Z',
  close: 133.75
}, {
  date: '2008-02-04T08:00:00.000Z',
  close: 131.65
}, {
  date: '2008-02-05T08:00:00.000Z',
  close: 129.36
}, {
  date: '2008-02-06T08:00:00.000Z',
  close: 122
}, {
  date: '2008-02-07T08:00:00.000Z',
  close: 121.24
}, {
  date: '2008-02-08T08:00:00.000Z',
  close: 125.48
}, {
  date: '2008-02-11T08:00:00.000Z',
  close: 129.45
}, {
  date: '2008-02-12T08:00:00.000Z',
  close: 124.86
}, {
  date: '2008-02-13T08:00:00.000Z',
  close: 129.4
}, {
  date: '2008-02-14T08:00:00.000Z',
  close: 127.46
}, {
  date: '2008-02-15T08:00:00.000Z',
  close: 124.63
}, {
  date: '2008-02-19T08:00:00.000Z',
  close: 122.18
}, {
  date: '2008-02-20T08:00:00.000Z',
  close: 123.82
}, {
  date: '2008-02-21T08:00:00.000Z',
  close: 121.54
}, {
  date: '2008-02-22T08:00:00.000Z',
  close: 119.46
}, {
  date: '2008-02-25T08:00:00.000Z',
  close: 119.74
}, {
  date: '2008-02-26T08:00:00.000Z',
  close: 119.15
}, {
  date: '2008-02-27T08:00:00.000Z',
  close: 122.96
}, {
  date: '2008-02-28T08:00:00.000Z',
  close: 129.91
}, {
  date: '2008-02-29T08:00:00.000Z',
  close: 125.02
}, {
  date: '2008-03-03T08:00:00.000Z',
  close: 121.73
}, {
  date: '2008-03-04T08:00:00.000Z',
  close: 124.62
}, {
  date: '2008-03-05T08:00:00.000Z',
  close: 124.49
}, {
  date: '2008-03-06T08:00:00.000Z',
  close: 120.93
}, {
  date: '2008-03-07T08:00:00.000Z',
  close: 122.25
}, {
  date: '2008-03-10T07:00:00.000Z',
  close: 119.69
}, {
  date: '2008-03-11T07:00:00.000Z',
  close: 127.35
}, {
  date: '2008-03-12T07:00:00.000Z',
  close: 126.03
}, {
  date: '2008-03-13T07:00:00.000Z',
  close: 127.94
}, {
  date: '2008-03-14T07:00:00.000Z',
  close: 126.61
}, {
  date: '2008-03-17T07:00:00.000Z',
  close: 126.73
}, {
  date: '2008-03-18T07:00:00.000Z',
  close: 132.82
}, {
  date: '2008-03-19T07:00:00.000Z',
  close: 129.67
}, {
  date: '2008-03-20T07:00:00.000Z',
  close: 133.27
}, {
  date: '2008-03-24T07:00:00.000Z',
  close: 139.53
}, {
  date: '2008-03-25T07:00:00.000Z',
  close: 140.98
}, {
  date: '2008-03-26T07:00:00.000Z',
  close: 145.06
}, {
  date: '2008-03-27T07:00:00.000Z',
  close: 140.25
}, {
  date: '2008-03-28T07:00:00.000Z',
  close: 143.01
}, {
  date: '2008-03-31T07:00:00.000Z',
  close: 143.5
}, {
  date: '2008-04-01T07:00:00.000Z',
  close: 149.53
}, {
  date: '2008-04-02T07:00:00.000Z',
  close: 147.49
}, {
  date: '2008-04-03T07:00:00.000Z',
  close: 151.61
}, {
  date: '2008-04-04T07:00:00.000Z',
  close: 153.08
}, {
  date: '2008-04-07T07:00:00.000Z',
  close: 155.89
}, {
  date: '2008-04-08T07:00:00.000Z',
  close: 152.84
}, {
  date: '2008-04-09T07:00:00.000Z',
  close: 151.44
}, {
  date: '2008-04-10T07:00:00.000Z',
  close: 154.55
}, {
  date: '2008-04-11T07:00:00.000Z',
  close: 147.14
}, {
  date: '2008-04-14T07:00:00.000Z',
  close: 147.78
}, {
  date: '2008-04-15T07:00:00.000Z',
  close: 148.38
}, {
  date: '2008-04-16T07:00:00.000Z',
  close: 153.7
}, {
  date: '2008-04-17T07:00:00.000Z',
  close: 154.49
}, {
  date: '2008-04-18T07:00:00.000Z',
  close: 161.04
}, {
  date: '2008-04-21T07:00:00.000Z',
  close: 168.16
}, {
  date: '2008-04-22T07:00:00.000Z',
  close: 160.2
}, {
  date: '2008-04-23T07:00:00.000Z',
  close: 162.89
}, {
  date: '2008-04-24T07:00:00.000Z',
  close: 168.94
}, {
  date: '2008-04-25T07:00:00.000Z',
  close: 169.73
}, {
  date: '2008-04-28T07:00:00.000Z',
  close: 172.24
}, {
  date: '2008-04-29T07:00:00.000Z',
  close: 175.05
}, {
  date: '2008-04-30T07:00:00.000Z',
  close: 173.95
}, {
  date: '2008-05-01T07:00:00.000Z',
  close: 180
}, {
  date: '2008-05-02T07:00:00.000Z',
  close: 180.94
}, {
  date: '2008-05-05T07:00:00.000Z',
  close: 184.73
}, {
  date: '2008-05-06T07:00:00.000Z',
  close: 186.66
}, {
  date: '2008-05-07T07:00:00.000Z',
  close: 182.59
}, {
  date: '2008-05-08T07:00:00.000Z',
  close: 185.06
}, {
  date: '2008-05-09T07:00:00.000Z',
  close: 183.45
}, {
  date: '2008-05-12T07:00:00.000Z',
  close: 188.16
}, {
  date: '2008-05-13T07:00:00.000Z',
  close: 189.96
}, {
  date: '2008-05-14T07:00:00.000Z',
  close: 186.26
}, {
  date: '2008-05-15T07:00:00.000Z',
  close: 189.73
}, {
  date: '2008-05-16T07:00:00.000Z',
  close: 187.62
}, {
  date: '2008-05-19T07:00:00.000Z',
  close: 183.6
}, {
  date: '2008-05-20T07:00:00.000Z',
  close: 185.9
}, {
  date: '2008-05-21T07:00:00.000Z',
  close: 178.19
}, {
  date: '2008-05-22T07:00:00.000Z',
  close: 177.05
}, {
  date: '2008-05-23T07:00:00.000Z',
  close: 181.17
}, {
  date: '2008-05-27T07:00:00.000Z',
  close: 186.43
}, {
  date: '2008-05-28T07:00:00.000Z',
  close: 187.01
}, {
  date: '2008-05-29T07:00:00.000Z',
  close: 186.69
}, {
  date: '2008-05-30T07:00:00.000Z',
  close: 188.75
}, {
  date: '2008-06-02T07:00:00.000Z',
  close: 186.1
}, {
  date: '2008-06-03T07:00:00.000Z',
  close: 185.37
}, {
  date: '2008-06-04T07:00:00.000Z',
  close: 185.19
}, {
  date: '2008-06-05T07:00:00.000Z',
  close: 189.43
}, {
  date: '2008-06-06T07:00:00.000Z',
  close: 185.64
}, {
  date: '2008-06-09T07:00:00.000Z',
  close: 181.61
}, {
  date: '2008-06-10T07:00:00.000Z',
  close: 185.64
}, {
  date: '2008-06-11T07:00:00.000Z',
  close: 180.81
}, {
  date: '2008-06-12T07:00:00.000Z',
  close: 173.26
}, {
  date: '2008-06-13T07:00:00.000Z',
  close: 172.37
}, {
  date: '2008-06-16T07:00:00.000Z',
  close: 176.84
}, {
  date: '2008-06-17T07:00:00.000Z',
  close: 181.43
}, {
  date: '2008-06-18T07:00:00.000Z',
  close: 178.75
}, {
  date: '2008-06-19T07:00:00.000Z',
  close: 180.9
}, {
  date: '2008-06-20T07:00:00.000Z',
  close: 175.27
}, {
  date: '2008-06-23T07:00:00.000Z',
  close: 173.16
}, {
  date: '2008-06-24T07:00:00.000Z',
  close: 173.25
}, {
  date: '2008-06-25T07:00:00.000Z',
  close: 177.39
}, {
  date: '2008-06-26T07:00:00.000Z',
  close: 168.26
}, {
  date: '2008-06-27T07:00:00.000Z',
  close: 170.09
}, {
  date: '2008-06-30T07:00:00.000Z',
  close: 167.44
}, {
  date: '2008-07-01T07:00:00.000Z',
  close: 174.68
}, {
  date: '2008-07-02T07:00:00.000Z',
  close: 168.18
}, {
  date: '2008-07-03T07:00:00.000Z',
  close: 170.12
}, {
  date: '2008-07-07T07:00:00.000Z',
  close: 175.16
}, {
  date: '2008-07-08T07:00:00.000Z',
  close: 179.55
}, {
  date: '2008-07-09T07:00:00.000Z',
  close: 174.25
}, {
  date: '2008-07-10T07:00:00.000Z',
  close: 176.63
}, {
  date: '2008-07-11T07:00:00.000Z',
  close: 172.58
}, {
  date: '2008-07-14T07:00:00.000Z',
  close: 173.88
}, {
  date: '2008-07-15T07:00:00.000Z',
  close: 169.64
}, {
  date: '2008-07-16T07:00:00.000Z',
  close: 172.81
}, {
  date: '2008-07-17T07:00:00.000Z',
  close: 171.81
}, {
  date: '2008-07-18T07:00:00.000Z',
  close: 165.15
}, {
  date: '2008-07-21T07:00:00.000Z',
  close: 166.29
}, {
  date: '2008-07-22T07:00:00.000Z',
  close: 162.02
}, {
  date: '2008-07-23T07:00:00.000Z',
  close: 166.26
}, {
  date: '2008-07-24T07:00:00.000Z',
  close: 159.03
}, {
  date: '2008-07-25T07:00:00.000Z',
  close: 162.12
}, {
  date: '2008-07-28T07:00:00.000Z',
  close: 154.4
}, {
  date: '2008-07-29T07:00:00.000Z',
  close: 157.08
}, {
  date: '2008-07-30T07:00:00.000Z',
  close: 159.88
}, {
  date: '2008-07-31T07:00:00.000Z',
  close: 158.95
}, {
  date: '2008-08-01T07:00:00.000Z',
  close: 156.66
}, {
  date: '2008-08-04T07:00:00.000Z',
  close: 153.23
}, {
  date: '2008-08-05T07:00:00.000Z',
  close: 160.64
}, {
  date: '2008-08-06T07:00:00.000Z',
  close: 164.19
}, {
  date: '2008-08-07T07:00:00.000Z',
  close: 163.57
}, {
  date: '2008-08-08T07:00:00.000Z',
  close: 169.55
}, {
  date: '2008-08-11T07:00:00.000Z',
  close: 173.56
}, {
  date: '2008-08-12T07:00:00.000Z',
  close: 176.73
}, {
  date: '2008-08-13T07:00:00.000Z',
  close: 179.3
}, {
  date: '2008-08-14T07:00:00.000Z',
  close: 179.32
}, {
  date: '2008-08-15T07:00:00.000Z',
  close: 175.74
}, {
  date: '2008-08-18T07:00:00.000Z',
  close: 175.39
}, {
  date: '2008-08-19T07:00:00.000Z',
  close: 173.53
}, {
  date: '2008-08-20T07:00:00.000Z',
  close: 175.84
}, {
  date: '2008-08-21T07:00:00.000Z',
  close: 174.29
}, {
  date: '2008-08-22T07:00:00.000Z',
  close: 176.79
}, {
  date: '2008-08-25T07:00:00.000Z',
  close: 172.55
}, {
  date: '2008-08-26T07:00:00.000Z',
  close: 173.64
}, {
  date: '2008-08-27T07:00:00.000Z',
  close: 174.67
}, {
  date: '2008-08-28T07:00:00.000Z',
  close: 173.74
}, {
  date: '2008-08-29T07:00:00.000Z',
  close: 169.53
}, {
  date: '2008-09-02T07:00:00.000Z',
  close: 166.19
}, {
  date: '2008-09-03T07:00:00.000Z',
  close: 166.96
}, {
  date: '2008-09-04T07:00:00.000Z',
  close: 161.22
}, {
  date: '2008-09-05T07:00:00.000Z',
  close: 160.18
}, {
  date: '2008-09-08T07:00:00.000Z',
  close: 157.92
}, {
  date: '2008-09-09T07:00:00.000Z',
  close: 151.68
}, {
  date: '2008-09-10T07:00:00.000Z',
  close: 151.61
}, {
  date: '2008-09-11T07:00:00.000Z',
  close: 152.65
}, {
  date: '2008-09-12T07:00:00.000Z',
  close: 148.94
}, {
  date: '2008-09-15T07:00:00.000Z',
  close: 140.36
}, {
  date: '2008-09-16T07:00:00.000Z',
  close: 139.88
}, {
  date: '2008-09-17T07:00:00.000Z',
  close: 127.83
}, {
  date: '2008-09-18T07:00:00.000Z',
  close: 134.09
}, {
  date: '2008-09-19T07:00:00.000Z',
  close: 140.91
}, {
  date: '2008-09-22T07:00:00.000Z',
  close: 131.05
}, {
  date: '2008-09-23T07:00:00.000Z',
  close: 126.84
}, {
  date: '2008-09-24T07:00:00.000Z',
  close: 128.71
}, {
  date: '2008-09-25T07:00:00.000Z',
  close: 131.93
}, {
  date: '2008-09-26T07:00:00.000Z',
  close: 128.24
}, {
  date: '2008-09-29T07:00:00.000Z',
  close: 105.26
}, {
  date: '2008-09-30T07:00:00.000Z',
  close: 113.66
}, {
  date: '2008-10-01T07:00:00.000Z',
  close: 109.12
}, {
  date: '2008-10-02T07:00:00.000Z',
  close: 100.1
}, {
  date: '2008-10-03T07:00:00.000Z',
  close: 97.07
}, {
  date: '2008-10-06T07:00:00.000Z',
  close: 98.14
}, {
  date: '2008-10-07T07:00:00.000Z',
  close: 89.16
}, {
  date: '2008-10-08T07:00:00.000Z',
  close: 89.79
}, {
  date: '2008-10-09T07:00:00.000Z',
  close: 88.74
}, {
  date: '2008-10-10T07:00:00.000Z',
  close: 96.8
}, {
  date: '2008-10-13T07:00:00.000Z',
  close: 110.26
}, {
  date: '2008-10-14T07:00:00.000Z',
  close: 104.08
}, {
  date: '2008-10-15T07:00:00.000Z',
  close: 97.95
}, {
  date: '2008-10-16T07:00:00.000Z',
  close: 101.89
}, {
  date: '2008-10-17T07:00:00.000Z',
  close: 97.4
}, {
  date: '2008-10-20T07:00:00.000Z',
  close: 98.44
}, {
  date: '2008-10-21T07:00:00.000Z',
  close: 91.49
}, {
  date: '2008-10-22T07:00:00.000Z',
  close: 96.87
}, {
  date: '2008-10-23T07:00:00.000Z',
  close: 98.23
}, {
  date: '2008-10-24T07:00:00.000Z',
  close: 96.38
}, {
  date: '2008-10-27T07:00:00.000Z',
  close: 92.09
}, {
  date: '2008-10-28T07:00:00.000Z',
  close: 99.91
}, {
  date: '2008-10-29T07:00:00.000Z',
  close: 104.55
}, {
  date: '2008-10-30T07:00:00.000Z',
  close: 111.04
}, {
  date: '2008-10-31T07:00:00.000Z',
  close: 107.59
}, {
  date: '2008-11-03T08:00:00.000Z',
  close: 106.96
}, {
  date: '2008-11-04T08:00:00.000Z',
  close: 110.99
}, {
  date: '2008-11-05T08:00:00.000Z',
  close: 103.3
}, {
  date: '2008-11-06T08:00:00.000Z',
  close: 99.1
}, {
  date: '2008-11-07T08:00:00.000Z',
  close: 98.24
}, {
  date: '2008-11-10T08:00:00.000Z',
  close: 95.88
}, {
  date: '2008-11-11T08:00:00.000Z',
  close: 94.77
}, {
  date: '2008-11-12T08:00:00.000Z',
  close: 90.12
}, {
  date: '2008-11-13T08:00:00.000Z',
  close: 96.44
}, {
  date: '2008-11-14T08:00:00.000Z',
  close: 90.24
}, {
  date: '2008-11-17T08:00:00.000Z',
  close: 88.14
}, {
  date: '2008-11-18T08:00:00.000Z',
  close: 89.91
}, {
  date: '2008-11-19T08:00:00.000Z',
  close: 86.29
}, {
  date: '2008-11-20T08:00:00.000Z',
  close: 80.49
}, {
  date: '2008-11-21T08:00:00.000Z',
  close: 82.58
}, {
  date: '2008-11-24T08:00:00.000Z',
  close: 92.95
}, {
  date: '2008-11-25T08:00:00.000Z',
  close: 90.8
}, {
  date: '2008-11-26T08:00:00.000Z',
  close: 95
}, {
  date: '2008-11-27T08:00:00.000Z',
  close: 95
}, {
  date: '2008-11-28T08:00:00.000Z',
  close: 92.67
}, {
  date: '2008-12-01T08:00:00.000Z',
  close: 88.93
}, {
  date: '2008-12-02T08:00:00.000Z',
  close: 92.47
}, {
  date: '2008-12-03T08:00:00.000Z',
  close: 95.9
}, {
  date: '2008-12-04T08:00:00.000Z',
  close: 91.41
}, {
  date: '2008-12-05T08:00:00.000Z',
  close: 94
}, {
  date: '2008-12-08T08:00:00.000Z',
  close: 99.72
}, {
  date: '2008-12-09T08:00:00.000Z',
  close: 100.06
}, {
  date: '2008-12-10T08:00:00.000Z',
  close: 98.21
}, {
  date: '2008-12-11T08:00:00.000Z',
  close: 95
}, {
  date: '2008-12-12T08:00:00.000Z',
  close: 98.27
}, {
  date: '2008-12-15T08:00:00.000Z',
  close: 94.75
}, {
  date: '2008-12-16T08:00:00.000Z',
  close: 95.43
}, {
  date: '2008-12-17T08:00:00.000Z',
  close: 89.16
}, {
  date: '2008-12-18T08:00:00.000Z',
  close: 89.43
}, {
  date: '2008-12-19T08:00:00.000Z',
  close: 90
}, {
  date: '2008-12-22T08:00:00.000Z',
  close: 85.74
}, {
  date: '2008-12-23T08:00:00.000Z',
  close: 86.38
}, {
  date: '2008-12-24T08:00:00.000Z',
  close: 85.04
}, {
  date: '2008-12-25T08:00:00.000Z',
  close: 85.04
}, {
  date: '2008-12-26T08:00:00.000Z',
  close: 85.81
}, {
  date: '2008-12-29T08:00:00.000Z',
  close: 86.61
}, {
  date: '2008-12-30T08:00:00.000Z',
  close: 86.29
}, {
  date: '2008-12-31T08:00:00.000Z',
  close: 85.35
}, {
  date: '2009-01-01T08:00:00.000Z',
  close: 85.35
}, {
  date: '2009-01-02T08:00:00.000Z',
  close: 90.75
}, {
  date: '2009-01-05T08:00:00.000Z',
  close: 94.58
}, {
  date: '2009-01-06T08:00:00.000Z',
  close: 93.02
}, {
  date: '2009-01-07T08:00:00.000Z',
  close: 91.01
}, {
  date: '2009-01-08T08:00:00.000Z',
  close: 92.7
}, {
  date: '2009-01-09T08:00:00.000Z',
  close: 90.58
}, {
  date: '2009-01-12T08:00:00.000Z',
  close: 88.66
}, {
  date: '2009-01-13T08:00:00.000Z',
  close: 87.71
}, {
  date: '2009-01-14T08:00:00.000Z',
  close: 85.33
}, {
  date: '2009-01-15T08:00:00.000Z',
  close: 83.38
}, {
  date: '2009-01-16T08:00:00.000Z',
  close: 82.33
}, {
  date: '2009-01-20T08:00:00.000Z',
  close: 78.2
}, {
  date: '2009-01-21T08:00:00.000Z',
  close: 82.83
}, {
  date: '2009-01-22T08:00:00.000Z',
  close: 88.36
}, {
  date: '2009-01-23T08:00:00.000Z',
  close: 88.36
}, {
  date: '2009-01-26T08:00:00.000Z',
  close: 89.64
}, {
  date: '2009-01-27T08:00:00.000Z',
  close: 90.73
}, {
  date: '2009-01-28T08:00:00.000Z',
  close: 94.2
}, {
  date: '2009-01-29T08:00:00.000Z',
  close: 93
}, {
  date: '2009-01-30T08:00:00.000Z',
  close: 90.13
}, {
  date: '2009-02-02T08:00:00.000Z',
  close: 91.51
}, {
  date: '2009-02-03T08:00:00.000Z',
  close: 92.98
}, {
  date: '2009-02-04T08:00:00.000Z',
  close: 93.55
}, {
  date: '2009-02-05T08:00:00.000Z',
  close: 96.46
}, {
  date: '2009-02-06T08:00:00.000Z',
  close: 99.72
}, {
  date: '2009-02-09T08:00:00.000Z',
  close: 102.51
}, {
  date: '2009-02-10T08:00:00.000Z',
  close: 97.83
}, {
  date: '2009-02-11T08:00:00.000Z',
  close: 96.82
}, {
  date: '2009-02-12T08:00:00.000Z',
  close: 99.27
}, {
  date: '2009-02-13T08:00:00.000Z',
  close: 99.16
}, {
  date: '2009-02-17T08:00:00.000Z',
  close: 94.53
}, {
  date: '2009-02-18T08:00:00.000Z',
  close: 94.37
}, {
  date: '2009-02-19T08:00:00.000Z',
  close: 90.64
}, {
  date: '2009-02-20T08:00:00.000Z',
  close: 91.2
}, {
  date: '2009-02-23T08:00:00.000Z',
  close: 86.95
}, {
  date: '2009-02-24T08:00:00.000Z',
  close: 90.25
}, {
  date: '2009-02-25T08:00:00.000Z',
  close: 91.16
}, {
  date: '2009-02-26T08:00:00.000Z',
  close: 89.19
}, {
  date: '2009-02-27T08:00:00.000Z',
  close: 89.31
}, {
  date: '2009-03-02T08:00:00.000Z',
  close: 87.94
}, {
  date: '2009-03-03T08:00:00.000Z',
  close: 88.37
}, {
  date: '2009-03-04T08:00:00.000Z',
  close: 91.17
}, {
  date: '2009-03-05T08:00:00.000Z',
  close: 88.84
}, {
  date: '2009-03-06T08:00:00.000Z',
  close: 85.3
}, {
  date: '2009-03-09T07:00:00.000Z',
  close: 83.11
}, {
  date: '2009-03-10T07:00:00.000Z',
  close: 88.63
}, {
  date: '2009-03-11T07:00:00.000Z',
  close: 92.68
}, {
  date: '2009-03-12T07:00:00.000Z',
  close: 96.35
}, {
  date: '2009-03-13T07:00:00.000Z',
  close: 95.93
}, {
  date: '2009-03-16T07:00:00.000Z',
  close: 95.42
}, {
  date: '2009-03-17T07:00:00.000Z',
  close: 99.66
}, {
  date: '2009-03-18T07:00:00.000Z',
  close: 101.52
}, {
  date: '2009-03-19T07:00:00.000Z',
  close: 101.62
}, {
  date: '2009-03-20T07:00:00.000Z',
  close: 101.59
}, {
  date: '2009-03-23T07:00:00.000Z',
  close: 107.66
}, {
  date: '2009-03-24T07:00:00.000Z',
  close: 106.5
}, {
  date: '2009-03-25T07:00:00.000Z',
  close: 106.49
}, {
  date: '2009-03-26T07:00:00.000Z',
  close: 109.87
}, {
  date: '2009-03-27T07:00:00.000Z',
  close: 106.85
}, {
  date: '2009-03-30T07:00:00.000Z',
  close: 104.49
}, {
  date: '2009-03-31T07:00:00.000Z',
  close: 105.12
}, {
  date: '2009-04-01T07:00:00.000Z',
  close: 108.69
}, {
  date: '2009-04-02T07:00:00.000Z',
  close: 112.71
}, {
  date: '2009-04-03T07:00:00.000Z',
  close: 115.99
}, {
  date: '2009-04-06T07:00:00.000Z',
  close: 118.45
}, {
  date: '2009-04-07T07:00:00.000Z',
  close: 115
}, {
  date: '2009-04-08T07:00:00.000Z',
  close: 116.32
}, {
  date: '2009-04-09T07:00:00.000Z',
  close: 119.57
}, {
  date: '2009-04-10T07:00:00.000Z',
  close: 119.57
}, {
  date: '2009-04-13T07:00:00.000Z',
  close: 120.22
}, {
  date: '2009-04-14T07:00:00.000Z',
  close: 118.31
}, {
  date: '2009-04-15T07:00:00.000Z',
  close: 117.64
}, {
  date: '2009-04-16T07:00:00.000Z',
  close: 121.45
}, {
  date: '2009-04-17T07:00:00.000Z',
  close: 123.42
}, {
  date: '2009-04-20T07:00:00.000Z',
  close: 120.5
}, {
  date: '2009-04-21T07:00:00.000Z',
  close: 121.76
}, {
  date: '2009-04-22T07:00:00.000Z',
  close: 121.51
}, {
  date: '2009-04-23T07:00:00.000Z',
  close: 125.4
}, {
  date: '2009-04-24T07:00:00.000Z',
  close: 123.9
}, {
  date: '2009-04-27T07:00:00.000Z',
  close: 124.73
}, {
  date: '2009-04-28T07:00:00.000Z',
  close: 123.9
}, {
  date: '2009-04-29T07:00:00.000Z',
  close: 125.14
}, {
  date: '2009-04-30T07:00:00.000Z',
  close: 125.83
}, {
  date: '2009-05-01T07:00:00.000Z',
  close: 127.24
}, {
  date: '2009-05-04T07:00:00.000Z',
  close: 132.07
}, {
  date: '2009-05-05T07:00:00.000Z',
  close: 132.71
}, {
  date: '2009-05-06T07:00:00.000Z',
  close: 132.5
}, {
  date: '2009-05-07T07:00:00.000Z',
  close: 129.06
}, {
  date: '2009-05-08T07:00:00.000Z',
  close: 129.19
}, {
  date: '2009-05-11T07:00:00.000Z',
  close: 129.57
}, {
  date: '2009-05-12T07:00:00.000Z',
  close: 124.42
}, {
  date: '2009-05-13T07:00:00.000Z',
  close: 119.49
}, {
  date: '2009-05-14T07:00:00.000Z',
  close: 122.95
}, {
  date: '2009-05-15T07:00:00.000Z',
  close: 122.42
}, {
  date: '2009-05-18T07:00:00.000Z',
  close: 126.65
}, {
  date: '2009-05-19T07:00:00.000Z',
  close: 127.45
}, {
  date: '2009-05-20T07:00:00.000Z',
  close: 125.87
}, {
  date: '2009-05-21T07:00:00.000Z',
  close: 124.18
}, {
  date: '2009-05-22T07:00:00.000Z',
  close: 122.5
}, {
  date: '2009-05-26T07:00:00.000Z',
  close: 130.78
}, {
  date: '2009-05-27T07:00:00.000Z',
  close: 133.05
}, {
  date: '2009-05-28T07:00:00.000Z',
  close: 135.07
}, {
  date: '2009-05-29T07:00:00.000Z',
  close: 135.81
}, {
  date: '2009-06-01T07:00:00.000Z',
  close: 139.35
}, {
  date: '2009-06-02T07:00:00.000Z',
  close: 139.49
}, {
  date: '2009-06-03T07:00:00.000Z',
  close: 140.95
}, {
  date: '2009-06-04T07:00:00.000Z',
  close: 143.74
}, {
  date: '2009-06-05T07:00:00.000Z',
  close: 144.67
}, {
  date: '2009-06-08T07:00:00.000Z',
  close: 143.85
}, {
  date: '2009-06-09T07:00:00.000Z',
  close: 142.72
}, {
  date: '2009-06-10T07:00:00.000Z',
  close: 140.25
}, {
  date: '2009-06-11T07:00:00.000Z',
  close: 139.95
}, {
  date: '2009-06-12T07:00:00.000Z',
  close: 136.97
}, {
  date: '2009-06-15T07:00:00.000Z',
  close: 136.09
}, {
  date: '2009-06-16T07:00:00.000Z',
  close: 136.35
}, {
  date: '2009-06-17T07:00:00.000Z',
  close: 135.58
}, {
  date: '2009-06-18T07:00:00.000Z',
  close: 135.88
}, {
  date: '2009-06-19T07:00:00.000Z',
  close: 139.48
}, {
  date: '2009-06-22T07:00:00.000Z',
  close: 137.37
}, {
  date: '2009-06-23T07:00:00.000Z',
  close: 134.01
}, {
  date: '2009-06-24T07:00:00.000Z',
  close: 136.22
}, {
  date: '2009-06-25T07:00:00.000Z',
  close: 139.86
}, {
  date: '2009-06-26T07:00:00.000Z',
  close: 142.44
}, {
  date: '2009-06-29T07:00:00.000Z',
  close: 141.97
}, {
  date: '2009-06-30T07:00:00.000Z',
  close: 142.43
}, {
  date: '2009-07-01T07:00:00.000Z',
  close: 142.83
}, {
  date: '2009-07-02T07:00:00.000Z',
  close: 140.02
}, {
  date: '2009-07-03T07:00:00.000Z',
  close: 140.02
}, {
  date: '2009-07-06T07:00:00.000Z',
  close: 138.61
}, {
  date: '2009-07-07T07:00:00.000Z',
  close: 135.4
}, {
  date: '2009-07-08T07:00:00.000Z',
  close: 137.22
}, {
  date: '2009-07-09T07:00:00.000Z',
  close: 136.36
}, {
  date: '2009-07-10T07:00:00.000Z',
  close: 138.52
}, {
  date: '2009-07-13T07:00:00.000Z',
  close: 142.34
}, {
  date: '2009-07-14T07:00:00.000Z',
  close: 142.27
}, {
  date: '2009-07-15T07:00:00.000Z',
  close: 146.88
}, {
  date: '2009-07-16T07:00:00.000Z',
  close: 147.52
}, {
  date: '2009-07-17T07:00:00.000Z',
  close: 151.75
}, {
  date: '2009-07-20T07:00:00.000Z',
  close: 152.91
}, {
  date: '2009-07-21T07:00:00.000Z',
  close: 151.51
}, {
  date: '2009-07-22T07:00:00.000Z',
  close: 156.74
}, {
  date: '2009-07-23T07:00:00.000Z',
  close: 157.82
}, {
  date: '2009-07-24T07:00:00.000Z',
  close: 159.99
}, {
  date: '2009-07-27T07:00:00.000Z',
  close: 160.1
}, {
  date: '2009-07-28T07:00:00.000Z',
  close: 160
}, {
  date: '2009-07-29T07:00:00.000Z',
  close: 160.03
}, {
  date: '2009-07-30T07:00:00.000Z',
  close: 162.79
}, {
  date: '2009-07-31T07:00:00.000Z',
  close: 163.39
}, {
  date: '2009-08-03T07:00:00.000Z',
  close: 166.43
}, {
  date: '2009-08-04T07:00:00.000Z',
  close: 165.55
}, {
  date: '2009-08-05T07:00:00.000Z',
  close: 165.11
}, {
  date: '2009-08-06T07:00:00.000Z',
  close: 163.91
}, {
  date: '2009-08-07T07:00:00.000Z',
  close: 165.51
}, {
  date: '2009-08-10T07:00:00.000Z',
  close: 164.72
}, {
  date: '2009-08-12T07:00:00.000Z',
  close: 165.31
}, {
  date: '2009-08-13T07:00:00.000Z',
  close: 168.42
}, {
  date: '2009-08-14T07:00:00.000Z',
  close: 166.78
}, {
  date: '2009-08-17T07:00:00.000Z',
  close: 159.59
}, {
  date: '2009-08-18T07:00:00.000Z',
  close: 164
}, {
  date: '2009-08-19T07:00:00.000Z',
  close: 164.6
}, {
  date: '2009-08-20T07:00:00.000Z',
  close: 166.33
}, {
  date: '2009-08-21T07:00:00.000Z',
  close: 169.22
}, {
  date: '2009-08-24T07:00:00.000Z',
  close: 169.06
}, {
  date: '2009-08-25T07:00:00.000Z',
  close: 169.4
}, {
  date: '2009-08-26T07:00:00.000Z',
  close: 167.41
}, {
  date: '2009-08-27T07:00:00.000Z',
  close: 169.45
}, {
  date: '2009-08-28T07:00:00.000Z',
  close: 170.05
}, {
  date: '2009-08-31T07:00:00.000Z',
  close: 168.21
}, {
  date: '2009-09-01T07:00:00.000Z',
  close: 165.3
}, {
  date: '2009-09-02T07:00:00.000Z',
  close: 165.18
}, {
  date: '2009-09-03T07:00:00.000Z',
  close: 166.55
}, {
  date: '2009-09-04T07:00:00.000Z',
  close: 170.31
}, {
  date: '2009-09-08T07:00:00.000Z',
  close: 172.93
}, {
  date: '2009-09-09T07:00:00.000Z',
  close: 171.14
}, {
  date: '2009-09-10T07:00:00.000Z',
  close: 172.56
}, {
  date: '2009-09-11T07:00:00.000Z',
  close: 172.16
}, {
  date: '2009-09-14T07:00:00.000Z',
  close: 173.72
}, {
  date: '2009-09-15T07:00:00.000Z',
  close: 175.16
}, {
  date: '2009-09-16T07:00:00.000Z',
  close: 181.87
}, {
  date: '2009-09-17T07:00:00.000Z',
  close: 184.55
}, {
  date: '2009-09-18T07:00:00.000Z',
  close: 185.02
}, {
  date: '2009-09-21T07:00:00.000Z',
  close: 184.02
}, {
  date: '2009-09-22T07:00:00.000Z',
  close: 184.48
}, {
  date: '2009-09-23T07:00:00.000Z',
  close: 185.5
}, {
  date: '2009-09-24T07:00:00.000Z',
  close: 183.82
}, {
  date: '2009-09-25T07:00:00.000Z',
  close: 182.37
}, {
  date: '2009-09-28T07:00:00.000Z',
  close: 186.15
}, {
  date: '2009-09-29T07:00:00.000Z',
  close: 185.38
}, {
  date: '2009-09-30T07:00:00.000Z',
  close: 185.35
}, {
  date: '2009-10-01T07:00:00.000Z',
  close: 180.86
}, {
  date: '2009-10-02T07:00:00.000Z',
  close: 184.9
}, {
  date: '2009-10-05T07:00:00.000Z',
  close: 186.02
}, {
  date: '2009-10-06T07:00:00.000Z',
  close: 190.01
}, {
  date: '2009-10-07T07:00:00.000Z',
  close: 190.25
}, {
  date: '2009-10-08T07:00:00.000Z',
  close: 189.27
}, {
  date: '2009-10-09T07:00:00.000Z',
  close: 190.47
}, {
  date: '2009-10-12T07:00:00.000Z',
  close: 190.81
}, {
  date: '2009-10-13T07:00:00.000Z',
  close: 190.02
}, {
  date: '2009-10-14T07:00:00.000Z',
  close: 191.29
}, {
  date: '2009-10-15T07:00:00.000Z',
  close: 190.56
}, {
  date: '2009-10-16T07:00:00.000Z',
  close: 188.05
}, {
  date: '2009-10-19T07:00:00.000Z',
  close: 189.86
}, {
  date: '2009-10-20T07:00:00.000Z',
  close: 198.76
}, {
  date: '2009-10-21T07:00:00.000Z',
  close: 204.92
}, {
  date: '2009-10-22T07:00:00.000Z',
  close: 205.2
}, {
  date: '2009-10-23T07:00:00.000Z',
  close: 203.94
}, {
  date: '2009-10-26T07:00:00.000Z',
  close: 202.48
}, {
  date: '2009-10-27T07:00:00.000Z',
  close: 197.37
}, {
  date: '2009-10-28T07:00:00.000Z',
  close: 192.4
}, {
  date: '2009-10-29T07:00:00.000Z',
  close: 196.35
}, {
  date: '2009-10-30T07:00:00.000Z',
  close: 188.5
}, {
  date: '2009-11-02T08:00:00.000Z',
  close: 189.31
}, {
  date: '2009-11-03T08:00:00.000Z',
  close: 188.75
}, {
  date: '2009-11-04T08:00:00.000Z',
  close: 190.81
}, {
  date: '2009-11-05T08:00:00.000Z',
  close: 194.03
}, {
  date: '2009-11-06T08:00:00.000Z',
  close: 194.34
}, {
  date: '2009-11-09T08:00:00.000Z',
  close: 201.46
}, {
  date: '2009-11-10T08:00:00.000Z',
  close: 202.98
}, {
  date: '2009-11-11T08:00:00.000Z',
  close: 203.25
}, {
  date: '2009-11-12T08:00:00.000Z',
  close: 201.99
}, {
  date: '2009-11-13T08:00:00.000Z',
  close: 204.45
}, {
  date: '2009-11-16T08:00:00.000Z',
  close: 206.63
}, {
  date: '2009-11-17T08:00:00.000Z',
  close: 207
}, {
  date: '2009-11-18T08:00:00.000Z',
  close: 205.96
}, {
  date: '2009-11-19T08:00:00.000Z',
  close: 200.51
}, {
  date: '2009-11-20T08:00:00.000Z',
  close: 199.92
}, {
  date: '2009-11-23T08:00:00.000Z',
  close: 205.88
}, {
  date: '2009-11-24T08:00:00.000Z',
  close: 204.44
}, {
  date: '2009-11-25T08:00:00.000Z',
  close: 204.19
}, {
  date: '2009-11-26T08:00:00.000Z',
  close: 204.19
}, {
  date: '2009-11-27T08:00:00.000Z',
  close: 200.59
}, {
  date: '2009-11-30T08:00:00.000Z',
  close: 199.91
}, {
  date: '2009-12-01T08:00:00.000Z',
  close: 196.97
}, {
  date: '2009-12-02T08:00:00.000Z',
  close: 196.23
}, {
  date: '2009-12-03T08:00:00.000Z',
  close: 196.48
}, {
  date: '2009-12-04T08:00:00.000Z',
  close: 193.32
}, {
  date: '2009-12-07T08:00:00.000Z',
  close: 188.95
}, {
  date: '2009-12-08T08:00:00.000Z',
  close: 189.87
}, {
  date: '2009-12-09T08:00:00.000Z',
  close: 197.8
}, {
  date: '2009-12-10T08:00:00.000Z',
  close: 196.43
}, {
  date: '2009-12-11T08:00:00.000Z',
  close: 194.67
}, {
  date: '2009-12-14T08:00:00.000Z',
  close: 196.98
}, {
  date: '2009-12-15T08:00:00.000Z',
  close: 194.17
}, {
  date: '2009-12-16T08:00:00.000Z',
  close: 195.03
}, {
  date: '2009-12-17T08:00:00.000Z',
  close: 191.86
}, {
  date: '2009-12-18T08:00:00.000Z',
  close: 195.43
}, {
  date: '2009-12-21T08:00:00.000Z',
  close: 198.23
}, {
  date: '2009-12-22T08:00:00.000Z',
  close: 200.36
}, {
  date: '2009-12-23T08:00:00.000Z',
  close: 202.1
}, {
  date: '2009-12-24T08:00:00.000Z',
  close: 209.04
}, {
  date: '2009-12-25T08:00:00.000Z',
  close: 209.04
}, {
  date: '2009-12-28T08:00:00.000Z',
  close: 211.61
}, {
  date: '2009-12-29T08:00:00.000Z',
  close: 209.1
}, {
  date: '2009-12-30T08:00:00.000Z',
  close: 211.64
}, {
  date: '2009-12-31T08:00:00.000Z',
  close: 210.73
}, {
  date: '2010-01-01T08:00:00.000Z',
  close: 210.73
}, {
  date: '2010-01-04T08:00:00.000Z',
  close: 214.01
}, {
  date: '2010-01-05T08:00:00.000Z',
  close: 214.38
}, {
  date: '2010-01-06T08:00:00.000Z',
  close: 210.97
}, {
  date: '2010-01-07T08:00:00.000Z',
  close: 210.58
}, {
  date: '2010-01-08T08:00:00.000Z',
  close: 211.98
}, {
  date: '2010-01-11T08:00:00.000Z',
  close: 210.11
}, {
  date: '2010-01-12T08:00:00.000Z',
  close: 207.72
}, {
  date: '2010-01-13T08:00:00.000Z',
  close: 210.65
}, {
  date: '2010-01-14T08:00:00.000Z',
  close: 209.43
}, {
  date: '2010-01-15T08:00:00.000Z',
  close: 205.93
}, {
  date: '2010-01-18T08:00:00.000Z',
  close: 205.93
}, {
  date: '2010-01-19T08:00:00.000Z',
  close: 215.04
}, {
  date: '2010-01-20T08:00:00.000Z',
  close: 211.72
}, {
  date: '2010-01-21T08:00:00.000Z',
  close: 208.07
}, {
  date: '2010-01-22T08:00:00.000Z',
  close: 197.75
}, {
  date: '2010-01-25T08:00:00.000Z',
  close: 203.08
}, {
  date: '2010-01-26T08:00:00.000Z',
  close: 205.94
}, {
  date: '2010-01-27T08:00:00.000Z',
  close: 207.88
}, {
  date: '2010-01-28T08:00:00.000Z',
  close: 199.29
}, {
  date: '2010-01-29T08:00:00.000Z',
  close: 192.06
}, {
  date: '2010-02-01T08:00:00.000Z',
  close: 194.73
}, {
  date: '2010-02-02T08:00:00.000Z',
  close: 195.86
}, {
  date: '2010-02-03T08:00:00.000Z',
  close: 199.23
}, {
  date: '2010-02-04T08:00:00.000Z',
  close: 192.05
}, {
  date: '2010-02-05T08:00:00.000Z',
  close: 195.46
}, {
  date: '2010-02-08T08:00:00.000Z',
  close: 194.12
}, {
  date: '2010-02-09T08:00:00.000Z',
  close: 196.19
}, {
  date: '2010-02-10T08:00:00.000Z',
  close: 195.12
}, {
  date: '2010-02-11T08:00:00.000Z',
  close: 198.67
}, {
  date: '2010-02-12T08:00:00.000Z',
  close: 200.38
}, {
  date: '2010-02-15T08:00:00.000Z',
  close: 200.38
}, {
  date: '2010-02-16T08:00:00.000Z',
  close: 203.4
}, {
  date: '2010-02-17T08:00:00.000Z',
  close: 202.55
}, {
  date: '2010-02-18T08:00:00.000Z',
  close: 202.93
}, {
  date: '2010-02-19T08:00:00.000Z',
  close: 201.67
}, {
  date: '2010-02-22T08:00:00.000Z',
  close: 200.42
}, {
  date: '2010-02-23T08:00:00.000Z',
  close: 197.06
}, {
  date: '2010-02-24T08:00:00.000Z',
  close: 200.66
}, {
  date: '2010-02-25T08:00:00.000Z',
  close: 202
}, {
  date: '2010-02-26T08:00:00.000Z',
  close: 204.62
}, {
  date: '2010-03-01T08:00:00.000Z',
  close: 208.99
}, {
  date: '2010-03-02T08:00:00.000Z',
  close: 208.85
}, {
  date: '2010-03-03T08:00:00.000Z',
  close: 209.33
}, {
  date: '2010-03-04T08:00:00.000Z',
  close: 210.71
}, {
  date: '2010-03-05T08:00:00.000Z',
  close: 218.95
}, {
  date: '2010-03-08T08:00:00.000Z',
  close: 219.08
}, {
  date: '2010-03-09T08:00:00.000Z',
  close: 223.02
}, {
  date: '2010-03-10T08:00:00.000Z',
  close: 224.84
}, {
  date: '2010-03-11T08:00:00.000Z',
  close: 225.5
}, {
  date: '2010-03-12T08:00:00.000Z',
  close: 226.6
}, {
  date: '2010-03-15T07:00:00.000Z',
  close: 223.84
}, {
  date: '2010-03-16T07:00:00.000Z',
  close: 224.45
}, {
  date: '2010-03-17T07:00:00.000Z',
  close: 224.12
}, {
  date: '2010-03-18T07:00:00.000Z',
  close: 224.65
}, {
  date: '2010-03-19T07:00:00.000Z',
  close: 222.25
}, {
  date: '2010-03-22T07:00:00.000Z',
  close: 224.75
}, {
  date: '2010-03-23T07:00:00.000Z',
  close: 228.36
}, {
  date: '2010-03-24T07:00:00.000Z',
  close: 229.37
}, {
  date: '2010-03-25T07:00:00.000Z',
  close: 226.65
}, {
  date: '2010-03-26T07:00:00.000Z',
  close: 230.9
}, {
  date: '2010-03-29T07:00:00.000Z',
  close: 232.39
}, {
  date: '2010-03-30T07:00:00.000Z',
  close: 235.84
}, {
  date: '2010-03-31T07:00:00.000Z',
  close: 235
}, {
  date: '2010-04-01T07:00:00.000Z',
  close: 235.97
}, {
  date: '2010-04-02T07:00:00.000Z',
  close: 235.97
}, {
  date: '2010-04-05T07:00:00.000Z',
  close: 238.49
}, {
  date: '2010-04-06T07:00:00.000Z',
  close: 239.54
}, {
  date: '2010-04-07T07:00:00.000Z',
  close: 240.6
}, {
  date: '2010-04-08T07:00:00.000Z',
  close: 239.95
}, {
  date: '2010-04-09T07:00:00.000Z',
  close: 241.79
}, {
  date: '2010-04-12T07:00:00.000Z',
  close: 242.29
}, {
  date: '2010-04-13T07:00:00.000Z',
  close: 242.43
}, {
  date: '2010-04-14T07:00:00.000Z',
  close: 245.69
}, {
  date: '2010-04-15T07:00:00.000Z',
  close: 248.92
}, {
  date: '2010-04-16T07:00:00.000Z',
  close: 247.4
}, {
  date: '2010-04-19T07:00:00.000Z',
  close: 247.07
}, {
  date: '2010-04-20T07:00:00.000Z',
  close: 244.59
}, {
  date: '2010-04-21T07:00:00.000Z',
  close: 259.22
}, {
  date: '2010-04-22T07:00:00.000Z',
  close: 266.47
}, {
  date: '2010-04-23T07:00:00.000Z',
  close: 270.83
}, {
  date: '2010-04-26T07:00:00.000Z',
  close: 269.5
}, {
  date: '2010-04-27T07:00:00.000Z',
  close: 262.04
}, {
  date: '2010-04-28T07:00:00.000Z',
  close: 261.6
}, {
  date: '2010-04-29T07:00:00.000Z',
  close: 268.64
}, {
  date: '2010-04-30T07:00:00.000Z',
  close: 261.09
}, {
  date: '2010-05-03T07:00:00.000Z',
  close: 266.35
}, {
  date: '2010-05-04T07:00:00.000Z',
  close: 258.68
}, {
  date: '2010-05-05T07:00:00.000Z',
  close: 255.98
}, {
  date: '2010-05-06T07:00:00.000Z',
  close: 246.25
}, {
  date: '2010-05-07T07:00:00.000Z',
  close: 235.86
}, {
  date: '2010-05-10T07:00:00.000Z',
  close: 253.99
}, {
  date: '2010-05-11T07:00:00.000Z',
  close: 256.52
}, {
  date: '2010-05-12T07:00:00.000Z',
  close: 262.09
}, {
  date: '2010-05-13T07:00:00.000Z',
  close: 258.36
}, {
  date: '2010-05-14T07:00:00.000Z',
  close: 253.82
}, {
  date: '2010-05-17T07:00:00.000Z',
  close: 254.22
}, {
  date: '2010-05-18T07:00:00.000Z',
  close: 252.36
}, {
  date: '2010-05-19T07:00:00.000Z',
  close: 248.34
}, {
  date: '2010-05-20T07:00:00.000Z',
  close: 237.76
}, {
  date: '2010-05-21T07:00:00.000Z',
  close: 242.32
}, {
  date: '2010-05-24T07:00:00.000Z',
  close: 246.76
}, {
  date: '2010-05-25T07:00:00.000Z',
  close: 245.22
}, {
  date: '2010-05-26T07:00:00.000Z',
  close: 244.11
}, {
  date: '2010-05-27T07:00:00.000Z',
  close: 253.35
}, {
  date: '2010-05-28T07:00:00.000Z',
  close: 256.88
}, {
  date: '2010-05-31T07:00:00.000Z',
  close: 256.88
}, {
  date: '2010-06-01T07:00:00.000Z',
  close: 260.83
}, {
  date: '2010-06-02T07:00:00.000Z',
  close: 263.95
}, {
  date: '2010-06-03T07:00:00.000Z',
  close: 263.12
}, {
  date: '2010-06-04T07:00:00.000Z',
  close: 255.96
}, {
  date: '2010-06-07T07:00:00.000Z',
  close: 250.94
}, {
  date: '2010-06-08T07:00:00.000Z',
  close: 249.33
}, {
  date: '2010-06-09T07:00:00.000Z',
  close: 243.2
}, {
  date: '2010-06-10T07:00:00.000Z',
  close: 250.51
}, {
  date: '2010-06-11T07:00:00.000Z',
  close: 253.51
}, {
  date: '2010-06-14T07:00:00.000Z',
  close: 254.28
}, {
  date: '2010-06-15T07:00:00.000Z',
  close: 259.69
}, {
  date: '2010-06-16T07:00:00.000Z',
  close: 267.25
}, {
  date: '2010-06-17T07:00:00.000Z',
  close: 271.87
}, {
  date: '2010-06-18T07:00:00.000Z',
  close: 274.07
}, {
  date: '2010-06-21T07:00:00.000Z',
  close: 270.17
}, {
  date: '2010-06-22T07:00:00.000Z',
  close: 273.85
}, {
  date: '2010-06-23T07:00:00.000Z',
  close: 270.97
}, {
  date: '2010-06-24T07:00:00.000Z',
  close: 269
}, {
  date: '2010-06-25T07:00:00.000Z',
  close: 266.7
}, {
  date: '2010-06-28T07:00:00.000Z',
  close: 268.3
}, {
  date: '2010-06-29T07:00:00.000Z',
  close: 256.17
}, {
  date: '2010-06-30T07:00:00.000Z',
  close: 251.53
}, {
  date: '2010-07-01T07:00:00.000Z',
  close: 248.48
}, {
  date: '2010-07-02T07:00:00.000Z',
  close: 246.94
}, {
  date: '2010-07-05T07:00:00.000Z',
  close: 246.94
}, {
  date: '2010-07-06T07:00:00.000Z',
  close: 248.63
}, {
  date: '2010-07-07T07:00:00.000Z',
  close: 258.66
}, {
  date: '2010-07-08T07:00:00.000Z',
  close: 258.09
}, {
  date: '2010-07-09T07:00:00.000Z',
  close: 259.62
}, {
  date: '2010-07-12T07:00:00.000Z',
  close: 257.28
}, {
  date: '2010-07-13T07:00:00.000Z',
  close: 251.8
}, {
  date: '2010-07-14T07:00:00.000Z',
  close: 252.73
}, {
  date: '2010-07-15T07:00:00.000Z',
  close: 251.45
}, {
  date: '2010-07-16T07:00:00.000Z',
  close: 249.9
}, {
  date: '2010-07-19T07:00:00.000Z',
  close: 245.58
}, {
  date: '2010-07-20T07:00:00.000Z',
  close: 251.89
}, {
  date: '2010-07-21T07:00:00.000Z',
  close: 254.24
}, {
  date: '2010-07-22T07:00:00.000Z',
  close: 259.02
}, {
  date: '2010-07-23T07:00:00.000Z',
  close: 259.94
}, {
  date: '2010-07-26T07:00:00.000Z',
  close: 259.28
}, {
  date: '2010-07-27T07:00:00.000Z',
  close: 264.08
}, {
  date: '2010-07-28T07:00:00.000Z',
  close: 260.96
}, {
  date: '2010-07-29T07:00:00.000Z',
  close: 258.11
}, {
  date: '2010-07-30T07:00:00.000Z',
  close: 257.25
}, {
  date: '2010-08-02T07:00:00.000Z',
  close: 261.85
}, {
  date: '2010-08-03T07:00:00.000Z',
  close: 261.93
}, {
  date: '2010-08-04T07:00:00.000Z',
  close: 262.98
}, {
  date: '2010-08-05T07:00:00.000Z',
  close: 261.7
}, {
  date: '2010-08-06T07:00:00.000Z',
  close: 260.09
}, {
  date: '2010-08-09T07:00:00.000Z',
  close: 261.75
}, {
  date: '2010-08-10T07:00:00.000Z',
  close: 259.41
}, {
  date: '2010-08-11T07:00:00.000Z',
  close: 250.19
}, {
  date: '2010-08-12T07:00:00.000Z',
  close: 251.79
}, {
  date: '2010-08-13T07:00:00.000Z',
  close: 249.1
}, {
  date: '2010-08-16T07:00:00.000Z',
  close: 247.64
}, {
  date: '2010-08-17T07:00:00.000Z',
  close: 251.97
}, {
  date: '2010-08-18T07:00:00.000Z',
  close: 253.07
}, {
  date: '2010-08-19T07:00:00.000Z',
  close: 249.88
}, {
  date: '2010-08-20T07:00:00.000Z',
  close: 249.64
}, {
  date: '2010-08-23T07:00:00.000Z',
  close: 245.8
}, {
  date: '2010-08-24T07:00:00.000Z',
  close: 239.93
}, {
  date: '2010-08-25T07:00:00.000Z',
  close: 242.89
}, {
  date: '2010-08-26T07:00:00.000Z',
  close: 240.28
}, {
  date: '2010-08-27T07:00:00.000Z',
  close: 241.62
}, {
  date: '2010-08-30T07:00:00.000Z',
  close: 242.5
}, {
  date: '2010-08-31T07:00:00.000Z',
  close: 243.1
}, {
  date: '2010-09-01T07:00:00.000Z',
  close: 250.33
}, {
  date: '2010-09-02T07:00:00.000Z',
  close: 252.17
}, {
  date: '2010-09-03T07:00:00.000Z',
  close: 258.77
}, {
  date: '2010-09-06T07:00:00.000Z',
  close: 258.77
}, {
  date: '2010-09-07T07:00:00.000Z',
  close: 257.81
}, {
  date: '2010-09-08T07:00:00.000Z',
  close: 262.92
}, {
  date: '2010-09-09T07:00:00.000Z',
  close: 263.07
}, {
  date: '2010-09-10T07:00:00.000Z',
  close: 263.41
}, {
  date: '2010-09-13T07:00:00.000Z',
  close: 267.04
}, {
  date: '2010-09-14T07:00:00.000Z',
  close: 268.06
}, {
  date: '2010-09-15T07:00:00.000Z',
  close: 270.22
}, {
  date: '2010-09-16T07:00:00.000Z',
  close: 276.57
}, {
  date: '2010-09-17T07:00:00.000Z',
  close: 275.37
}, {
  date: '2010-09-20T07:00:00.000Z',
  close: 283.23
}, {
  date: '2010-09-21T07:00:00.000Z',
  close: 283.77
}, {
  date: '2010-09-22T07:00:00.000Z',
  close: 287.75
}, {
  date: '2010-09-23T07:00:00.000Z',
  close: 288.92
}, {
  date: '2010-09-24T07:00:00.000Z',
  close: 292.32
}, {
  date: '2010-09-27T07:00:00.000Z',
  close: 291.16
}, {
  date: '2010-09-28T07:00:00.000Z',
  close: 286.86
}, {
  date: '2010-09-29T07:00:00.000Z',
  close: 287.37
}, {
  date: '2010-09-30T07:00:00.000Z',
  close: 283.75
}, {
  date: '2010-10-01T07:00:00.000Z',
  close: 282.52
}, {
  date: '2010-10-04T07:00:00.000Z',
  close: 278.64
}, {
  date: '2010-10-05T07:00:00.000Z',
  close: 288.94
}, {
  date: '2010-10-06T07:00:00.000Z',
  close: 289.19
}, {
  date: '2010-10-07T07:00:00.000Z',
  close: 289.22
}, {
  date: '2010-10-08T07:00:00.000Z',
  close: 294.07
}, {
  date: '2010-10-11T07:00:00.000Z',
  close: 295.36
}, {
  date: '2010-10-12T07:00:00.000Z',
  close: 298.54
}, {
  date: '2010-10-13T07:00:00.000Z',
  close: 300.14
}, {
  date: '2010-10-14T07:00:00.000Z',
  close: 302.31
}, {
  date: '2010-10-15T07:00:00.000Z',
  close: 314.74
}, {
  date: '2010-10-18T07:00:00.000Z',
  close: 318
}, {
  date: '2010-10-19T07:00:00.000Z',
  close: 309.49
}, {
  date: '2010-10-20T07:00:00.000Z',
  close: 310.53
}, {
  date: '2010-10-21T07:00:00.000Z',
  close: 309.52
}, {
  date: '2010-10-22T07:00:00.000Z',
  close: 307.47
}, {
  date: '2010-10-25T07:00:00.000Z',
  close: 308.84
}, {
  date: '2010-10-26T07:00:00.000Z',
  close: 308.05
}, {
  date: '2010-10-27T07:00:00.000Z',
  close: 307.83
}, {
  date: '2010-10-28T07:00:00.000Z',
  close: 305.24
}, {
  date: '2010-10-29T07:00:00.000Z',
  close: 300.98
}, {
  date: '2010-11-01T07:00:00.000Z',
  close: 304.18
}, {
  date: '2010-11-02T07:00:00.000Z',
  close: 309.36
}, {
  date: '2010-11-03T07:00:00.000Z',
  close: 312.8
}, {
  date: '2010-11-04T07:00:00.000Z',
  close: 318.27
}, {
  date: '2010-11-05T07:00:00.000Z',
  close: 317.13
}, {
  date: '2010-11-08T08:00:00.000Z',
  close: 318.62
}, {
  date: '2010-11-09T08:00:00.000Z',
  close: 316.08
}, {
  date: '2010-11-10T08:00:00.000Z',
  close: 318.03
}, {
  date: '2010-11-11T08:00:00.000Z',
  close: 316.66
}, {
  date: '2010-11-12T08:00:00.000Z',
  close: 308.03
}, {
  date: '2010-11-15T08:00:00.000Z',
  close: 307.04
}, {
  date: '2010-11-16T08:00:00.000Z',
  close: 301.59
}, {
  date: '2010-11-17T08:00:00.000Z',
  close: 300.5
}, {
  date: '2010-11-18T08:00:00.000Z',
  close: 308.43
}, {
  date: '2010-11-19T08:00:00.000Z',
  close: 306.73
}, {
  date: '2010-11-22T08:00:00.000Z',
  close: 313.36
}, {
  date: '2010-11-23T08:00:00.000Z',
  close: 308.73
}, {
  date: '2010-11-24T08:00:00.000Z',
  close: 314.8
}, {
  date: '2010-11-26T08:00:00.000Z',
  close: 315
}, {
  date: '2010-11-29T08:00:00.000Z',
  close: 316.87
}, {
  date: '2010-11-30T08:00:00.000Z',
  close: 311.15
}, {
  date: '2010-12-01T08:00:00.000Z',
  close: 316.4
}, {
  date: '2010-12-02T08:00:00.000Z',
  close: 318.15
}, {
  date: '2010-12-03T08:00:00.000Z',
  close: 317.44
}, {
  date: '2010-12-06T08:00:00.000Z',
  close: 320.15
}, {
  date: '2010-12-07T08:00:00.000Z',
  close: 318.21
}, {
  date: '2010-12-08T08:00:00.000Z',
  close: 321.01
}, {
  date: '2010-12-09T08:00:00.000Z',
  close: 319.76
}, {
  date: '2010-12-10T08:00:00.000Z',
  close: 320.56
}, {
  date: '2010-12-13T08:00:00.000Z',
  close: 321.67
}, {
  date: '2010-12-14T08:00:00.000Z',
  close: 320.29
}, {
  date: '2010-12-15T08:00:00.000Z',
  close: 320.36
}, {
  date: '2010-12-16T08:00:00.000Z',
  close: 321.25
}, {
  date: '2010-12-17T08:00:00.000Z',
  close: 320.61
}, {
  date: '2010-12-20T08:00:00.000Z',
  close: 322.21
}, {
  date: '2010-12-21T08:00:00.000Z',
  close: 324.2
}, {
  date: '2010-12-22T08:00:00.000Z',
  close: 325.16
}, {
  date: '2010-12-23T08:00:00.000Z',
  close: 323.6
}, {
  date: '2010-12-27T08:00:00.000Z',
  close: 324.68
}, {
  date: '2010-12-28T08:00:00.000Z',
  close: 325.47
}, {
  date: '2010-12-29T08:00:00.000Z',
  close: 325.29
}, {
  date: '2010-12-30T08:00:00.000Z',
  close: 323.66
}, {
  date: '2010-12-31T08:00:00.000Z',
  close: 322.56
}, {
  date: '2011-01-03T08:00:00.000Z',
  close: 329.57
}, {
  date: '2011-01-04T08:00:00.000Z',
  close: 331.29
}, {
  date: '2011-01-05T08:00:00.000Z',
  close: 334
}, {
  date: '2011-01-06T08:00:00.000Z',
  close: 333.73
}, {
  date: '2011-01-07T08:00:00.000Z',
  close: 336.12
}, {
  date: '2011-01-10T08:00:00.000Z',
  close: 342.46
}, {
  date: '2011-01-11T08:00:00.000Z',
  close: 341.64
}, {
  date: '2011-01-12T08:00:00.000Z',
  close: 344.42
}, {
  date: '2011-01-13T08:00:00.000Z',
  close: 345.68
}, {
  date: '2011-01-14T08:00:00.000Z',
  close: 348.48
}, {
  date: '2011-01-18T08:00:00.000Z',
  close: 340.65
}, {
  date: '2011-01-19T08:00:00.000Z',
  close: 338.84
}, {
  date: '2011-01-20T08:00:00.000Z',
  close: 332.68
}, {
  date: '2011-01-21T08:00:00.000Z',
  close: 326.72
}, {
  date: '2011-01-24T08:00:00.000Z',
  close: 337.45
}, {
  date: '2011-01-25T08:00:00.000Z',
  close: 341.4
}, {
  date: '2011-01-26T08:00:00.000Z',
  close: 343.85
}, {
  date: '2011-01-27T08:00:00.000Z',
  close: 343.21
}, {
  date: '2011-01-28T08:00:00.000Z',
  close: 336.1
}, {
  date: '2011-01-31T08:00:00.000Z',
  close: 339.32
}, {
  date: '2011-02-01T08:00:00.000Z',
  close: 345.03
}, {
  date: '2011-02-02T08:00:00.000Z',
  close: 344.32
}, {
  date: '2011-02-03T08:00:00.000Z',
  close: 343.44
}, {
  date: '2011-02-04T08:00:00.000Z',
  close: 346.5
}, {
  date: '2011-02-07T08:00:00.000Z',
  close: 351.88
}, {
  date: '2011-02-08T08:00:00.000Z',
  close: 355.2
}, {
  date: '2011-02-09T08:00:00.000Z',
  close: 358.16
}, {
  date: '2011-02-10T08:00:00.000Z',
  close: 354.54
}, {
  date: '2011-02-11T08:00:00.000Z',
  close: 356.85
}, {
  date: '2011-02-14T08:00:00.000Z',
  close: 359.18
}, {
  date: '2011-02-15T08:00:00.000Z',
  close: 359.9
}, {
  date: '2011-02-16T08:00:00.000Z',
  close: 363.13
}, {
  date: '2011-02-17T08:00:00.000Z',
  close: 358.3
}, {
  date: '2011-02-18T08:00:00.000Z',
  close: 350.56
}, {
  date: '2011-02-22T08:00:00.000Z',
  close: 338.61
}, {
  date: '2011-02-23T08:00:00.000Z',
  close: 342.62
}, {
  date: '2011-02-24T08:00:00.000Z',
  close: 342.88
}, {
  date: '2011-02-25T08:00:00.000Z',
  close: 348.16
}, {
  date: '2011-02-28T08:00:00.000Z',
  close: 353.21
}, {
  date: '2011-03-01T08:00:00.000Z',
  close: 349.31
}, {
  date: '2011-03-02T08:00:00.000Z',
  close: 352.12
}, {
  date: '2011-03-03T08:00:00.000Z',
  close: 359.56
}, {
  date: '2011-03-04T08:00:00.000Z',
  close: 360
}, {
  date: '2011-03-07T08:00:00.000Z',
  close: 355.36
}, {
  date: '2011-03-08T08:00:00.000Z',
  close: 355.76
}, {
  date: '2011-03-09T08:00:00.000Z',
  close: 352.47
}, {
  date: '2011-03-10T08:00:00.000Z',
  close: 346.67
}, {
  date: '2011-03-11T08:00:00.000Z',
  close: 351.99
}, {
  date: '2011-03-14T07:00:00.000Z',
  close: 353.56
}, {
  date: '2011-03-15T07:00:00.000Z',
  close: 345.43
}, {
  date: '2011-03-16T07:00:00.000Z',
  close: 330.01
}, {
  date: '2011-03-17T07:00:00.000Z',
  close: 334.64
}, {
  date: '2011-03-18T07:00:00.000Z',
  close: 330.67
}, {
  date: '2011-03-21T07:00:00.000Z',
  close: 339.3
}, {
  date: '2011-03-22T07:00:00.000Z',
  close: 341.2
}, {
  date: '2011-03-23T07:00:00.000Z',
  close: 339.19
}, {
  date: '2011-03-24T07:00:00.000Z',
  close: 344.97
}, {
  date: '2011-03-25T07:00:00.000Z',
  close: 351.54
}, {
  date: '2011-03-28T07:00:00.000Z',
  close: 350.44
}, {
  date: '2011-03-29T07:00:00.000Z',
  close: 350.96
}, {
  date: '2011-03-30T07:00:00.000Z',
  close: 348.63
}, {
  date: '2011-03-31T07:00:00.000Z',
  close: 348.51
}, {
  date: '2011-04-01T07:00:00.000Z',
  close: 344.56
}, {
  date: '2011-04-04T07:00:00.000Z',
  close: 341.19
}, {
  date: '2011-04-05T07:00:00.000Z',
  close: 338.89
}, {
  date: '2011-04-06T07:00:00.000Z',
  close: 338.04
}, {
  date: '2011-04-07T07:00:00.000Z',
  close: 338.08
}, {
  date: '2011-04-08T07:00:00.000Z',
  close: 335.06
}, {
  date: '2011-04-11T07:00:00.000Z',
  close: 330.8
}, {
  date: '2011-04-12T07:00:00.000Z',
  close: 332.4
}, {
  date: '2011-04-13T07:00:00.000Z',
  close: 336.13
}, {
  date: '2011-04-14T07:00:00.000Z',
  close: 332.42
}, {
  date: '2011-04-15T07:00:00.000Z',
  close: 327.46
}, {
  date: '2011-04-18T07:00:00.000Z',
  close: 331.85
}, {
  date: '2011-04-19T07:00:00.000Z',
  close: 337.86
}, {
  date: '2011-04-20T07:00:00.000Z',
  close: 342.41
}, {
  date: '2011-04-21T07:00:00.000Z',
  close: 350.7
}, {
  date: '2011-04-25T07:00:00.000Z',
  close: 353.01
}, {
  date: '2011-04-26T07:00:00.000Z',
  close: 350.42
}, {
  date: '2011-04-27T07:00:00.000Z',
  close: 350.15
}, {
  date: '2011-04-28T07:00:00.000Z',
  close: 346.75
}, {
  date: '2011-04-29T07:00:00.000Z',
  close: 350.13
}, {
  date: '2011-05-02T07:00:00.000Z',
  close: 346.28
}, {
  date: '2011-05-03T07:00:00.000Z',
  close: 348.2
}, {
  date: '2011-05-04T07:00:00.000Z',
  close: 349.57
}, {
  date: '2011-05-05T07:00:00.000Z',
  close: 346.75
}, {
  date: '2011-05-06T07:00:00.000Z',
  close: 346.66
}, {
  date: '2011-05-09T07:00:00.000Z',
  close: 347.6
}, {
  date: '2011-05-10T07:00:00.000Z',
  close: 349.45
}, {
  date: '2011-05-11T07:00:00.000Z',
  close: 347.23
}, {
  date: '2011-05-12T07:00:00.000Z',
  close: 346.57
}, {
  date: '2011-05-13T07:00:00.000Z',
  close: 340.5
}, {
  date: '2011-05-16T07:00:00.000Z',
  close: 333.3
}, {
  date: '2011-05-17T07:00:00.000Z',
  close: 336.14
}, {
  date: '2011-05-18T07:00:00.000Z',
  close: 339.87
}, {
  date: '2011-05-19T07:00:00.000Z',
  close: 340.53
}, {
  date: '2011-05-20T07:00:00.000Z',
  close: 335.22
}, {
  date: '2011-05-23T07:00:00.000Z',
  close: 334.4
}, {
  date: '2011-05-24T07:00:00.000Z',
  close: 332.19
}, {
  date: '2011-05-25T07:00:00.000Z',
  close: 336.78
}, {
  date: '2011-05-26T07:00:00.000Z',
  close: 335
}, {
  date: '2011-05-27T07:00:00.000Z',
  close: 337.41
}, {
  date: '2011-05-31T07:00:00.000Z',
  close: 347.83
}, {
  date: '2011-06-01T07:00:00.000Z',
  close: 345.51
}, {
  date: '2011-06-02T07:00:00.000Z',
  close: 346.1
}, {
  date: '2011-06-03T07:00:00.000Z',
  close: 343.44
}, {
  date: '2011-06-06T07:00:00.000Z',
  close: 338.04
}, {
  date: '2011-06-07T07:00:00.000Z',
  close: 332.04
}, {
  date: '2011-06-08T07:00:00.000Z',
  close: 332.24
}, {
  date: '2011-06-09T07:00:00.000Z',
  close: 331.49
}, {
  date: '2011-06-10T07:00:00.000Z',
  close: 325.9
}, {
  date: '2011-06-13T07:00:00.000Z',
  close: 326.6
}, {
  date: '2011-06-14T07:00:00.000Z',
  close: 332.44
}, {
  date: '2011-06-15T07:00:00.000Z',
  close: 326.75
}, {
  date: '2011-06-16T07:00:00.000Z',
  close: 325.16
}, {
  date: '2011-06-17T07:00:00.000Z',
  close: 320.26
}, {
  date: '2011-06-20T07:00:00.000Z',
  close: 315.32
}, {
  date: '2011-06-21T07:00:00.000Z',
  close: 325.3
}, {
  date: '2011-06-22T07:00:00.000Z',
  close: 322.61
}, {
  date: '2011-06-23T07:00:00.000Z',
  close: 331.23
}, {
  date: '2011-06-24T07:00:00.000Z',
  close: 326.35
}, {
  date: '2011-06-27T07:00:00.000Z',
  close: 332.04
}, {
  date: '2011-06-28T07:00:00.000Z',
  close: 335.26
}, {
  date: '2011-06-29T07:00:00.000Z',
  close: 334.04
}, {
  date: '2011-06-30T07:00:00.000Z',
  close: 335.67
}, {
  date: '2011-07-01T07:00:00.000Z',
  close: 343.26
}, {
  date: '2011-07-05T07:00:00.000Z',
  close: 349.43
}, {
  date: '2011-07-06T07:00:00.000Z',
  close: 351.76
}, {
  date: '2011-07-07T07:00:00.000Z',
  close: 357.2
}, {
  date: '2011-07-08T07:00:00.000Z',
  close: 359.71
}, {
  date: '2011-07-11T07:00:00.000Z',
  close: 354
}, {
  date: '2011-07-12T07:00:00.000Z',
  close: 353.75
}, {
  date: '2011-07-13T07:00:00.000Z',
  close: 358.02
}, {
  date: '2011-07-14T07:00:00.000Z',
  close: 357.77
}, {
  date: '2011-07-15T07:00:00.000Z',
  close: 364.92
}, {
  date: '2011-07-18T07:00:00.000Z',
  close: 373.8
}, {
  date: '2011-07-19T07:00:00.000Z',
  close: 376.85
}, {
  date: '2011-07-20T07:00:00.000Z',
  close: 386.9
}, {
  date: '2011-07-21T07:00:00.000Z',
  close: 387.29
}, {
  date: '2011-07-22T07:00:00.000Z',
  close: 393.3
}, {
  date: '2011-07-25T07:00:00.000Z',
  close: 398.5
}, {
  date: '2011-07-26T07:00:00.000Z',
  close: 403.41
}, {
  date: '2011-07-27T07:00:00.000Z',
  close: 392.59
}, {
  date: '2011-07-28T07:00:00.000Z',
  close: 391.82
}, {
  date: '2011-07-29T07:00:00.000Z',
  close: 390.48
}, {
  date: '2011-08-01T07:00:00.000Z',
  close: 396.75
}, {
  date: '2011-08-02T07:00:00.000Z',
  close: 388.91
}, {
  date: '2011-08-03T07:00:00.000Z',
  close: 392.57
}, {
  date: '2011-08-04T07:00:00.000Z',
  close: 377.37
}, {
  date: '2011-08-05T07:00:00.000Z',
  close: 373.62
}, {
  date: '2011-08-08T07:00:00.000Z',
  close: 353.21
}, {
  date: '2011-08-09T07:00:00.000Z',
  close: 374.01
}, {
  date: '2011-08-10T07:00:00.000Z',
  close: 363.69
}, {
  date: '2011-08-11T07:00:00.000Z',
  close: 373.7
}, {
  date: '2011-08-12T07:00:00.000Z',
  close: 376.99
}, {
  date: '2011-08-15T07:00:00.000Z',
  close: 383.41
}, {
  date: '2011-08-16T07:00:00.000Z',
  close: 380.48
}, {
  date: '2011-08-17T07:00:00.000Z',
  close: 380.44
}, {
  date: '2011-08-18T07:00:00.000Z',
  close: 366.05
}, {
  date: '2011-08-19T07:00:00.000Z',
  close: 356.03
}, {
  date: '2011-08-22T07:00:00.000Z',
  close: 356.44
}, {
  date: '2011-08-23T07:00:00.000Z',
  close: 373.6
}, {
  date: '2011-08-24T07:00:00.000Z',
  close: 376.18
}, {
  date: '2011-08-25T07:00:00.000Z',
  close: 373.72
}, {
  date: '2011-08-26T07:00:00.000Z',
  close: 383.58
}, {
  date: '2011-08-29T07:00:00.000Z',
  close: 389.97
}, {
  date: '2011-08-30T07:00:00.000Z',
  close: 389.99
}, {
  date: '2011-08-31T07:00:00.000Z',
  close: 384.83
}, {
  date: '2011-09-01T07:00:00.000Z',
  close: 381.03
}, {
  date: '2011-09-02T07:00:00.000Z',
  close: 374.05
}, {
  date: '2011-09-06T07:00:00.000Z',
  close: 379.74
}, {
  date: '2011-09-07T07:00:00.000Z',
  close: 383.93
}, {
  date: '2011-09-08T07:00:00.000Z',
  close: 384.14
}, {
  date: '2011-09-09T07:00:00.000Z',
  close: 377.48
}, {
  date: '2011-09-12T07:00:00.000Z',
  close: 379.94
}, {
  date: '2011-09-13T07:00:00.000Z',
  close: 384.62
}, {
  date: '2011-09-14T07:00:00.000Z',
  close: 389.3
}, {
  date: '2011-09-15T07:00:00.000Z',
  close: 392.96
}, {
  date: '2011-09-16T07:00:00.000Z',
  close: 400.5
}, {
  date: '2011-09-19T07:00:00.000Z',
  close: 411.63
}, {
  date: '2011-09-20T07:00:00.000Z',
  close: 413.45
}, {
  date: '2011-09-21T07:00:00.000Z',
  close: 412.14
}, {
  date: '2011-09-22T07:00:00.000Z',
  close: 401.82
}, {
  date: '2011-09-23T07:00:00.000Z',
  close: 404.3
}, {
  date: '2011-09-26T07:00:00.000Z',
  close: 403.17
}, {
  date: '2011-09-27T07:00:00.000Z',
  close: 399.26
}, {
  date: '2011-09-28T07:00:00.000Z',
  close: 397.01
}, {
  date: '2011-09-29T07:00:00.000Z',
  close: 390.57
}, {
  date: '2011-09-30T07:00:00.000Z',
  close: 381.32
}, {
  date: '2011-10-03T07:00:00.000Z',
  close: 374.6
}, {
  date: '2011-10-04T07:00:00.000Z',
  close: 372.5
}, {
  date: '2011-10-05T07:00:00.000Z',
  close: 378.25
}, {
  date: '2011-10-06T07:00:00.000Z',
  close: 377.37
}, {
  date: '2011-10-07T07:00:00.000Z',
  close: 369.8
}, {
  date: '2011-10-10T07:00:00.000Z',
  close: 388.81
}, {
  date: '2011-10-11T07:00:00.000Z',
  close: 400.29
}, {
  date: '2011-10-12T07:00:00.000Z',
  close: 402.19
}, {
  date: '2011-10-13T07:00:00.000Z',
  close: 408.43
}, {
  date: '2011-10-14T07:00:00.000Z',
  close: 422
}, {
  date: '2011-10-17T07:00:00.000Z',
  close: 419.99
}, {
  date: '2011-10-18T07:00:00.000Z',
  close: 422.24
}, {
  date: '2011-10-19T07:00:00.000Z',
  close: 398.62
}, {
  date: '2011-10-20T07:00:00.000Z',
  close: 395.31
}, {
  date: '2011-10-21T07:00:00.000Z',
  close: 392.87
}, {
  date: '2011-10-24T07:00:00.000Z',
  close: 405.77
}, {
  date: '2011-10-25T07:00:00.000Z',
  close: 397.77
}, {
  date: '2011-10-26T07:00:00.000Z',
  close: 400.6
}, {
  date: '2011-10-27T07:00:00.000Z',
  close: 404.69
}, {
  date: '2011-10-28T07:00:00.000Z',
  close: 404.95
}, {
  date: '2011-10-31T07:00:00.000Z',
  close: 404.78
}, {
  date: '2011-11-01T07:00:00.000Z',
  close: 396.51
}, {
  date: '2011-11-02T07:00:00.000Z',
  close: 397.41
}, {
  date: '2011-11-03T07:00:00.000Z',
  close: 403.07
}, {
  date: '2011-11-04T07:00:00.000Z',
  close: 400.24
}, {
  date: '2011-11-07T08:00:00.000Z',
  close: 399.73
}, {
  date: '2011-11-08T08:00:00.000Z',
  close: 406.23
}, {
  date: '2011-11-09T08:00:00.000Z',
  close: 395.28
}, {
  date: '2011-11-10T08:00:00.000Z',
  close: 385.22
}, {
  date: '2011-11-11T08:00:00.000Z',
  close: 384.62
}, {
  date: '2011-11-14T08:00:00.000Z',
  close: 379.26
}, {
  date: '2011-11-15T08:00:00.000Z',
  close: 388.83
}, {
  date: '2011-11-16T08:00:00.000Z',
  close: 384.77
}, {
  date: '2011-11-17T08:00:00.000Z',
  close: 377.41
}, {
  date: '2011-11-18T08:00:00.000Z',
  close: 374.94
}, {
  date: '2011-11-21T08:00:00.000Z',
  close: 369.01
}, {
  date: '2011-11-22T08:00:00.000Z',
  close: 376.51
}, {
  date: '2011-11-23T08:00:00.000Z',
  close: 366.99
}, {
  date: '2011-11-25T08:00:00.000Z',
  close: 363.57
}, {
  date: '2011-11-28T08:00:00.000Z',
  close: 376.12
}, {
  date: '2011-11-29T08:00:00.000Z',
  close: 373.2
}, {
  date: '2011-11-30T08:00:00.000Z',
  close: 382.2
}, {
  date: '2011-12-01T08:00:00.000Z',
  close: 387.93
}, {
  date: '2011-12-02T08:00:00.000Z',
  close: 389.7
}, {
  date: '2011-12-05T08:00:00.000Z',
  close: 393.01
}, {
  date: '2011-12-06T08:00:00.000Z',
  close: 390.95
}, {
  date: '2011-12-07T08:00:00.000Z',
  close: 389.09
}, {
  date: '2011-12-08T08:00:00.000Z',
  close: 390.66
}, {
  date: '2011-12-09T08:00:00.000Z',
  close: 393.62
}, {
  date: '2011-12-12T08:00:00.000Z',
  close: 391.84
}, {
  date: '2011-12-13T08:00:00.000Z',
  close: 388.81
}, {
  date: '2011-12-14T08:00:00.000Z',
  close: 380.19
}, {
  date: '2011-12-15T08:00:00.000Z',
  close: 378.94
}, {
  date: '2011-12-16T08:00:00.000Z',
  close: 381.02
}, {
  date: '2011-12-19T08:00:00.000Z',
  close: 382.21
}, {
  date: '2011-12-20T08:00:00.000Z',
  close: 395.95
}, {
  date: '2011-12-21T08:00:00.000Z',
  close: 396.44
}, {
  date: '2011-12-22T08:00:00.000Z',
  close: 398.55
}, {
  date: '2011-12-23T08:00:00.000Z',
  close: 403.43
}, {
  date: '2011-12-27T08:00:00.000Z',
  close: 406.53
}, {
  date: '2011-12-28T08:00:00.000Z',
  close: 402.64
}, {
  date: '2011-12-29T08:00:00.000Z',
  close: 405.12
}, {
  date: '2011-12-30T08:00:00.000Z',
  close: 405
}, {
  date: '2012-01-03T08:00:00.000Z',
  close: 411.23
}, {
  date: '2012-01-04T08:00:00.000Z',
  close: 413.44
}, {
  date: '2012-01-05T08:00:00.000Z',
  close: 418.03
}, {
  date: '2012-01-06T08:00:00.000Z',
  close: 422.4
}, {
  date: '2012-01-09T08:00:00.000Z',
  close: 421.73
}, {
  date: '2012-01-10T08:00:00.000Z',
  close: 423.24
}, {
  date: '2012-01-11T08:00:00.000Z',
  close: 422.55
}, {
  date: '2012-01-12T08:00:00.000Z',
  close: 421.39
}, {
  date: '2012-01-13T08:00:00.000Z',
  close: 419.81
}, {
  date: '2012-01-17T08:00:00.000Z',
  close: 424.7
}, {
  date: '2012-01-18T08:00:00.000Z',
  close: 429.11
}, {
  date: '2012-01-19T08:00:00.000Z',
  close: 427.75
}, {
  date: '2012-01-20T08:00:00.000Z',
  close: 420.3
}, {
  date: '2012-01-23T08:00:00.000Z',
  close: 427.41
}, {
  date: '2012-01-24T08:00:00.000Z',
  close: 420.41
}, {
  date: '2012-01-25T08:00:00.000Z',
  close: 446.66
}, {
  date: '2012-01-26T08:00:00.000Z',
  close: 444.63
}, {
  date: '2012-01-27T08:00:00.000Z',
  close: 447.28
}, {
  date: '2012-01-30T08:00:00.000Z',
  close: 453.01
}, {
  date: '2012-01-31T08:00:00.000Z',
  close: 456.48
}, {
  date: '2012-02-01T08:00:00.000Z',
  close: 456.19
}, {
  date: '2012-02-02T08:00:00.000Z',
  close: 455.12
}, {
  date: '2012-02-03T08:00:00.000Z',
  close: 459.68
}, {
  date: '2012-02-06T08:00:00.000Z',
  close: 463.97
}, {
  date: '2012-02-07T08:00:00.000Z',
  close: 468.83
}, {
  date: '2012-02-08T08:00:00.000Z',
  close: 476.68
}, {
  date: '2012-02-09T08:00:00.000Z',
  close: 493.17
}, {
  date: '2012-02-10T08:00:00.000Z',
  close: 493.42
}, {
  date: '2012-02-13T08:00:00.000Z',
  close: 502.6
}, {
  date: '2012-02-14T08:00:00.000Z',
  close: 509.46
}, {
  date: '2012-02-15T08:00:00.000Z',
  close: 497.67
}, {
  date: '2012-02-16T08:00:00.000Z',
  close: 502.21
}, {
  date: '2012-02-17T08:00:00.000Z',
  close: 502.12
}, {
  date: '2012-02-21T08:00:00.000Z',
  close: 514.85
}, {
  date: '2012-02-22T08:00:00.000Z',
  close: 513.04
}, {
  date: '2012-02-23T08:00:00.000Z',
  close: 516.39
}, {
  date: '2012-02-24T08:00:00.000Z',
  close: 522.41
}, {
  date: '2012-02-27T08:00:00.000Z',
  close: 525.76
}, {
  date: '2012-02-28T08:00:00.000Z',
  close: 535.41
}, {
  date: '2012-02-29T08:00:00.000Z',
  close: 542.44
}, {
  date: '2012-03-01T08:00:00.000Z',
  close: 544.47
}, {
  date: '2012-03-02T08:00:00.000Z',
  close: 545.18
}, {
  date: '2012-03-05T08:00:00.000Z',
  close: 533.16
}, {
  date: '2012-03-06T08:00:00.000Z',
  close: 530.26
}, {
  date: '2012-03-07T08:00:00.000Z',
  close: 530.69
}, {
  date: '2012-03-08T08:00:00.000Z',
  close: 541.99
}, {
  date: '2012-03-09T08:00:00.000Z',
  close: 545.17
}, {
  date: '2012-03-12T07:00:00.000Z',
  close: 552
}, {
  date: '2012-03-13T07:00:00.000Z',
  close: 568.1
}, {
  date: '2012-03-14T07:00:00.000Z',
  close: 589.58
}, {
  date: '2012-03-15T07:00:00.000Z',
  close: 585.56
}, {
  date: '2012-03-16T07:00:00.000Z',
  close: 585.57
}, {
  date: '2012-03-19T07:00:00.000Z',
  close: 601.1
}, {
  date: '2012-03-20T07:00:00.000Z',
  close: 605.96
}, {
  date: '2012-03-21T07:00:00.000Z',
  close: 602.5
}, {
  date: '2012-03-22T07:00:00.000Z',
  close: 599.34
}, {
  date: '2012-03-23T07:00:00.000Z',
  close: 596.05
}, {
  date: '2012-03-26T07:00:00.000Z',
  close: 606.98
}, {
  date: '2012-03-27T07:00:00.000Z',
  close: 614.48
}, {
  date: '2012-03-28T07:00:00.000Z',
  close: 617.62
}, {
  date: '2012-03-29T07:00:00.000Z',
  close: 609.86
}, {
  date: '2012-03-30T07:00:00.000Z',
  close: 599.55
}, {
  date: '2012-04-02T07:00:00.000Z',
  close: 618.63
}, {
  date: '2012-04-03T07:00:00.000Z',
  close: 629.32
}, {
  date: '2012-04-04T07:00:00.000Z',
  close: 624.31
}, {
  date: '2012-04-05T07:00:00.000Z',
  close: 633.68
}, {
  date: '2012-04-09T07:00:00.000Z',
  close: 636.23
}, {
  date: '2012-04-10T07:00:00.000Z',
  close: 628.44
}, {
  date: '2012-04-11T07:00:00.000Z',
  close: 626.2
}, {
  date: '2012-04-12T07:00:00.000Z',
  close: 622.77
}, {
  date: '2012-04-13T07:00:00.000Z',
  close: 605.23
}, {
  date: '2012-04-16T07:00:00.000Z',
  close: 580.13
}, {
  date: '2012-04-17T07:00:00.000Z',
  close: 609.7
}, {
  date: '2012-04-18T07:00:00.000Z',
  close: 608.34
}, {
  date: '2012-04-19T07:00:00.000Z',
  close: 587.44
}, {
  date: '2012-04-20T07:00:00.000Z',
  close: 572.98
}, {
  date: '2012-04-23T07:00:00.000Z',
  close: 571.7
}, {
  date: '2012-04-24T07:00:00.000Z',
  close: 560.28
}, {
  date: '2012-04-25T07:00:00.000Z',
  close: 610
}, {
  date: '2012-04-26T07:00:00.000Z',
  close: 607.7
}, {
  date: '2012-04-27T07:00:00.000Z',
  close: 603
}, {
  date: '2012-04-30T07:00:00.000Z',
  close: 583.98
}, {
  date: '2012-05-01T07:00:00.000Z',
  close: 582.13
}];
export default appleStock;