import styled from 'styled-components';

import { ANIMATION, COLORS, TYPOGRAPHY } from 'Permafrost/index';

// todo: move styling into common file

export const StyledButton = styled.button`
  border: 0;
  display: inline-block;
  cursor: pointer;
  font-family: ${TYPOGRAPHY.fontFamily.base};

  &.default {
    border-radius: 2px;
    padding: 0.625em 1.25em;
    box-shadow: 0 1px 1px 0 ${COLORS.black};
    transition: background-color ${ANIMATION.duration} ${ANIMATION.timing};
  }

  &.primary,
  &.cta {
    background-color: ${COLORS.curiousBlue};
    color: ${COLORS.white};

    &:hover,
    &:focus {
      // todo: shouldn't have to override...
      color: ${COLORS.white};
      // todo: store as constant
      background-color: #1777c2;
    }
  }

  &.cta {
    font-size: ${TYPOGRAPHY.fontSize.subheadLarge};
  }

  &.normal {
    font-size: ${TYPOGRAPHY.fontSize.base};
    background-color: ${COLORS.iron};
    color: ${COLORS.darkFontColor};

    &:hover,
    &:focus {
      // todo: store as constant
      background-color: #bebebe;
    }
  }

  &.no-style {
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    &:hover,
    &:focus {
      color: inherit;
      background-color: transparent;
    }

    &:not(.focus-visible) {
      outline: none;
    }
  }

  &.busy {
    cursor: wait;
  }
`;
