import { css } from 'styled-components';

export const fontStack = [
  'Noto Sans',
  '-apple-system',
  'BlinkMacSystemFont',
  'Avenir Next',
  'Avenir',
  'Segoe UI',
  'Lucida Grande',
  'Helvetica Neue',
  'Helvetica',
  'Fira Sans',
  'Roboto',
  'Noto',
  'Droid Sans',
  'Cantarell',
  'Oxygen',
  'Ubuntu',
  'Franklin Gothic Medium',
  'Century Gothic',
  'Liberation Sans',
  'sans-serif',
] as const;

export const fontFamily = {
  base: fontStack.join(','),
  heading: fontStack.join(','),
};

export const fontSize = {
  caption: '12px',
  base: '14px',
  subheadSmall: '16px',
  subheadLarge: '18px',
  display: '30px',
} as const;

// export const fontColor: { [key: string]: string } = {
// dark: fontColors.darkFontColor,
// default: fontColors.defaultFontColor,
// hilite: fontColors.hiliteFontColor,
// light: fontColors.lightFontColor,
// mid: fontColors.midFontColor,
// };

export const lineHeight = {
  base: 1.25,
  heading: 1.2,
} as const;

export const spacing = {
  base: `${lineHeight.base}em`,
  half: `${lineHeight.base / 2}em`,
  quarter: `${lineHeight.base / 4}em`,
  third: `${lineHeight.base / 3}em`,
};

export const ellipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const typography = { fontFamily, fontSize, lineHeight, spacing, ellipsis };
