export const camelCaseToUpperUnderscore = (string: string) => {
  return string
    .replace(/([A-Z])/g, function ($1) {
      return '_' + $1;
    })
    .toUpperCase();
};

export const camelCaseToSpaceUpper = (string: string) => {
  return (
    string
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export const underscoreToCapitalize = (string: string) => {
  return (
    string
      // replace all _ with spaces
      .replace(/_/g, ' ')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export const snakeCaseToCamelCase = (string: string) => {
  const parts = string.split('_').map((part, i) => {
    if (i > 0) {
      return part.replace(/^./, function (str) {
        return str.toUpperCase();
      });
    } else {
      return part;
    }
  });

  return parts.join().replace(/,/g, '');
};

export function capitalize(string: string): string {
  if (typeof string !== 'string') return '';

  return string.slice(0, 1).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Generates a random string of English alphabet characters. Defaults to a length of 8.
 */
export function createRandomString(length?: number): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';

  for (let i = 0; i < (length || 8); i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
}

export const maxLengthWithEllipse = (string: string, maxLength: number) => {
  if (string.length <= maxLength) {
    return string;
  } else {
    return string.slice(0, maxLength - 2).concat('...');
  }
};

export const maxLengthWithMiddleEllipsis = (string: string, maxLength: number): string => {
  const frontSegment = string.slice(0, Math.floor(maxLength / 2) - 2);
  const endSegmentLength = string.length - (Math.floor(maxLength / 2) - 1);
  const endSegment = string.slice(endSegmentLength, string.length);

  return string.length <= maxLength ? string : `${frontSegment}...${endSegment}`;
};
