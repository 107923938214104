// This file might be too clever...
// Alternative is to create duplicate files for different env configurations

// For example: src/containers/Root would become
/** Root.js
 * if (process.env.NODE_ENV === 'production') {
 *     module.exports = require('./Root.prod.js');
 * } else {
 *     module.exports = require('./Root.dev.js');
 * }
 **/

export const isDevEnv = () => {
  const env = process.env.NODE_ENV;
  return env !== 'production' && env !== 'test';
};

export const isTestEnv = () => {
  const env = process.env.NODE_ENV;
  return env === 'test';
};
