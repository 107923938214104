import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { v4 as uuid } from 'uuid';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledFieldInfo, StyledTextField } from './TextField.styles';

export type Props = PermafrostComponent & {
  autoComplete?: 'email' | 'current-password' | 'new-password' | string;
  autoFocus?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  displayErrors?: boolean;
  inputProps?: unknown;
  label: string;
  maxLength?: number;
  name?: string;
  onBlur?(): void;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  onFocus?(): void;
  placeholder?: string;
  promptProps?: unknown;
  required?: boolean;
  type?: 'text' | 'email' | 'password' | string;
  validationErrors?: string[];
  value?: string;
  readOnly?: boolean;
};

export function TextField(props: Props): React.ReactElement {
  // ensures unique value to associate label with input
  const fieldId = uuid();
  const {
    autoComplete,
    autoFocus,
    className,
    defaultValue,
    disabled,
    displayErrors,
    id,
    inputProps,
    label,
    maxLength,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    promptProps,
    required,
    type = 'text',
    validationErrors,
    value,
    readOnly,
  } = props;

  const errorClass: string = displayErrors ? 'error' : 'prompt';

  return (
    <StyledTextField className={className} data-cy={props['data-cy']} id={id}>
      <input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classNames({
          filled: (value && value.length > 0) || defaultValue,
        })}
        disabled={disabled}
        defaultValue={defaultValue}
        id={fieldId}
        maxLength={maxLength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        required={required}
        type={type}
        value={value}
        readOnly={readOnly}
        {...inputProps}
      />

      <label htmlFor={fieldId}>{label}</label>

      {(validationErrors || maxLength) && (
        <StyledFieldInfo>
          {validationErrors && (
            <div>
              {validationErrors.map((err, i) => {
                return (
                  <p className={errorClass} key={i} {...promptProps}>
                    {err}
                  </p>
                );
              })}
            </div>
          )}

          {maxLength && (
            <div className={classNames('count', { isMax: value?.length === maxLength })}>
              {value?.length || 0} / {maxLength}
            </div>
          )}
        </StyledFieldInfo>
      )}
    </StyledTextField>
  );
}
