import React, { useLayoutEffect, useMemo, useState } from 'react';

import { Axis, Bar, localPoint, scaleBand, scaleLinear, useTooltip } from '@visx/visx';

import { hookUtils } from '@indico-data/utils';

import { PermafrostComponent } from 'Permafrost/types';

import { MeasurementRows } from './MeasurementRows';

import { StyledHorizontalBarChart, StyledTooltip } from './HorizontalBarChart.styles';

export type Datum = {
  [key: string]: string | number;
};

type HorizontalBarChartProps = PermafrostComponent & {
  fontSize?: number;
  graphColor: string;
  graphData: Datum[];
  numTicks?: number;
  xAxisKey: string;
  yAxisKey: string;
};

const BAR_HEIGHT = 16;
const BAR_PADDING = 20;
const CHART_MARGIN_RIGHT = 40;

export function HorizontalBarChart(props: HorizontalBarChartProps) {
  const { className, fontSize, graphColor, graphData, id, numTicks, xAxisKey, yAxisKey } = props;

  const [labelsWidth, setLabelsWidth] = useState<number>(0);
  const [chartSvgRef, { width: chartWidth }] = hookUtils.useMeasure();

  // accessors
  const getYAxisValue = (d: Datum): string => d[yAxisKey] as string;
  const getXAxisValue = (d: Datum): number => d[xAxisKey] as number;

  // ensure we never have a negative value
  const xMax = useMemo(() => Math.abs(chartWidth - labelsWidth - CHART_MARGIN_RIGHT), [chartWidth]);
  const yMax = useMemo(() => (BAR_HEIGHT + BAR_PADDING) * graphData.length + BAR_PADDING, [
    graphData,
  ]);

  const maxCount = Math.max(...graphData.map(getXAxisValue));
  // limit # of lines to show so they don’t get out of control
  const verticalLinesToShow = numTicks || Math.floor(maxCount / 10);

  const {
    tooltipData,
    tooltipTop,
    tooltipLeft,
    tooltipOpen,
    hideTooltip,
    showTooltip,
  } = useTooltip();

  const xAxisScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        domain: [0, maxCount],
      }),
    [xMax]
  );

  const yAxisScale = useMemo(
    () =>
      scaleBand<string>({
        range: [yMax, 0],
        domain: graphData.map(getYAxisValue),
        padding: 0.1,
      }),
    [yMax]
  );

  // textAnchor must be explicitly set in the component, because typescript
  // thinks it’s a string if declared here...
  const tickLabelProps = {
    fill: 'currentColor',
    fontSize,
  };

  const commonAxisProps = {
    stroke: 'currentColor',
    strokeWidth: 1,
  };

  const horizontalAxis = () => {
    return (
      <svg
        width={xMax}
        height={16}
        style={{ paddingLeft: labelsWidth, color: graphColor }}
        overflow="visible"
      >
        <Axis
          {...commonAxisProps}
          top={-8}
          scale={xAxisScale}
          hideAxisLine={true}
          hideTicks={true}
          numTicks={verticalLinesToShow}
          tickLabelProps={() => ({
            ...tickLabelProps,
            textAnchor: 'middle',
          })}
        />
      </svg>
    );
  };

  const handleTooltip = (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>,
    count: number,
    yPos: number
  ) => {
    const { x } = localPoint(event) || { x: 0 };
    const tooltipTop = yPos;
    const tooltipLeft = x + labelsWidth;

    showTooltip({
      tooltipData: count || 0,
      tooltipLeft,
      tooltipTop,
    });
  };

  // measure the width of the Axis element so we can accommodate for it
  useLayoutEffect(() => {
    const leftAxisElement = document.querySelector('.left-axis-container');

    const width = leftAxisElement?.getBoundingClientRect().width || 0;

    if (width > labelsWidth) {
      setLabelsWidth(Math.max(width, 120));
    }
  }, [labelsWidth]);

  return (
    <div className={className} data-cy={props['data-cy']} id={id} ref={chartSvgRef}>
      {horizontalAxis()}

      <StyledHorizontalBarChart className="scrollable-vertical">
        <svg
          width={xMax}
          height={yMax}
          overflow="visible"
          style={{ paddingLeft: labelsWidth, paddingRight: 40 }}
        >
          <MeasurementRows
            axisProps={commonAxisProps}
            color={graphColor}
            data={graphData}
            tickProps={tickLabelProps}
            verticalLinesToShow={verticalLinesToShow}
            xScale={xAxisScale}
            yAxisKey={yAxisKey}
            yMax={yMax}
            yScale={yAxisScale}
          />

          <g transform={`translate(0, ${BAR_HEIGHT / 2})`}>
            {graphData.map((datum) => {
              const count = getXAxisValue(datum);
              const barWidth = xAxisScale(count) || 0;
              const x = 0;
              const y = yAxisScale(getYAxisValue(datum)) || 0;

              return (
                <Bar
                  key={`${getYAxisValue(datum)}-${count}`}
                  x={x}
                  y={y}
                  width={barWidth}
                  height={BAR_HEIGHT}
                  fill={graphColor}
                  onMouseMove={(e) => handleTooltip(e, count, y)}
                  onMouseOut={() => hideTooltip()}
                />
              );
            })}
          </g>
        </svg>

        {tooltipOpen && (
          <StyledTooltip top={tooltipTop} left={tooltipLeft} unstyled={true}>
            {tooltipData as any}
          </StyledTooltip>
        )}
      </StyledHorizontalBarChart>

      {horizontalAxis()}
    </div>
  );
}
