export const allColors = {
  akaroa: '#cbc98f',
  amber: '#f4c401',
  amethyst: '#8e57b3',
  aqua: '#10e4fe',
  avocado: '#81b12f',
  azure: '#108cf7',
  baliHai: '#8498b6',
  black: '#000',
  blueBayoux: '#4f5e74',
  blueRibbon: '#0b35ff',
  brick: '#c62828',
  brown: '#785349',
  candyCorn: '#fcef4d',
  carnation: '#ff8adf',
  carrot: '#f29a2d',
  cerulean: '#008b9e',
  chantilly: '#f7b0eb',
  charcoal: '#474b4b',
  chartreuse: '#34ff01',
  clay: '#283243',
  comet: '#5a6982',
  cornflower: '#2060ff',
  crimson: '#e53126',
  curiousBlue: '#178ee0',
  cyan: '#0cf',
  darkFontColor: '#0d151d',
  darkGray: '#333',
  darkPurple: '#6f3eae',
  darkRed: '#b30000',
  defaultFontColor: '#bbbfc5',
  dodgerBlue: '#1774ff',
  ebony: '#101a26',
  eggplant: '#7a0074',
  emerald: '#09c199',
  espresso: '#5a3627',
  fieldPromptError: '#fd9693',
  fieldPromptInfo: '#00bfff',
  flamingo: '#f861b6',
  geraldine: '#fe9486',
  grannysmith: '#92e959',
  green: '#4caf50',
  harlequin: '#20cb00',
  iron: '#d9dee0',
  juniper: '#6d9292',
  khaki: '#bba44b',
  lavender: '#fb9fda',
  lightGray: '#eee',
  lightPurple: '#b43be4',
  lilac: '#a195d4',
  magenta: '#ff15e0',
  mantis: '#4fa83d',
  mauve: '#e06cfb',
  mediumGray: '#999',
  midFontColor: '#8593b3',
  mint: '#3ac999',
  mirage: '#182432',
  mulberry: '#5c0536',
  orange: '#ff7200',
  orchid: '#ab00ff',
  oxfordBlue: '#384558',
  pacific: '#00b5b6',
  palm: '#1aa511',
  persian: '#0327ce',
  pine: '#017074',
  pink: '#f74f8a',
  pistachio: '#aac609',
  red: '#f95359',
  robinsEggBlue: '#00bcd4',
  salem: '#03832e',
  seaGreen: '#0b8a51',
  tangerine: '#ff9900',
  toast: '#997862',
  turquoise: '#00b5b6',
  viking: '#59cfe0',
  violet: '#3e4eb8',
  walnut: '#783f00',
  white: '#fff',
  yellow: '#ffca28',
  zucchini: '#016422',
  get hiliteFontColor() {
    return this.white;
  },
  get lightFontColor() {
    return this.lightGray;
  },
  get borderColor() {
    return this.lightGray;
  },
  get actionColor() {
    return this.midFontColor;
  },
  get backgroundColor() {
    return this.mirage;
  },
} as const;

export type ColorName = keyof typeof allColors;

/**
 * Given an array of valid color names, return an array containing their hex values as strings.
 */
function returnColorPalette(colorNames: ColorName[]): string[] {
  return colorNames.map((name) => allColors[name]);
}

// Base Colors
export const baseColors = returnColorPalette([
  'curiousBlue',
  'robinsEggBlue',
  'green',
  'yellow',
  'red',
  'white',
  'black',
]);

// Layout Colors
export const layoutColors = returnColorPalette([
  'ebony',
  'mirage',
  'clay',
  'oxfordBlue',
  'blueBayoux',
  'comet',
  'baliHai',
]);

// Grays
export const grayColors = returnColorPalette(['darkGray', 'mediumGray', 'lightGray', 'iron']);

// Font Colors
export const fontColors = returnColorPalette([
  'darkFontColor',
  'hiliteFontColor',
  'lightFontColor',
  'defaultFontColor',
  'midFontColor',
]);

// Button Palette
export const buttonColors = returnColorPalette([
  'turquoise',
  'violet',
  'emerald',
  'seaGreen',
  'avocado',
  'brick',
  'pink',
  'lightPurple',
  'charcoal',
]);

// New Button Palette
export const newButtonColors = returnColorPalette([
  'azure',
  'orchid',
  'tangerine',
  'palm',
  'magenta',
  'blueRibbon',
  'orange',
  'darkRed',
  'amber',
  'lilac',
  'pacific',
  'carnation',
  'brown',
  'khaki',
  'zucchini',
  'eggplant',
  //'crimson',
  'cerulean',
  'harlequin',
  'akaroa',
  'persian',
  'viking',
  'mauve',
  'espresso',
  'toast',
  'salem',
  'darkPurple',
  'cornflower',
  'grannysmith',
  'lavender',
]);

// Visualization Palette
export const vizColors = returnColorPalette([
  'aqua',
  'carrot',
  'flamingo',
  'dodgerBlue',
  'chantilly',
  'crimson',
  'candyCorn',
  'amethyst',
]);

export const vizColorsArray = returnColorPalette([
  'aqua',
  'carrot',
  'flamingo',
  'green',
  'dodgerBlue',
  'chantilly',
  'crimson',
  'candyCorn',
  'amethyst',
  'baliHai',
]);

// ODL Label Palette
export const fieldColors = returnColorPalette([
  'curiousBlue',
  'orchid',
  'orange',
  'mantis',
  'amber',
  'persian',
  'carnation',
  'crimson',
  'aqua',
  'khaki',
  'chartreuse',
  'magenta',
  'juniper',
  'walnut',
  'pistachio',
  'lilac',
  'mint',
  'pine',
  'geraldine',
  'mulberry',
]);
