import styled from 'styled-components';

import { ANIMATION, COLORS } from 'Permafrost/index';

import {
  defaultHeight,
  defaults,
  largeHeight,
  sizeLarge,
  variantPrimary,
} from 'Permafrost/components/ui-foundations/buttons/commonStyles';

const commonPadding = '3.5em';

export const StyledMenuButton = styled.div`
  display: inline-block;
  position: relative;

  z-index: 1;
`;

export const StyledButton = styled.button`
  ${defaults};
  ${variantPrimary};

  padding: 0 !important;
  margin-bottom: 0;

  &.large {
    --caret-size: ${largeHeight};

    ${sizeLarge};
  }

  &.normal {
    --caret-size: ${defaultHeight};
  }

  // adds addt'l specificity; without, the global button styles overrule this styling...
  &[aria-haspopup='true'] {
    background-color: ${COLORS.curiousBlue};
    color: ${COLORS.white};

    display: flex;
    align-items: center;

    .menuButtonTitle {
      padding-left: 1em;
      padding-right: 1.9em;
    }

    .caret {
      width: var(--caret-size);
      height: var(--caret-size);

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0);

      transition: background-color ${ANIMATION.duration} ${ANIMATION.timing};
    }

    svg[class='spin'] {
      margin-top: -2px;
      height: 1em;
      width: 1em;
    }

    // necessary to override default button styling
    &:hover,
    &:focus {
      color: ${COLORS.white};
      background-color: ${COLORS.curiousBlue};
    }

    &:hover,
    &[aria-expanded='true'] {
      .caret {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }

    &.processing {
      color: ${COLORS.iron};
      cursor: wait;
      padding-left: calc(${commonPadding} - 2px);

      .menuButtonTitle {
        padding-left: 0.75em;
        padding-right: ${commonPadding};
      }
    }
  }
`;

export const StyledMenu = styled.div`
  position: absolute;
  width: 100%;

  display: none;
  flex-direction: column;

  margin-top: 2px;

  button {
    border-radius: 0;
    box-shadow: none;

    margin-bottom: 1px;

    // dark-on-light text looks thinner than light-on-dark; removing
    // smoothing for that former case gives a more consistent appearance.
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: auto;

    &:focus {
      outline: none;
    }

    &:first-of-type {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }

    &:last-of-type {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  &.open {
    display: flex;
  }
`;
