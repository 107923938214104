import React from 'react';

import { v4 as uuid } from 'uuid';

import classNames from 'classnames';

import { Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledSelect, StyledValidationError } from './Select.styles';

type Props = PermafrostComponent & {
  defaultValue?: string;
  disabled?: boolean;
  horizontal?: boolean;
  initialText?: string;
  label?: string;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  options: { name: string; value: string | number }[];
  style?: object;
  value?: string | number;
  validationErrors?: string[];
};

export const Select = (props: Props) => {
  const {
    className = '',
    disabled,
    horizontal,
    id,
    initialText,
    label,
    onChange,
    options,
    style = {},
    value,
    validationErrors,
  } = props;

  const selectId = uuid();

  return (
    <StyledSelect
      className={classNames('Select', { horizontal }, className)}
      data-cy={props['data-cy']}
      id={id}
      style={style}
    >
      {label && <label htmlFor={selectId}>{label}</label>}

      <div className="select-container">
        <select onChange={onChange} value={value} disabled={disabled} id={selectId}>
          {initialText && (
            <option disabled value="">
              {initialText}
            </option>
          )}

          {options.map((o) => {
            const key = uuid();

            return (
              <option key={key} value={o.value}>
                {o.name}
              </option>
            );
          })}
        </select>

        <Icon name="fa-caret-down" className="selectCaret" />
      </div>
      {validationErrors ? (
        <StyledValidationError>
          {validationErrors.map((err, i) => {
            return (
              <p className={`error-${err}`} key={i}>
                {err}
              </p>
            );
          })}
        </StyledValidationError>
      ) : null}
    </StyledSelect>
  );
};
