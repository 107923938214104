/**
 * Takes a number and formats it nicely.
 *
 * @example numberWithCommas(12345)
 * // returns 12,345
 */
export function numberWithCommas(value: number): string {
  return new Intl.NumberFormat(navigator.language || 'en-US').format(value);
}

/**
 * Takes a number and returns it, rounded up to the maximum decimal places specified.
 *
 * @example maxDecimalPlaces(0.1694915254237288, 2)
 * // returns 0.17
 *
 * @example maxDecimalPlaces(0.1694915254237288, 3)
 * // returns 0.169
 *
 * @example maxDecimalPlaces(12.902, 2)
 * // returns 12.9
 */
export function maxDecimalPlaces(value: number, decimalPlaces: number): number {
  return +value.toFixed(decimalPlaces);
}
