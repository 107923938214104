import styled from 'styled-components';

import { ANIMATION, COLORS, SPACING } from 'Permafrost/index';

const boxSize = '17px';

export const StyledCheckbox = styled.label`
  display: flex;

  position: relative;

  &.disabled {
    cursor: not-allowed;
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover,
    &.checked .checkboxSquare {
      color: currentColor;
    }
  }

  &.checked .checkboxSquare {
    fill: currentColor;
  }

  transition: color ${ANIMATION.duration} ${ANIMATION.timing};

  .checkbox {
    width: ${boxSize};
    height: ${boxSize};

    margin-top: 2px;
    margin-right: ${SPACING.sm};
  }

  .checkboxSquare {
    stroke-width: 2.5;
  }

  .checkmark {
    color: ${COLORS.black};
    pointer-events: none;
  }

  .focusRing {
    stroke: ${COLORS.curiousBlue};
    stroke-width: 2;
  }
`;
