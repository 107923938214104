import React from 'react';

import { clipboardUtils, stringUtils } from '@indico-data/utils';

import { Button, Icon, Tooltip } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledCopyableFilename } from './CopyableFilename.styles';

type Props = PermafrostComponent & {
  filename: string;
  maxNameLength?: number;
};

export function CopyableFilename(props: Props) {
  const { className, filename, id, maxNameLength = 50 } = props;

  return (
    <StyledCopyableFilename className={className} data-cy={props['data-cy']}>
      {filename.length > maxNameLength! ? (
        <>
          <Tooltip questionMark={false} for="shortened-name">
            <p>{filename}</p>
          </Tooltip>
          <span data-tip data-for="shortened-name" id={id}>
            {stringUtils.maxLengthWithMiddleEllipsis(filename, maxNameLength!)}
          </span>
        </>
      ) : (
        <span id={id}>{filename}</span>
      )}

      <Button variant="link-style" onClick={() => clipboardUtils.copy(filename)}>
        <Icon name="fa-clipboard" ariaLabel="copy filename" size={['16px']} />
      </Button>
    </StyledCopyableFilename>
  );
}
