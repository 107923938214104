import create from 'zustand';
import { produce } from 'immer';

import { undoMiddleware, UndoState } from 'zundo';

export type Set<StoreState> = (cb: (store: StoreState) => void) => void;
export type Get<StoreState> = () => StoreState;
export type StoreUndoActions = UndoState;

// https://github.com/react-spring/zustand#middleware
// Log every time state is changed
// const log = (config: any) => (set: any, get: any, api: any) =>
//   config(
//     (args: any) => {
//       set(args);
//       // console.info('%cNew State:', 'font-weight: bold; color: #bada55;');
//       // console.info(get());
//     },
//     get,
//     api
//   );

// Turn the set method into an immer proxy
const immer = (config: any) => (set: any, get: any, api: any) =>
  config(
    (fn: any) => {
      // console.info('%cApplying:', 'font-weight: bold; color: cornflowerblue;');
      // console.info(fn);
      set(produce(fn));
    },
    get,
    api
  );

/**
 * Turns the set method into an immer proxy; see store example below.
 * When in a `development` env, logging will be enabled.
 */
export function createStore(store: unknown) {
  // if (process.env.NODE_ENV === 'development') {
  //   // prettier-ignore
  //   return create(
  //     log(
  //       immer(
  //         store
  //       )
  //     )
  //   );
  // } else {
  return create(immer(store));
  // }
}

/**
 * Works just like createStore(), but includes undo/redo functionality.
 *
 * @see {@link https://github.com/charkour/zundo}
 */
export function createStoreWithUndo(
  store: unknown,
  options?: {
    exclude?: string[];
    historyDepthLimit?: number;
    include?: string[];
  }
) {
  return create(undoMiddleware(immer(store), options));
}

// blatantly copied and adapted from andy's odl pr - 9/2020 jm
// const initialState = {
//   datasetId: null,
//   datasetName: null,
//   datasetType: null,
// };
//
// export const store = (
//  set: (cb: (state: Store) => void) => void,
//  get: () => Store) => ({
//   ...initialState,
//
//   updateDataset: (dataset: Dataset) =>
//     set((state: Store) => {
//       state.datasetId = dataset.id;
//       state.datasetName = dataset.name;
//       state.datasetType = dataset.type;
//     }),
//
//   resetState: () => set(() => initialState),
// });
//
// Best practices:
//
// Create selectors outside of the React component e.g.
//  const resetStateFunction = (state: Store) => state.resetState;
//
// Then bind it in the React component like any other hook:
//  const resetFilters = useStore(resetStateFunction);
