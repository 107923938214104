import React from 'react';

import classNames from 'classnames';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledCardHeader } from './CardHeader.styles';

type Props = PermafrostComponent & {
  style?: object;
  children?: React.ReactNode;
};

export const CardHeader = (props: Props) => {
  const { className, style, children, id, ...restOfProps } = props;

  return (
    <StyledCardHeader
      className={classNames('CardHeader', className)}
      data-cy={props['data-cy']}
      id={id}
      style={style}
      {...restOfProps}
    >
      {children}
    </StyledCardHeader>
  );
};

CardHeader.defaultProps = {
  className: '',
  style: {},
};
