import React, { useRef } from 'react';

import classNames from 'classnames';

import { AriaCheckboxProps } from '@react-types/checkbox';
import { useCheckbox } from '@react-aria/checkbox';
import { useFocusRing } from '@react-aria/focus';
import { useVisuallyHidden } from '@react-aria/visually-hidden';

import { COLORS, Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledCheckbox } from './Checkbox.styles';

type Props = PermafrostComponent & {
  color?: string;
  'data-tip'?: boolean;
  'data-for'?: string;
} & AriaCheckboxProps;

/**
 * A single checkbox and its label.
 */
export function Checkbox(props: Props) {
  const {
    children,
    className,
    color,
    id,
    isDisabled,
    isSelected = false,
    onChange = () => {},
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const checkboxState = {
    isSelected,
    setSelected: onChange,
    toggle: () => onChange(!isSelected),
  };

  const { inputProps } = useCheckbox(props, checkboxState, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing();
  const { visuallyHiddenProps } = useVisuallyHidden();

  const checkboxMeasure = 18;

  return (
    <StyledCheckbox
      data-cy={props['data-cy']}
      data-tip={props['data-tip']}
      data-for={props['data-for']}
      className={classNames({ checked: isSelected, disabled: isDisabled }, className)}
      id={id}
      style={color ? { color } : { color: COLORS.white }}
    >
      <input
        type="checkbox"
        {...inputProps}
        {...visuallyHiddenProps}
        {...focusProps}
        ref={inputRef}
      />
      <svg
        viewBox="0 0 20 20"
        aria-hidden="true"
        focusable="false"
        overflow="visible"
        className="checkbox"
      >
        <rect
          x={1}
          y={1}
          width={checkboxMeasure}
          height={checkboxMeasure}
          rx={2}
          fill="none"
          stroke="currentColor"
          className="checkboxSquare"
        />

        {isFocusVisible && (
          <rect
            x={-2.5}
            y={-2.5}
            width={checkboxMeasure + 7}
            height={checkboxMeasure + 7}
            rx={4}
            fill="none"
            className="focusRing"
          />
        )}

        <g transform="translate(1, 1)">
          {isSelected && <Icon name="check" className="checkmark" size={[checkboxMeasure]} />}
        </g>
      </svg>

      {children}
    </StyledCheckbox>
  );
}
