var planets = [{
  name: 'Jupiter',
  radius: '10.97',
  distance: '0'
}, {
  name: 'Saturn',
  radius: '9.14',
  distance: '0'
}, {
  name: 'Uranus',
  radius: '3.98',
  distance: '0'
}, {
  name: 'Neptune',
  radius: '3.86',
  distance: '0'
}, {
  name: 'Earth',
  radius: '1',
  distance: '0'
}, {
  name: 'Venus',
  radius: '0.950',
  distance: '0'
}, {
  name: 'Mars',
  radius: '0.532',
  distance: '0'
}, {
  name: 'Mercury',
  radius: '0.383',
  distance: '0'
}, {
  name: 'Pluto',
  radius: '0.181',
  distance: '0'
}];
export default planets;