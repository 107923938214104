import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledDatePicker } from './DatePicker.styles';

type Props = PermafrostComponent & {
  placeholder?: string;
  value?: Date;
  onChange: (value: Date) => void;
  ariaLabel?: string;
  dayPickerProps?: {
    disabledDays?: any; //This is typed as ANY because of the dayPickerProps.disabledDays evaluation in the onChangeHandler.  We won't know if disabledDays will have after, before or both, and TS throws an error indicating so
    selectedDays?: any;
  };
  label?: string;
  disabled?: boolean;
  disableFutureDates?: boolean;
};

export const DatePicker = (props: Props) => {
  const {
    className,
    id,
    placeholder,
    value,
    onChange,
    ariaLabel,
    dayPickerProps = {
      disabledDays: {},
    },
    label,
    disabled,
    disableFutureDates,
  } = props;

  const today = new Date();
  const onChangeHandler = (newDate: Date) => {
    const selectedDateHoursZero = newDate.setHours(0, 0, 0, 0);
    if (
      newDate &&
      (dayPickerProps?.disabledDays?.after < selectedDateHoursZero ||
        dayPickerProps?.disabledDays?.before > selectedDateHoursZero)
    ) {
      return;
    }
    onChange(newDate);
  };

  //if disableFutureDates===True - first need to check if a disabledDays.after value is set, if it is and it is before today's date we set the disabled after date to that value...if not, we set it to today
  if (disableFutureDates) {
    const { disabledDays } = dayPickerProps;
    dayPickerProps.disabledDays =
      dayPickerProps?.disabledDays?.after < today
        ? { ...disabledDays, after: disabledDays.after }
        : { ...disabledDays, after: today };
  }

  return (
    <StyledDatePicker
      className={className}
      aria-label={ariaLabel || 'date select'}
      aria-describedby="picker-label"
      data-cy={props['data-cy']}
      id={id}
    >
      {label ? <div id="picker-label">{label}</div> : null}
      <DayPickerInput
        inputProps={{ disabled: disabled }}
        placeholder={placeholder || 'yyyy-mm-dd'}
        value={value}
        dayPickerProps={{ ...dayPickerProps, selectedDays: dayPickerProps?.selectedDays || value }}
        onDayChange={(newDate) => {
          onChangeHandler(newDate);
        }}
      />
    </StyledDatePicker>
  );
};
