import React from 'react';

import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { utilTypes } from '@indico-data/utils';

import { PermafrostComponent } from 'Permafrost/types';
import { TYPOGRAPHY } from 'Permafrost/constants';
import { Button } from 'Permafrost/components/ui-foundations/buttons/Button/Button';

type BaseProps = {
  onClick(param?: any): void;
  text: string;
  to: LinkProps['to'];
};

type Props = PermafrostComponent & utilTypes.RequireAtLeastOne<BaseProps, 'onClick' | 'to'>;

/**
 * Found on item detail pages; used to navigate to the current module’s list page.
 * If an action needs to occur in addition to navigation, pass a handler into the
 * `onClick` prop.
 *
 * If navigation is not needed and nothing is provided to the `to` prop, this
 * component will be rendered as a button element.
 */
export function BackToLink(props: Props) {
  const { className, id, onClick, text, to } = props;

  const attributes = {
    // override the element’s innerText so AT ignores the left-angle quote character
    'aria-label': text,
    className,
    'data-cy': props['data-cy'],
    id,
  };

  if (to !== undefined) {
    return (
      <StyledBackToLink to={to} onClick={onClick} {...attributes}>
        &laquo; {text}
      </StyledBackToLink>
    );
  } else if (onClick !== undefined) {
    return (
      <StyledBackToLink as={Button} onClick={onClick} variant="link-style" {...attributes}>
        &laquo; {text}
      </StyledBackToLink>
    );
  }

  return null;
}

const StyledBackToLink = styled(Link)`
  display: block !important;

  line-height: 20px !important;
  font-size: ${TYPOGRAPHY.fontSize.subheadSmall} !important;
`;
