import React from 'react';

import { Card, CardBody, Shrug } from '@indico-data/permafrost';

import { StyledLoginFailed } from './LoginFailed.styles';

export default function LoginFailed() {
  return (
    <StyledLoginFailed>
      <Card>
        <CardBody>
          <Shrug
            message="Looks like our system failed to verify you."
            buttonAction={() => window.location.reload()}
            buttonText="Refresh"
          />
        </CardBody>
      </Card>
    </StyledLoginFailed>
  );
}
