import React, { useState } from 'react';

import { TabbedInterface, SectionTab } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

type Section = SectionTab & { content: React.ReactNode };

type Props = PermafrostComponent & {
  defaultSection?: string;
  fullWidthTabs?: boolean;
  sections: Section[];
};

/**
 * An a11y-friendly tabbed interface; allows the user to tab between different sections
 * of a container.
 *
 * If the URL will change as the user moves from section to section, the `TabNavigation` component
 * should be used instead.
 */
export function TabbedContainer(props: Props) {
  const { className, defaultSection, fullWidthTabs, id, sections } = props;
  const [current, setCurrent] = useState<string>(defaultSection || sections[0].id);

  const tabs = sections.map((section) => ({ id: section.id, name: section.name }));
  const sectionContent: { [sectionId: string]: React.ReactNode } = sections.reduce(
    (acc, section) => {
      return {
        ...acc,
        [section.id]: section.content,
      };
    },
    {}
  );

  const updateContent = (id: string) => {
    setCurrent(id);
  };

  return (
    <TabbedInterface
      tabType="section"
      tabs={tabs}
      className={className}
      currentTab={current}
      data-cy={props['data-cy']}
      id={id}
      fullWidthTabs={fullWidthTabs}
      onTabSelect={updateContent}
    >
      {sectionContent[current]}
    </TabbedInterface>
  );
}
