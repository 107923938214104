import React from 'react';

import classNames from 'classnames';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledCardSection } from './CardSection.styles';

type Props = PermafrostComponent & {
  title?: string;
  style?: object;
  children: React.ReactNode;
};

export const CardSection = (props: Props) => {
  const { title, className, children, id, style = {}, ...restOfProps } = props;

  return (
    <StyledCardSection
      className={classNames('CardSection', className)}
      data-cy={props['data-cy']}
      id={id}
      style={style}
      {...restOfProps}
    >
      {title ? (
        <header>
          <h3>{title}</h3>
        </header>
      ) : null}
      {children}
      <hr className="section-bottom-border" />
    </StyledCardSection>
  );
};
