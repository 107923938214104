import React, { useState, useEffect, useRef } from 'react';
import { addDays, subDays } from 'date-fns';
import classNames from 'classnames';

import { DatePicker } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledDateRangePicker } from './DateRangePicker.styles';

type FromDisabledDaysProps = { after: Date };
type ToDisabledDaysProps = { before: Date };

type PickerProps = {
  disabledDays?: FromDisabledDaysProps | ToDisabledDaysProps;
  fromMonth?: Date;
  modifiers?: { from?: Date; to?: Date };
  month: Date;
  selectedDays?: [Date | undefined, {}] | Date;
  toMonth?: Date;
};

type Props = PermafrostComponent & {
  // From Date props
  fromAriaLabel?: string;
  fromLabel?: string;
  fromPickerProps?: PickerProps;
  fromPlaceholder?: string;
  fromValue?: Date;
  fromOnChange: (value: Date) => void;

  // To Date props
  toAriaLabel?: string;
  toLabel?: string;
  toPickerProps?: PickerProps;
  toPlaceholder?: string;
  toValue?: Date;
  toOnChange: (value: Date) => void;

  // General props
  disabled?: boolean;
  disableFutureDates?: boolean;
  label?: string;
  maxRange?: number;
  numberOfMonths?: number;
  vertical?: boolean;
};

export const DateRangePicker = (props: Props) => {
  const {
    id,
    className,
    disabled,
    disableFutureDates,
    fromAriaLabel,
    fromLabel,
    fromOnChange,
    fromPickerProps,
    fromPlaceholder,
    fromValue,
    label,
    maxRange,
    numberOfMonths,
    toAriaLabel,
    toLabel,
    toOnChange,
    toPickerProps,
    toPlaceholder,
    toValue,
    vertical,
  } = props;

  const [fromDatePickerProps, setFromDatePickerProps] = useState({
    disabledDays: fromPickerProps?.disabledDays || undefined,
    month: fromPickerProps?.month || undefined,
    toMonth: fromPickerProps?.toMonth || undefined,
    selectedDays: fromPickerProps?.selectedDays || [],
    modifiers: fromPickerProps?.modifiers || {},
    numberOfMonths: numberOfMonths || 1,
    onDayClick: () => {},
  });

  const [toDatePickerProps, setToDatePickerProps] = useState({
    disabledDays: toPickerProps?.disabledDays || undefined,
    month: fromPickerProps?.month || undefined,
    fromMonth: toPickerProps?.fromMonth || undefined,
    selectedDays: toPickerProps?.selectedDays || [],
    modifiers: toPickerProps?.modifiers || {},
    numberOfMonths: numberOfMonths || 1,
  });

  const inputsContainer = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // sets focus to To-Date Input after selecting From-Date
    const toInputRef: any = inputsContainer?.current?.querySelector('.to-picker input');
    setFromDatePickerProps({ ...fromDatePickerProps, onDayClick: () => toInputRef.focus() });
  }, []);

  const handleFromChange = (from: Date) => {
    fromOnChange(from);
    setToDatePickerProps({
      ...toDatePickerProps,
      disabledDays: { before: from, after: maxRange ? addDays(from, maxRange - 1) : undefined },
      fromMonth: from,
      month: toDatePickerProps.month ? toDatePickerProps.month : from,
      modifiers: { ...toDatePickerProps.modifiers, from },
      selectedDays: [from, { from, to: toValue }],
    });
    setFromDatePickerProps({
      ...fromDatePickerProps,
      month: from,
      modifiers: { ...fromDatePickerProps.modifiers, from },
      selectedDays: [from, { from, to: toValue }],
    });
  };

  const handleToChange = (to: Date) => {
    toOnChange(to);
    setFromDatePickerProps({
      ...fromDatePickerProps,
      disabledDays: { after: to, before: maxRange ? subDays(to, maxRange - 1) : undefined },
      toMonth: to,
      month: fromDatePickerProps.month ? fromDatePickerProps.month : to,
      modifiers: { ...fromDatePickerProps.modifiers, to },
      selectedDays: [to, { from: fromValue, to }],
    });
    setToDatePickerProps({
      ...toDatePickerProps,
      month: to,
      modifiers: { ...toDatePickerProps.modifiers, to },
      selectedDays: [to, { from: fromValue, to }],
    });
  };

  return (
    <StyledDateRangePicker className={className} data-cy={props['data-cy']} id={id}>
      {label ? <label htmlFor="date-range-picker-inputs">{label}</label> : null}
      <div
        ref={inputsContainer}
        className={classNames('date-range-picker-inputs', { vertical: vertical })}
      >
        <DatePicker
          ariaLabel={fromAriaLabel || 'from date select'}
          className="from-picker"
          dayPickerProps={fromDatePickerProps}
          disabled={disabled}
          disableFutureDates={disableFutureDates}
          label={fromLabel}
          onChange={(from: Date) => handleFromChange(from)}
          placeholder={fromPlaceholder}
          value={fromValue}
        />

        {!vertical ? <hr className="spacer" /> : null}

        <DatePicker
          ariaLabel={toAriaLabel || 'to date select'}
          className="to-picker"
          dayPickerProps={toDatePickerProps}
          disabled={disabled}
          disableFutureDates={disableFutureDates}
          label={toLabel}
          onChange={(to: Date) => handleToChange(to)}
          placeholder={toPlaceholder}
          value={toValue}
        />
      </div>
    </StyledDateRangePicker>
  );
};
