import styled, { keyframes } from 'styled-components';

import { colorUtils } from '@indico-data/utils';

import { MEDIA_QUERIES, COLORS } from 'Permafrost/index';

const cardTableLoading = keyframes`
  25% {
    left: -50px;
  }
  50% {
    left: calc(100% + 50px);
  }
  75% {
    left: calc(100% + 50px);
  }
  100% {
    left: calc(100% + 50px);
  }
`;

export const StyledCardTable = styled.div`
  .mobile-sort {
    @media ${MEDIA_QUERIES.mediumScreen} {
      display: none;
    }

    select {
      max-width: none;
    }
  }

  .outer-hover {
    @media ${MEDIA_QUERIES.mediumScreen} {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.loading {
    margin-top: 50px;

    table {
      overflow: hidden;
    }
  }

  .CardTable--tbody-loading {
    tr {
      margin: 10px 0;
      border-bottom: 10px solid transparent;
    }

    td {
      position: relative;
      border-bottom: none;
      height: 73px;
      background: ${COLORS.mirage};

      &:before {
        position: absolute;
        display: block;
        top: 0;
        left: -50px;
        content: '';
        height: 100%;
        width: 50px;
        background: linear-gradient(90deg, ${COLORS.mirage}, #232e3d, ${COLORS.mirage});
        animation: ${cardTableLoading} 2s infinite;
        animation-timing-function: linear;
      }
    }
  }

  tbody > tr {
    @media ${MEDIA_QUERIES.mediumScreen} {
      &.hover:hover {
        background: ${COLORS.oxfordBlue};
        cursor: pointer;
      }
    }
  }

  .action-column {
    display: none;
    text-align: center;
    width: 20px;
    padding: 20px 5px;

    @media ${MEDIA_QUERIES.mediumScreen} {
      display: table-cell;
    }
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;

    @media ${MEDIA_QUERIES.mediumScreen} {
      display: table;
    }
  }

  thead {
    display: block;

    @media ${MEDIA_QUERIES.mediumScreen} {
      display: table-header-group;
    }
  }

  th {
    padding-bottom: 10px;
    border-bottom: 1px solid #535d6d;
  }

  th:hover {
    cursor: pointer;
  }

  th.not-sortable:hover {
    cursor: default;
  }

  tbody {
    display: block;

    @media ${MEDIA_QUERIES.mediumScreen} {
      display: table-row-group;
    }
  }

  th,
  td {
    display: block;

    @media ${MEDIA_QUERIES.mediumScreen} {
      display: table-cell;
    }
  }

  tr {
    display: block;
    padding-bottom: 10px;

    @media ${MEDIA_QUERIES.mediumScreen} {
      display: table-row;

      border-bottom: 0.5px solid ${colorUtils.hexToRgb(COLORS.defaultFontColor, 0.1)};
    }
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;

    @media ${MEDIA_QUERIES.mediumScreen} {
      position: static;
    }
  }

  tr:nth-child(odd) {
    background: ${COLORS.oxfordBlue};

    @media ${MEDIA_QUERIES.mediumScreen} {
      background: transparent;
    }
  }

  td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 50%;
    padding-top: 10px;
    padding-bottom: 5px;

    @media ${MEDIA_QUERIES.mediumScreen} {
      position: static;
      // border-bottom: 0.5px solid ${colorUtils.hexToRgb(COLORS.defaultFontColor, 0.1)};
      padding: 20px 15px;
    }
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-heading);

    @media ${MEDIA_QUERIES.mediumScreen} {
      display: none;
    }
  }

  td.inner-table-cell {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 5px;
    border: none;

    &:before {
      display: none;
    }
  }

  tr.row-option {
    display: none;

    &.open {
      display: table-row;
    }
  }

  .outer-hover tr {
    border: none;
  }

  .outer-hover .hover {
    border-bottom: 0.5px solid ${colorUtils.hexToRgb(COLORS.defaultFontColor, 0.1)};
  }
`;
