import styled from 'styled-components';

const commonSpace = '10px';

export const StyledCopyableFilename = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: ${commonSpace};
  }

  svg {
    margin-bottom: -1px;
  }
`;
