var browserUsage = [{
  date: '2015 Jun 15',
  'Google Chrome': '48.09',
  'Internet Explorer': '24.14',
  Firefox: '18.82',
  Safari: '7.46',
  'Microsoft Edge': '0.03',
  Opera: '1.32',
  Mozilla: '0.12',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 16',
  'Google Chrome': '48',
  'Internet Explorer': '24.19',
  Firefox: '18.96',
  Safari: '7.36',
  'Microsoft Edge': '0.03',
  Opera: '1.32',
  Mozilla: '0.12',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 17',
  'Google Chrome': '47.87',
  'Internet Explorer': '24.44',
  Firefox: '18.91',
  Safari: '7.27',
  'Microsoft Edge': '0.03',
  Opera: '1.36',
  Mozilla: '0.12',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 18',
  'Google Chrome': '48.22',
  'Internet Explorer': '23.83',
  Firefox: '19.16',
  Safari: '7.24',
  'Microsoft Edge': '0.04',
  Opera: '1.39',
  Mozilla: '0.12',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 19',
  'Google Chrome': '47.91',
  'Internet Explorer': '23.86',
  Firefox: '19.35',
  Safari: '7.31',
  'Microsoft Edge': '0.04',
  Opera: '1.41',
  Mozilla: '0.12',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 20',
  'Google Chrome': '48.78',
  'Internet Explorer': '21.14',
  Firefox: '19.66',
  Safari: '8.42',
  'Microsoft Edge': '0.05',
  Opera: '1.83',
  Mozilla: '0.1',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 21',
  'Google Chrome': '49.43',
  'Internet Explorer': '20.55',
  Firefox: '19.42',
  Safari: '8.66',
  'Microsoft Edge': '0.05',
  Opera: '1.75',
  Mozilla: '0.12',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 22',
  'Google Chrome': '48.98',
  'Internet Explorer': '23.47',
  Firefox: '18.84',
  Safari: '7.25',
  'Microsoft Edge': '0.04',
  Opera: '1.28',
  Mozilla: '0.12',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 23',
  'Google Chrome': '48.69',
  'Internet Explorer': '23.76',
  Firefox: '18.89',
  Safari: '7.22',
  'Microsoft Edge': '0.04',
  Opera: '1.27',
  Mozilla: '0.11',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 24',
  'Google Chrome': '49.17',
  'Internet Explorer': '23.35',
  Firefox: '18.91',
  Safari: '7.09',
  'Microsoft Edge': '0.04',
  Opera: '1.32',
  Mozilla: '0.12',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 25',
  'Google Chrome': '49.32',
  'Internet Explorer': '23.39',
  Firefox: '18.76',
  Safari: '7.03',
  'Microsoft Edge': '0.04',
  Opera: '1.34',
  Mozilla: '0.11',
  'Other/Unknown': '0'
}, {
  date: '2015 Jun 26',
  'Google Chrome': '49.39',
  'Internet Explorer': '23.11',
  Firefox: '18.84',
  Safari: '7.14',
  'Microsoft Edge': '0.04',
  Opera: '1.37',
  Mozilla: '0.1',
  'Other/Unknown': '0'
}, {
  date: '2015 Jun 27',
  'Google Chrome': '49.77',
  'Internet Explorer': '20.68',
  Firefox: '19.23',
  Safari: '8.46',
  'Microsoft Edge': '0.05',
  Opera: '1.71',
  Mozilla: '0.09',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 28',
  'Google Chrome': '50.07',
  'Internet Explorer': '20.41',
  Firefox: '18.91',
  Safari: '8.77',
  'Microsoft Edge': '0.05',
  Opera: '1.69',
  Mozilla: '0.1',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 29',
  'Google Chrome': '49.32',
  'Internet Explorer': '23.3',
  Firefox: '18.54',
  Safari: '7.4',
  'Microsoft Edge': '0.04',
  Opera: '1.27',
  Mozilla: '0.11',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jun 30',
  'Google Chrome': '49.99',
  'Internet Explorer': '22.94',
  Firefox: '18.45',
  Safari: '7.07',
  'Microsoft Edge': '0.07',
  Opera: '1.32',
  Mozilla: '0.13',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Jul 1',
  'Google Chrome': '50.22',
  'Internet Explorer': '22.79',
  Firefox: '18.46',
  Safari: '6.95',
  'Microsoft Edge': '0.07',
  Opera: '1.37',
  Mozilla: '0.12',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Jul 2',
  'Google Chrome': '50.33',
  'Internet Explorer': '22.59',
  Firefox: '18.74',
  Safari: '6.69',
  'Microsoft Edge': '0.07',
  Opera: '1.45',
  Mozilla: '0.12',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Jul 3',
  'Google Chrome': '50.4',
  'Internet Explorer': '21.29',
  Firefox: '19.28',
  Safari: '7.28',
  'Microsoft Edge': '0.08',
  Opera: '1.54',
  Mozilla: '0.11',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Jul 4',
  'Google Chrome': '50.83',
  'Internet Explorer': '20.02',
  Firefox: '19.12',
  Safari: '7.99',
  'Microsoft Edge': '0.08',
  Opera: '1.82',
  Mozilla: '0.11',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Jul 5',
  'Google Chrome': '51.6',
  'Internet Explorer': '19.6',
  Firefox: '18.43',
  Safari: '8.58',
  'Microsoft Edge': '0.07',
  Opera: '1.62',
  Mozilla: '0.1',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 6',
  'Google Chrome': '50.53',
  'Internet Explorer': '22.64',
  Firefox: '18.04',
  Safari: '7.33',
  'Microsoft Edge': '0.07',
  Opera: '1.27',
  Mozilla: '0.11',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 7',
  'Google Chrome': '50.01',
  'Internet Explorer': '23.02',
  Firefox: '18.32',
  Safari: '7.25',
  'Microsoft Edge': '0.07',
  Opera: '1.25',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 8',
  'Google Chrome': '49.52',
  'Internet Explorer': '23.17',
  Firefox: '18.55',
  Safari: '7.31',
  'Microsoft Edge': '0.07',
  Opera: '1.32',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 9',
  'Google Chrome': '49.31',
  'Internet Explorer': '23.24',
  Firefox: '18.7',
  Safari: '7.22',
  'Microsoft Edge': '0.08',
  Opera: '1.39',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 10',
  'Google Chrome': '48.4',
  'Internet Explorer': '23.68',
  Firefox: '18.97',
  Safari: '7.37',
  'Microsoft Edge': '0.09',
  Opera: '1.43',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 11',
  'Google Chrome': '48.87',
  'Internet Explorer': '21.22',
  Firefox: '19.33',
  Safari: '8.65',
  'Microsoft Edge': '0.1',
  Opera: '1.76',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 12',
  'Google Chrome': '49.4',
  'Internet Explorer': '20.87',
  Firefox: '18.84',
  Safari: '8.99',
  'Microsoft Edge': '0.09',
  Opera: '1.73',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 13',
  'Google Chrome': '49.12',
  'Internet Explorer': '23.35',
  Firefox: '18.49',
  Safari: '7.51',
  'Microsoft Edge': '0.07',
  Opera: '1.39',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 14',
  'Google Chrome': '49.36',
  'Internet Explorer': '23.2',
  Firefox: '18.48',
  Safari: '7.48',
  'Microsoft Edge': '0.08',
  Opera: '1.35',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 15',
  'Google Chrome': '48.88',
  'Internet Explorer': '23.73',
  Firefox: '18.46',
  Safari: '7.44',
  'Microsoft Edge': '0.09',
  Opera: '1.34',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 16',
  'Google Chrome': '48.76',
  'Internet Explorer': '23.77',
  Firefox: '18.5',
  Safari: '7.43',
  'Microsoft Edge': '0.09',
  Opera: '1.4',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 17',
  'Google Chrome': '47.58',
  'Internet Explorer': '24.54',
  Firefox: '18.76',
  Safari: '7.53',
  'Microsoft Edge': '0.09',
  Opera: '1.43',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 18',
  'Google Chrome': '48.06',
  'Internet Explorer': '21.89',
  Firefox: '19.14',
  Safari: '8.99',
  'Microsoft Edge': '0.11',
  Opera: '1.74',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 19',
  'Google Chrome': '48.39',
  'Internet Explorer': '21.5',
  Firefox: '19',
  Safari: '9.23',
  'Microsoft Edge': '0.11',
  Opera: '1.7',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 20',
  'Google Chrome': '48.32',
  'Internet Explorer': '23.91',
  Firefox: '18.62',
  Safari: '7.68',
  'Microsoft Edge': '0.09',
  Opera: '1.32',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 21',
  'Google Chrome': '47.81',
  'Internet Explorer': '24.98',
  Firefox: '18.34',
  Safari: '7.52',
  'Microsoft Edge': '0.08',
  Opera: '1.2',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 22',
  'Google Chrome': '48.28',
  'Internet Explorer': '24.4',
  Firefox: '18.45',
  Safari: '7.44',
  'Microsoft Edge': '0.08',
  Opera: '1.29',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 23',
  'Google Chrome': '48.41',
  'Internet Explorer': '24.2',
  Firefox: '18.57',
  Safari: '7.35',
  'Microsoft Edge': '0.08',
  Opera: '1.31',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 24',
  'Google Chrome': '48.22',
  'Internet Explorer': '23.96',
  Firefox: '18.67',
  Safari: '7.55',
  'Microsoft Edge': '0.1',
  Opera: '1.42',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 25',
  'Google Chrome': '48.11',
  'Internet Explorer': '21.86',
  Firefox: '19.28',
  Safari: '8.88',
  'Microsoft Edge': '0.1',
  Opera: '1.65',
  Mozilla: '0.08',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Jul 26',
  'Google Chrome': '48.27',
  'Internet Explorer': '21.57',
  Firefox: '19',
  Safari: '9.38',
  'Microsoft Edge': '0.11',
  Opera: '1.59',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 27',
  'Google Chrome': '48.5',
  'Internet Explorer': '23.72',
  Firefox: '18.59',
  Safari: '7.76',
  'Microsoft Edge': '0.1',
  Opera: '1.27',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Jul 28',
  'Google Chrome': '48.48',
  'Internet Explorer': '23.95',
  Firefox: '18.46',
  Safari: '7.61',
  'Microsoft Edge': '0.12',
  Opera: '1.31',
  Mozilla: '0.07',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Jul 29',
  'Google Chrome': '48.33',
  'Internet Explorer': '23.77',
  Firefox: '18.56',
  Safari: '7.51',
  'Microsoft Edge': '0.42',
  Opera: '1.33',
  Mozilla: '0.06',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Jul 30',
  'Google Chrome': '47.8',
  'Internet Explorer': '24.06',
  Firefox: '18.6',
  Safari: '7.46',
  'Microsoft Edge': '0.7',
  Opera: '1.3',
  Mozilla: '0.07',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Jul 31',
  'Google Chrome': '48.41',
  'Internet Explorer': '22.9',
  Firefox: '18.88',
  Safari: '7.48',
  'Microsoft Edge': '0.84',
  Opera: '1.41',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 1',
  'Google Chrome': '48.8',
  'Internet Explorer': '20.29',
  Firefox: '19.16',
  Safari: '8.75',
  'Microsoft Edge': '1.16',
  Opera: '1.75',
  Mozilla: '0.07',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Aug 2',
  'Google Chrome': '48.95',
  'Internet Explorer': '20.27',
  Firefox: '18.81',
  Safari: '9',
  'Microsoft Edge': '1.23',
  Opera: '1.65',
  Mozilla: '0.08',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Aug 3',
  'Google Chrome': '49.07',
  'Internet Explorer': '22.67',
  Firefox: '18.35',
  Safari: '7.59',
  'Microsoft Edge': '0.93',
  Opera: '1.31',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 4',
  'Google Chrome': '49.18',
  'Internet Explorer': '22.72',
  Firefox: '18.31',
  Safari: '7.44',
  'Microsoft Edge': '0.95',
  Opera: '1.32',
  Mozilla: '0.07',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Aug 5',
  'Google Chrome': '49.56',
  'Internet Explorer': '22.37',
  Firefox: '18.28',
  Safari: '7.38',
  'Microsoft Edge': '0.99',
  Opera: '1.34',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 6',
  'Google Chrome': '49.77',
  'Internet Explorer': '21.81',
  Firefox: '18.28',
  Safari: '7.67',
  'Microsoft Edge': '1.12',
  Opera: '1.28',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 7',
  'Google Chrome': '48.73',
  'Internet Explorer': '22.63',
  Firefox: '18.42',
  Safari: '7.5',
  'Microsoft Edge': '1.26',
  Opera: '1.39',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 8',
  'Google Chrome': '49.6',
  'Internet Explorer': '18.97',
  Firefox: '19.11',
  Safari: '8.79',
  'Microsoft Edge': '1.69',
  Opera: '1.74',
  Mozilla: '0.09',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Aug 9',
  'Google Chrome': '49.68',
  'Internet Explorer': '18.85',
  Firefox: '18.71',
  Safari: '9.23',
  'Microsoft Edge': '1.72',
  Opera: '1.72',
  Mozilla: '0.08',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Aug 10',
  'Google Chrome': '49.11',
  'Internet Explorer': '22.54',
  Firefox: '17.97',
  Safari: '7.64',
  'Microsoft Edge': '1.28',
  Opera: '1.39',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 11',
  'Google Chrome': '49.21',
  'Internet Explorer': '22.59',
  Firefox: '17.96',
  Safari: '7.56',
  'Microsoft Edge': '1.27',
  Opera: '1.35',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 12',
  'Google Chrome': '48.95',
  'Internet Explorer': '22.68',
  Firefox: '18.21',
  Safari: '7.44',
  'Microsoft Edge': '1.3',
  Opera: '1.35',
  Mozilla: '0.06',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Aug 13',
  'Google Chrome': '49.31',
  'Internet Explorer': '22.28',
  Firefox: '18.19',
  Safari: '7.32',
  'Microsoft Edge': '1.31',
  Opera: '1.51',
  Mozilla: '0.06',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Aug 14',
  'Google Chrome': '49.08',
  'Internet Explorer': '21.98',
  Firefox: '18.38',
  Safari: '7.47',
  'Microsoft Edge': '1.43',
  Opera: '1.56',
  Mozilla: '0.07',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Aug 15',
  'Google Chrome': '49.1',
  'Internet Explorer': '19.47',
  Firefox: '18.77',
  Safari: '8.85',
  'Microsoft Edge': '1.87',
  Opera: '1.86',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 16',
  'Google Chrome': '49.5',
  'Internet Explorer': '19.33',
  Firefox: '18.42',
  Safari: '9.12',
  'Microsoft Edge': '1.83',
  Opera: '1.72',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 17',
  'Google Chrome': '49.42',
  'Internet Explorer': '22.12',
  Firefox: '18.06',
  Safari: '7.5',
  'Microsoft Edge': '1.36',
  Opera: '1.44',
  Mozilla: '0.08',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Aug 18',
  'Google Chrome': '49.46',
  'Internet Explorer': '22.16',
  Firefox: '18.05',
  Safari: '7.43',
  'Microsoft Edge': '1.41',
  Opera: '1.41',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 19',
  'Google Chrome': '49.51',
  'Internet Explorer': '22.21',
  Firefox: '17.93',
  Safari: '7.46',
  'Microsoft Edge': '1.39',
  Opera: '1.42',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 20',
  'Google Chrome': '49.43',
  'Internet Explorer': '22.38',
  Firefox: '17.91',
  Safari: '7.31',
  'Microsoft Edge': '1.42',
  Opera: '1.47',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 21',
  'Google Chrome': '49.44',
  'Internet Explorer': '21.79',
  Firefox: '18.3',
  Safari: '7.39',
  'Microsoft Edge': '1.51',
  Opera: '1.47',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 22',
  'Google Chrome': '49.79',
  'Internet Explorer': '19.29',
  Firefox: '18.51',
  Safari: '8.67',
  'Microsoft Edge': '1.91',
  Opera: '1.74',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 23',
  'Google Chrome': '50.12',
  'Internet Explorer': '18.91',
  Firefox: '18.1',
  Safari: '9.11',
  'Microsoft Edge': '1.89',
  Opera: '1.8',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 24',
  'Google Chrome': '49.34',
  'Internet Explorer': '22.2',
  Firefox: '18.01',
  Safari: '7.62',
  'Microsoft Edge': '1.48',
  Opera: '1.29',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Aug 25',
  'Google Chrome': '49.21',
  'Internet Explorer': '22.51',
  Firefox: '17.91',
  Safari: '7.47',
  'Microsoft Edge': '1.47',
  Opera: '1.38',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 26',
  'Google Chrome': '49.06',
  'Internet Explorer': '22.58',
  Firefox: '17.92',
  Safari: '7.52',
  'Microsoft Edge': '1.47',
  Opera: '1.39',
  Mozilla: '0.04',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 27',
  'Google Chrome': '48.89',
  'Internet Explorer': '22.63',
  Firefox: '17.98',
  Safari: '7.57',
  'Microsoft Edge': '1.51',
  Opera: '1.35',
  Mozilla: '0.06',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Aug 28',
  'Google Chrome': '48.69',
  'Internet Explorer': '22.44',
  Firefox: '18.2',
  Safari: '7.56',
  'Microsoft Edge': '1.61',
  Opera: '1.43',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 29',
  'Google Chrome': '48.64',
  'Internet Explorer': '19.87',
  Firefox: '18.43',
  Safari: '9.16',
  'Microsoft Edge': '2.07',
  Opera: '1.74',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 30',
  'Google Chrome': '49.72',
  'Internet Explorer': '18.84',
  Firefox: '17.88',
  Safari: '9.76',
  'Microsoft Edge': '2.03',
  Opera: '1.69',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Aug 31',
  'Google Chrome': '49.43',
  'Internet Explorer': '21.94',
  Firefox: '17.82',
  Safari: '7.87',
  'Microsoft Edge': '1.57',
  Opera: '1.31',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 1',
  'Google Chrome': '49.65',
  'Internet Explorer': '21.91',
  Firefox: '17.95',
  Safari: '7.52',
  'Microsoft Edge': '1.52',
  Opera: '1.39',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 2',
  'Google Chrome': '49.73',
  'Internet Explorer': '21.85',
  Firefox: '18.02',
  Safari: '7.49',
  'Microsoft Edge': '1.53',
  Opera: '1.3',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 3',
  'Google Chrome': '49.59',
  'Internet Explorer': '21.97',
  Firefox: '17.88',
  Safari: '7.6',
  'Microsoft Edge': '1.58',
  Opera: '1.31',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 4',
  'Google Chrome': '49.52',
  'Internet Explorer': '21.64',
  Firefox: '18.06',
  Safari: '7.62',
  'Microsoft Edge': '1.67',
  Opera: '1.41',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 5',
  'Google Chrome': '50.28',
  'Internet Explorer': '18.93',
  Firefox: '18.19',
  Safari: '8.71',
  'Microsoft Edge': '2.13',
  Opera: '1.69',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 6',
  'Google Chrome': '50.25',
  'Internet Explorer': '18.67',
  Firefox: '18.2',
  Safari: '9.04',
  'Microsoft Edge': '2.09',
  Opera: '1.67',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 7',
  'Google Chrome': '49.83',
  'Internet Explorer': '19.8',
  Firefox: '18.39',
  Safari: '8.63',
  'Microsoft Edge': '1.86',
  Opera: '1.43',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 8',
  'Google Chrome': '50',
  'Internet Explorer': '21.8',
  Firefox: '17.62',
  Safari: '7.66',
  'Microsoft Edge': '1.55',
  Opera: '1.31',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 9',
  'Google Chrome': '49.81',
  'Internet Explorer': '21.89',
  Firefox: '17.58',
  Safari: '7.83',
  'Microsoft Edge': '1.59',
  Opera: '1.25',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 10',
  'Google Chrome': '49.65',
  'Internet Explorer': '21.93',
  Firefox: '17.74',
  Safari: '7.82',
  'Microsoft Edge': '1.6',
  Opera: '1.19',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 11',
  'Google Chrome': '49.75',
  'Internet Explorer': '21.71',
  Firefox: '17.88',
  Safari: '7.64',
  'Microsoft Edge': '1.67',
  Opera: '1.29',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 12',
  'Google Chrome': '49.86',
  'Internet Explorer': '19.04',
  Firefox: '17.96',
  Safari: '9.3',
  'Microsoft Edge': '2.21',
  Opera: '1.56',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 13',
  'Google Chrome': '50.1',
  'Internet Explorer': '18.71',
  Firefox: '17.76',
  Safari: '9.69',
  'Microsoft Edge': '2.19',
  Opera: '1.49',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 14',
  'Google Chrome': '49.91',
  'Internet Explorer': '21.55',
  Firefox: '17.87',
  Safari: '7.74',
  'Microsoft Edge': '1.66',
  Opera: '1.21',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 15',
  'Google Chrome': '50.1',
  'Internet Explorer': '21.34',
  Firefox: '17.96',
  Safari: '7.63',
  'Microsoft Edge': '1.66',
  Opera: '1.25',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 16',
  'Google Chrome': '50.6',
  'Internet Explorer': '21.53',
  Firefox: '17.44',
  Safari: '7.56',
  'Microsoft Edge': '1.59',
  Opera: '1.23',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 17',
  'Google Chrome': '50.59',
  'Internet Explorer': '21.59',
  Firefox: '17.55',
  Safari: '7.37',
  'Microsoft Edge': '1.6',
  Opera: '1.24',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 18',
  'Google Chrome': '50.57',
  'Internet Explorer': '21.75',
  Firefox: '17.43',
  Safari: '7.17',
  'Microsoft Edge': '1.7',
  Opera: '1.32',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Sep 19',
  'Google Chrome': '51.33',
  'Internet Explorer': '18.77',
  Firefox: '17.63',
  Safari: '8.37',
  'Microsoft Edge': '2.17',
  Opera: '1.66',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 20',
  'Google Chrome': '51.41',
  'Internet Explorer': '18.15',
  Firefox: '17.55',
  Safari: '9.09',
  'Microsoft Edge': '2.17',
  Opera: '1.58',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 21',
  'Google Chrome': '50.76',
  'Internet Explorer': '21.38',
  Firefox: '17.41',
  Safari: '7.52',
  'Microsoft Edge': '1.67',
  Opera: '1.22',
  Mozilla: '0.04',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 22',
  'Google Chrome': '50.6',
  'Internet Explorer': '21.48',
  Firefox: '17.55',
  Safari: '7.41',
  'Microsoft Edge': '1.7',
  Opera: '1.22',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Sep 23',
  'Google Chrome': '50.56',
  'Internet Explorer': '21.5',
  Firefox: '17.52',
  Safari: '7.42',
  'Microsoft Edge': '1.71',
  Opera: '1.25',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Sep 24',
  'Google Chrome': '50.66',
  'Internet Explorer': '21.34',
  Firefox: '17.52',
  Safari: '7.45',
  'Microsoft Edge': '1.7',
  Opera: '1.29',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Sep 25',
  'Google Chrome': '50.87',
  'Internet Explorer': '20.84',
  Firefox: '17.84',
  Safari: '7.24',
  'Microsoft Edge': '1.78',
  Opera: '1.39',
  Mozilla: '0.04',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 26',
  'Google Chrome': '51.66',
  'Internet Explorer': '17.77',
  Firefox: '18.01',
  Safari: '8.52',
  'Microsoft Edge': '2.28',
  Opera: '1.71',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 27',
  'Google Chrome': '51.99',
  'Internet Explorer': '17.24',
  Firefox: '17.71',
  Safari: '9.17',
  'Microsoft Edge': '2.25',
  Opera: '1.6',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Sep 28',
  'Google Chrome': '50.77',
  'Internet Explorer': '21.23',
  Firefox: '17.52',
  Safari: '7.49',
  'Microsoft Edge': '1.73',
  Opera: '1.22',
  Mozilla: '0.03',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 29',
  'Google Chrome': '50.75',
  'Internet Explorer': '21.39',
  Firefox: '17.47',
  Safari: '7.38',
  'Microsoft Edge': '1.74',
  Opera: '1.22',
  Mozilla: '0.04',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Sep 30',
  'Google Chrome': '50.8',
  'Internet Explorer': '21.34',
  Firefox: '17.4',
  Safari: '7.46',
  'Microsoft Edge': '1.74',
  Opera: '1.23',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 1',
  'Google Chrome': '50.82',
  'Internet Explorer': '21.39',
  Firefox: '17.19',
  Safari: '7.55',
  'Microsoft Edge': '1.78',
  Opera: '1.24',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 2',
  'Google Chrome': '50.93',
  'Internet Explorer': '21.17',
  Firefox: '17.32',
  Safari: '7.38',
  'Microsoft Edge': '1.86',
  Opera: '1.32',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 3',
  'Google Chrome': '51.69',
  'Internet Explorer': '18.12',
  Firefox: '17.51',
  Safari: '8.61',
  'Microsoft Edge': '2.34',
  Opera: '1.68',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 4',
  'Google Chrome': '52.06',
  'Internet Explorer': '17.59',
  Firefox: '17.18',
  Safari: '9.25',
  'Microsoft Edge': '2.32',
  Opera: '1.58',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 5',
  'Google Chrome': '51.4',
  'Internet Explorer': '20.73',
  Firefox: '17.24',
  Safari: '7.56',
  'Microsoft Edge': '1.81',
  Opera: '1.22',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 6',
  'Google Chrome': '51.81',
  'Internet Explorer': '20.35',
  Firefox: '17.31',
  Safari: '7.46',
  'Microsoft Edge': '1.79',
  Opera: '1.25',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 7',
  'Google Chrome': '51.98',
  'Internet Explorer': '20.18',
  Firefox: '17.29',
  Safari: '7.49',
  'Microsoft Edge': '1.76',
  Opera: '1.27',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 8',
  'Google Chrome': '50.97',
  'Internet Explorer': '20.56',
  Firefox: '17.72',
  Safari: '7.63',
  'Microsoft Edge': '1.79',
  Opera: '1.3',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 9',
  'Google Chrome': '50.94',
  'Internet Explorer': '20.42',
  Firefox: '17.8',
  Safari: '7.52',
  'Microsoft Edge': '1.88',
  Opera: '1.4',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 10',
  'Google Chrome': '51.02',
  'Internet Explorer': '17.74',
  Firefox: '18.26',
  Safari: '8.79',
  'Microsoft Edge': '2.38',
  Opera: '1.75',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 11',
  'Google Chrome': '52.07',
  'Internet Explorer': '16.83',
  Firefox: '17.77',
  Safari: '9.23',
  'Microsoft Edge': '2.32',
  Opera: '1.72',
  Mozilla: '0.04',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Oct 12',
  'Google Chrome': '50.9',
  'Internet Explorer': '19.94',
  Firefox: '17.55',
  Safari: '8.27',
  'Microsoft Edge': '1.94',
  Opera: '1.35',
  Mozilla: '0.04',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Oct 13',
  'Google Chrome': '51.52',
  'Internet Explorer': '19.66',
  Firefox: '17.71',
  Safari: '7.76',
  'Microsoft Edge': '1.78',
  Opera: '1.51',
  Mozilla: '0.04',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Oct 14',
  'Google Chrome': '51.75',
  'Internet Explorer': '19.37',
  Firefox: '17.85',
  Safari: '7.72',
  'Microsoft Edge': '1.82',
  Opera: '1.44',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 15',
  'Google Chrome': '51.37',
  'Internet Explorer': '19.66',
  Firefox: '17.92',
  Safari: '7.68',
  'Microsoft Edge': '1.87',
  Opera: '1.46',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 16',
  'Google Chrome': '51.29',
  'Internet Explorer': '19.56',
  Firefox: '18.06',
  Safari: '7.62',
  'Microsoft Edge': '1.91',
  Opera: '1.52',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 17',
  'Google Chrome': '52.47',
  'Internet Explorer': '15.8',
  Firefox: '18.39',
  Safari: '8.96',
  'Microsoft Edge': '2.46',
  Opera: '1.88',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 18',
  'Google Chrome': '51.88',
  'Internet Explorer': '16.03',
  Firefox: '18.07',
  Safari: '9.75',
  'Microsoft Edge': '2.42',
  Opera: '1.79',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 19',
  'Google Chrome': '51.48',
  'Internet Explorer': '19.69',
  Firefox: '17.68',
  Safari: '7.87',
  'Microsoft Edge': '1.83',
  Opera: '1.41',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 20',
  'Google Chrome': '51.58',
  'Internet Explorer': '19.73',
  Firefox: '17.63',
  Safari: '7.85',
  'Microsoft Edge': '1.8',
  Opera: '1.38',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 21',
  'Google Chrome': '52.04',
  'Internet Explorer': '20.14',
  Firefox: '16.99',
  Safari: '7.63',
  'Microsoft Edge': '1.81',
  Opera: '1.34',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 22',
  'Google Chrome': '51.62',
  'Internet Explorer': '20.1',
  Firefox: '17.28',
  Safari: '7.75',
  'Microsoft Edge': '1.86',
  Opera: '1.35',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 23',
  'Google Chrome': '51.87',
  'Internet Explorer': '19.88',
  Firefox: '17.17',
  Safari: '7.64',
  'Microsoft Edge': '1.94',
  Opera: '1.45',
  Mozilla: '0.04',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Oct 24',
  'Google Chrome': '52.62',
  'Internet Explorer': '16.25',
  Firefox: '17.52',
  Safari: '9.28',
  'Microsoft Edge': '2.5',
  Opera: '1.78',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 25',
  'Google Chrome': '52.64',
  'Internet Explorer': '16.1',
  Firefox: '17.4',
  Safari: '9.69',
  'Microsoft Edge': '2.46',
  Opera: '1.67',
  Mozilla: '0.04',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Oct 26',
  'Google Chrome': '52.29',
  'Internet Explorer': '19.72',
  Firefox: '17',
  Safari: '7.81',
  'Microsoft Edge': '1.88',
  Opera: '1.26',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 27',
  'Google Chrome': '51.88',
  'Internet Explorer': '20.08',
  Firefox: '17.1',
  Safari: '7.73',
  'Microsoft Edge': '1.87',
  Opera: '1.3',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 28',
  'Google Chrome': '51.92',
  'Internet Explorer': '19.48',
  Firefox: '17.49',
  Safari: '7.86',
  'Microsoft Edge': '1.93',
  Opera: '1.28',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 29',
  'Google Chrome': '51.56',
  'Internet Explorer': '19.74',
  Firefox: '17.83',
  Safari: '7.64',
  'Microsoft Edge': '1.9',
  Opera: '1.31',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 30',
  'Google Chrome': '51.8',
  'Internet Explorer': '19.42',
  Firefox: '17.86',
  Safari: '7.5',
  'Microsoft Edge': '1.99',
  Opera: '1.38',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2015 Oct 31',
  'Google Chrome': '52.67',
  'Internet Explorer': '16.22',
  Firefox: '18.22',
  Safari: '8.62',
  'Microsoft Edge': '2.46',
  Opera: '1.76',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 1',
  'Google Chrome': '52.4',
  'Internet Explorer': '15.92',
  Firefox: '17.77',
  Safari: '9.7',
  'Microsoft Edge': '2.48',
  Opera: '1.62',
  Mozilla: '0.1',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 2',
  'Google Chrome': '51.9',
  'Internet Explorer': '19.56',
  Firefox: '17.26',
  Safari: '7.93',
  'Microsoft Edge': '1.96',
  Opera: '1.32',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 3',
  'Google Chrome': '51.95',
  'Internet Explorer': '19.83',
  Firefox: '17.41',
  Safari: '7.53',
  'Microsoft Edge': '1.89',
  Opera: '1.33',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 4',
  'Google Chrome': '52.25',
  'Internet Explorer': '19.61',
  Firefox: '17.24',
  Safari: '7.57',
  'Microsoft Edge': '1.83',
  Opera: '1.44',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 5',
  'Google Chrome': '51.98',
  'Internet Explorer': '19.9',
  Firefox: '17.2',
  Safari: '7.63',
  'Microsoft Edge': '1.87',
  Opera: '1.35',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 6',
  'Google Chrome': '51.38',
  'Internet Explorer': '20.09',
  Firefox: '17.39',
  Safari: '7.74',
  'Microsoft Edge': '1.97',
  Opera: '1.36',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 7',
  'Google Chrome': '52.45',
  'Internet Explorer': '16.46',
  Firefox: '17.38',
  Safari: '9.32',
  'Microsoft Edge': '2.6',
  Opera: '1.7',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Nov 8',
  'Google Chrome': '52.61',
  'Internet Explorer': '16.12',
  Firefox: '16.97',
  Safari: '9.97',
  'Microsoft Edge': '2.55',
  Opera: '1.69',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Nov 9',
  'Google Chrome': '51.65',
  'Internet Explorer': '20.18',
  Firefox: '16.67',
  Safari: '8.21',
  'Microsoft Edge': '1.96',
  Opera: '1.28',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 10',
  'Google Chrome': '51.95',
  'Internet Explorer': '19.99',
  Firefox: '16.46',
  Safari: '8.29',
  'Microsoft Edge': '1.95',
  Opera: '1.29',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Nov 11',
  'Google Chrome': '52.74',
  'Internet Explorer': '18.91',
  Firefox: '16.38',
  Safari: '8.49',
  'Microsoft Edge': '2.04',
  Opera: '1.37',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Nov 12',
  'Google Chrome': '52.16',
  'Internet Explorer': '19.88',
  Firefox: '16.54',
  Safari: '8.03',
  'Microsoft Edge': '1.99',
  Opera: '1.35',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 13',
  'Google Chrome': '52.17',
  'Internet Explorer': '19.7',
  Firefox: '16.65',
  Safari: '7.98',
  'Microsoft Edge': '2.09',
  Opera: '1.35',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 14',
  'Google Chrome': '52.81',
  'Internet Explorer': '16.25',
  Firefox: '17.02',
  Safari: '9.53',
  'Microsoft Edge': '2.71',
  Opera: '1.62',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 15',
  'Google Chrome': '52.89',
  'Internet Explorer': '15.65',
  Firefox: '16.97',
  Safari: '10.25',
  'Microsoft Edge': '2.62',
  Opera: '1.56',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 16',
  'Google Chrome': '52.68',
  'Internet Explorer': '19.59',
  Firefox: '16.23',
  Safari: '8.22',
  'Microsoft Edge': '2',
  Opera: '1.24',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 17',
  'Google Chrome': '52.6',
  'Internet Explorer': '19.62',
  Firefox: '16.38',
  Safari: '8.12',
  'Microsoft Edge': '1.96',
  Opera: '1.28',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 18',
  'Google Chrome': '52.35',
  'Internet Explorer': '19.79',
  Firefox: '16.39',
  Safari: '8.12',
  'Microsoft Edge': '1.99',
  Opera: '1.33',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 19',
  'Google Chrome': '52.37',
  'Internet Explorer': '19.81',
  Firefox: '16.46',
  Safari: '8',
  'Microsoft Edge': '2',
  Opera: '1.31',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 20',
  'Google Chrome': '52.2',
  'Internet Explorer': '19.55',
  Firefox: '16.74',
  Safari: '7.96',
  'Microsoft Edge': '2.11',
  Opera: '1.39',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 21',
  'Google Chrome': '52.7',
  'Internet Explorer': '16.03',
  Firefox: '17.04',
  Safari: '9.83',
  'Microsoft Edge': '2.72',
  Opera: '1.62',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 22',
  'Google Chrome': '52.72',
  'Internet Explorer': '15.75',
  Firefox: '16.9',
  Safari: '10.36',
  'Microsoft Edge': '2.68',
  Opera: '1.54',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 23',
  'Google Chrome': '52.35',
  'Internet Explorer': '19.53',
  Firefox: '16.53',
  Safari: '8.21',
  'Microsoft Edge': '2.04',
  Opera: '1.28',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 24',
  'Google Chrome': '52.33',
  'Internet Explorer': '19.53',
  Firefox: '16.75',
  Safari: '7.96',
  'Microsoft Edge': '2.03',
  Opera: '1.34',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 25',
  'Google Chrome': '52.34',
  'Internet Explorer': '19.04',
  Firefox: '17.02',
  Safari: '7.82',
  'Microsoft Edge': '2.07',
  Opera: '1.64',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 26',
  'Google Chrome': '53.29',
  'Internet Explorer': '17.12',
  Firefox: '17.42',
  Safari: '8.12',
  'Microsoft Edge': '2.27',
  Opera: '1.7',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 27',
  'Google Chrome': '52.46',
  'Internet Explorer': '17.83',
  Firefox: '17.12',
  Safari: '8.58',
  'Microsoft Edge': '2.49',
  Opera: '1.46',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 28',
  'Google Chrome': '52.84',
  'Internet Explorer': '15.74',
  Firefox: '17.03',
  Safari: '9.7',
  'Microsoft Edge': '2.97',
  Opera: '1.67',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 29',
  'Google Chrome': '52.8',
  'Internet Explorer': '15.57',
  Firefox: '16.72',
  Safari: '10.32',
  'Microsoft Edge': '2.91',
  Opera: '1.63',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Nov 30',
  'Google Chrome': '52.71',
  'Internet Explorer': '19.08',
  Firefox: '16.21',
  Safari: '8.49',
  'Microsoft Edge': '2.22',
  Opera: '1.24',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 1',
  'Google Chrome': '52.41',
  'Internet Explorer': '19.62',
  Firefox: '16.28',
  Safari: '8.11',
  'Microsoft Edge': '2.25',
  Opera: '1.29',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 2',
  'Google Chrome': '52.64',
  'Internet Explorer': '19.41',
  Firefox: '16.18',
  Safari: '8.1',
  'Microsoft Edge': '2.31',
  Opera: '1.31',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 3',
  'Google Chrome': '52.37',
  'Internet Explorer': '19.3',
  Firefox: '16.4',
  Safari: '8.23',
  'Microsoft Edge': '2.31',
  Opera: '1.35',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 4',
  'Google Chrome': '52.15',
  'Internet Explorer': '19.2',
  Firefox: '16.72',
  Safari: '7.98',
  'Microsoft Edge': '2.46',
  Opera: '1.44',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 5',
  'Google Chrome': '52.15',
  'Internet Explorer': '15.77',
  Firefox: '17.33',
  Safari: '9.82',
  'Microsoft Edge': '3.18',
  Opera: '1.68',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 6',
  'Google Chrome': '52.92',
  'Internet Explorer': '15.33',
  Firefox: '16.65',
  Safari: '10.31',
  'Microsoft Edge': '3.06',
  Opera: '1.67',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 7',
  'Google Chrome': '52.59',
  'Internet Explorer': '19.15',
  Firefox: '16.25',
  Safari: '8.36',
  'Microsoft Edge': '2.34',
  Opera: '1.26',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 8',
  'Google Chrome': '52.57',
  'Internet Explorer': '19.12',
  Firefox: '16.38',
  Safari: '8.21',
  'Microsoft Edge': '2.38',
  Opera: '1.28',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 9',
  'Google Chrome': '52.47',
  'Internet Explorer': '19.47',
  Firefox: '16.41',
  Safari: '7.98',
  'Microsoft Edge': '2.31',
  Opera: '1.3',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 10',
  'Google Chrome': '52.22',
  'Internet Explorer': '20',
  Firefox: '16.4',
  Safari: '7.73',
  'Microsoft Edge': '2.35',
  Opera: '1.25',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 11',
  'Google Chrome': '51.97',
  'Internet Explorer': '19.54',
  Firefox: '16.69',
  Safari: '7.91',
  'Microsoft Edge': '2.49',
  Opera: '1.34',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 12',
  'Google Chrome': '52.03',
  'Internet Explorer': '16.49',
  Firefox: '16.84',
  Safari: '9.67',
  'Microsoft Edge': '3.18',
  Opera: '1.68',
  Mozilla: '0.1',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 13',
  'Google Chrome': '52.17',
  'Internet Explorer': '16.22',
  Firefox: '16.61',
  Safari: '10.18',
  'Microsoft Edge': '3.16',
  Opera: '1.57',
  Mozilla: '0.08',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 14',
  'Google Chrome': '51.48',
  'Internet Explorer': '20.71',
  Firefox: '16.04',
  Safari: '8.12',
  'Microsoft Edge': '2.39',
  Opera: '1.21',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 15',
  'Google Chrome': '51.64',
  'Internet Explorer': '20.54',
  Firefox: '16.11',
  Safari: '8.05',
  'Microsoft Edge': '2.37',
  Opera: '1.23',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 16',
  'Google Chrome': '51.32',
  'Internet Explorer': '21.04',
  Firefox: '16.04',
  Safari: '7.9',
  'Microsoft Edge': '2.45',
  Opera: '1.2',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 17',
  'Google Chrome': '51.03',
  'Internet Explorer': '21.29',
  Firefox: '16.13',
  Safari: '7.77',
  'Microsoft Edge': '2.46',
  Opera: '1.25',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 18',
  'Google Chrome': '50.9',
  'Internet Explorer': '20.71',
  Firefox: '16.59',
  Safari: '7.73',
  'Microsoft Edge': '2.6',
  Opera: '1.38',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 19',
  'Google Chrome': '51.36',
  'Internet Explorer': '17.02',
  Firefox: '17.13',
  Safari: '9.36',
  'Microsoft Edge': '3.29',
  Opera: '1.75',
  Mozilla: '0.09',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 20',
  'Google Chrome': '52.24',
  'Internet Explorer': '16.39',
  Firefox: '17.18',
  Safari: '9.39',
  'Microsoft Edge': '3.13',
  Opera: '1.58',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 21',
  'Google Chrome': '51.44',
  'Internet Explorer': '20.62',
  Firefox: '16.64',
  Safari: '7.45',
  'Microsoft Edge': '2.51',
  Opera: '1.28',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 22',
  'Google Chrome': '51.44',
  'Internet Explorer': '20.64',
  Firefox: '16.72',
  Safari: '7.23',
  'Microsoft Edge': '2.58',
  Opera: '1.33',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 23',
  'Google Chrome': '50.69',
  'Internet Explorer': '21.15',
  Firefox: '16.69',
  Safari: '7.24',
  'Microsoft Edge': '2.7',
  Opera: '1.46',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 24',
  'Google Chrome': '50.13',
  'Internet Explorer': '20.14',
  Firefox: '16.68',
  Safari: '8',
  'Microsoft Edge': '3.1',
  Opera: '1.85',
  Mozilla: '0.08',
  'Other/Unknown': '0.02'
}, {
  date: '2015 Dec 25',
  'Google Chrome': '50.55',
  'Internet Explorer': '17.4',
  Firefox: '16.92',
  Safari: '9.19',
  'Microsoft Edge': '3.73',
  Opera: '2.1',
  Mozilla: '0.09',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 26',
  'Google Chrome': '50.12',
  'Internet Explorer': '17.8',
  Firefox: '16.92',
  Safari: '9.44',
  'Microsoft Edge': '3.72',
  Opera: '1.9',
  Mozilla: '0.09',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 27',
  'Google Chrome': '50.68',
  'Internet Explorer': '17.25',
  Firefox: '16.89',
  Safari: '9.55',
  'Microsoft Edge': '3.73',
  Opera: '1.81',
  Mozilla: '0.09',
  'Other/Unknown': '0'
}, {
  date: '2015 Dec 28',
  'Google Chrome': '49.89',
  'Internet Explorer': '21.09',
  Firefox: '16.22',
  Safari: '8.13',
  'Microsoft Edge': '3.21',
  Opera: '1.39',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 29',
  'Google Chrome': '50.27',
  'Internet Explorer': '20.86',
  Firefox: '16.25',
  Safari: '7.95',
  'Microsoft Edge': '3.22',
  Opera: '1.37',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 30',
  'Google Chrome': '49.85',
  'Internet Explorer': '21.28',
  Firefox: '16.26',
  Safari: '7.87',
  'Microsoft Edge': '3.29',
  Opera: '1.37',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2015 Dec 31',
  'Google Chrome': '49.4',
  'Internet Explorer': '20.41',
  Firefox: '16.43',
  Safari: '8.52',
  'Microsoft Edge': '3.57',
  Opera: '1.59',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Jan 1',
  'Google Chrome': '50.07',
  'Internet Explorer': '16.61',
  Firefox: '17.36',
  Safari: '10.09',
  'Microsoft Edge': '4.01',
  Opera: '1.76',
  Mozilla: '0.09',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Jan 2',
  'Google Chrome': '50.72',
  'Internet Explorer': '16.66',
  Firefox: '17.22',
  Safari: '9.67',
  'Microsoft Edge': '3.91',
  Opera: '1.74',
  Mozilla: '0.08',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 3',
  'Google Chrome': '51.23',
  'Internet Explorer': '16.46',
  Firefox: '16.95',
  Safari: '9.87',
  'Microsoft Edge': '3.75',
  Opera: '1.67',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 4',
  'Google Chrome': '51.26',
  'Internet Explorer': '20.36',
  Firefox: '16.35',
  Safari: '7.84',
  'Microsoft Edge': '2.85',
  Opera: '1.29',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 5',
  'Google Chrome': '51.32',
  'Internet Explorer': '20.21',
  Firefox: '16.35',
  Safari: '7.89',
  'Microsoft Edge': '2.79',
  Opera: '1.36',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 6',
  'Google Chrome': '51.81',
  'Internet Explorer': '19.86',
  Firefox: '16.2',
  Safari: '7.93',
  'Microsoft Edge': '2.76',
  Opera: '1.38',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 7',
  'Google Chrome': '51.85',
  'Internet Explorer': '19.82',
  Firefox: '16.31',
  Safari: '7.81',
  'Microsoft Edge': '2.76',
  Opera: '1.4',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 8',
  'Google Chrome': '50.99',
  'Internet Explorer': '20.36',
  Firefox: '16.55',
  Safari: '7.64',
  'Microsoft Edge': '2.9',
  Opera: '1.49',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Jan 9',
  'Google Chrome': '51.5',
  'Internet Explorer': '16.58',
  Firefox: '17.03',
  Safari: '9.38',
  'Microsoft Edge': '3.57',
  Opera: '1.86',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Jan 10',
  'Google Chrome': '51.88',
  'Internet Explorer': '15.91',
  Firefox: '17',
  Safari: '9.79',
  'Microsoft Edge': '3.52',
  Opera: '1.81',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Jan 11',
  'Google Chrome': '51.91',
  'Internet Explorer': '19.73',
  Firefox: '16.3',
  Safari: '7.93',
  'Microsoft Edge': '2.72',
  Opera: '1.37',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 12',
  'Google Chrome': '51.99',
  'Internet Explorer': '19.63',
  Firefox: '16.35',
  Safari: '7.84',
  'Microsoft Edge': '2.74',
  Opera: '1.39',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Jan 13',
  'Google Chrome': '52.31',
  'Internet Explorer': '19.26',
  Firefox: '16.25',
  Safari: '7.95',
  'Microsoft Edge': '2.85',
  Opera: '1.33',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 14',
  'Google Chrome': '52.07',
  'Internet Explorer': '19.64',
  Firefox: '16.1',
  Safari: '7.95',
  'Microsoft Edge': '2.87',
  Opera: '1.32',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 15',
  'Google Chrome': '52.21',
  'Internet Explorer': '18.83',
  Firefox: '16.42',
  Safari: '7.98',
  'Microsoft Edge': '3.04',
  Opera: '1.46',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Jan 16',
  'Google Chrome': '52.65',
  'Internet Explorer': '15.51',
  Firefox: '16.86',
  Safari: '9.5',
  'Microsoft Edge': '3.66',
  Opera: '1.75',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 17',
  'Google Chrome': '52.48',
  'Internet Explorer': '15.69',
  Firefox: '16.61',
  Safari: '9.84',
  'Microsoft Edge': '3.66',
  Opera: '1.66',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 18',
  'Google Chrome': '52.07',
  'Internet Explorer': '18.91',
  Firefox: '16.31',
  Safari: '8.36',
  'Microsoft Edge': '3',
  Opera: '1.3',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 19',
  'Google Chrome': '52.51',
  'Internet Explorer': '19.48',
  Firefox: '16.08',
  Safari: '7.91',
  'Microsoft Edge': '2.72',
  Opera: '1.25',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 20',
  'Google Chrome': '52.82',
  'Internet Explorer': '19.01',
  Firefox: '16.14',
  Safari: '7.97',
  'Microsoft Edge': '2.76',
  Opera: '1.25',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Jan 21',
  'Google Chrome': '52.76',
  'Internet Explorer': '19.26',
  Firefox: '16.12',
  Safari: '7.79',
  'Microsoft Edge': '2.74',
  Opera: '1.3',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 22',
  'Google Chrome': '52.56',
  'Internet Explorer': '18.8',
  Firefox: '16.33',
  Safari: '7.9',
  'Microsoft Edge': '2.95',
  Opera: '1.4',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 23',
  'Google Chrome': '52.38',
  'Internet Explorer': '15.76',
  Firefox: '16.79',
  Safari: '9.5',
  'Microsoft Edge': '3.78',
  Opera: '1.72',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 24',
  'Google Chrome': '53.12',
  'Internet Explorer': '15.22',
  Firefox: '16.55',
  Safari: '9.83',
  'Microsoft Edge': '3.57',
  Opera: '1.65',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 25',
  'Google Chrome': '52.55',
  'Internet Explorer': '19.12',
  Firefox: '16.15',
  Safari: '7.94',
  'Microsoft Edge': '2.89',
  Opera: '1.32',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 26',
  'Google Chrome': '52.45',
  'Internet Explorer': '19.49',
  Firefox: '16.03',
  Safari: '7.83',
  'Microsoft Edge': '2.87',
  Opera: '1.28',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 27',
  'Google Chrome': '52.64',
  'Internet Explorer': '19.42',
  Firefox: '16.06',
  Safari: '7.7',
  'Microsoft Edge': '2.84',
  Opera: '1.29',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 28',
  'Google Chrome': '51.95',
  'Internet Explorer': '19.87',
  Firefox: '16.15',
  Safari: '7.77',
  'Microsoft Edge': '2.92',
  Opera: '1.32',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 29',
  'Google Chrome': '51.03',
  'Internet Explorer': '20',
  Firefox: '16.6',
  Safari: '7.85',
  'Microsoft Edge': '3.07',
  Opera: '1.4',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Jan 30',
  'Google Chrome': '50.97',
  'Internet Explorer': '16.86',
  Firefox: '17.28',
  Safari: '9.36',
  'Microsoft Edge': '3.71',
  Opera: '1.76',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Jan 31',
  'Google Chrome': '52.36',
  'Internet Explorer': '15.75',
  Firefox: '16.71',
  Safari: '9.85',
  'Microsoft Edge': '3.63',
  Opera: '1.63',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 1',
  'Google Chrome': '52.62',
  'Internet Explorer': '19.09',
  Firefox: '16.1',
  Safari: '7.98',
  'Microsoft Edge': '2.88',
  Opera: '1.28',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 2',
  'Google Chrome': '52.48',
  'Internet Explorer': '19.25',
  Firefox: '16.25',
  Safari: '7.83',
  'Microsoft Edge': '2.85',
  Opera: '1.29',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 3',
  'Google Chrome': '52.47',
  'Internet Explorer': '19.16',
  Firefox: '16.16',
  Safari: '7.94',
  'Microsoft Edge': '2.91',
  Opera: '1.31',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 4',
  'Google Chrome': '51.91',
  'Internet Explorer': '19.77',
  Firefox: '16.12',
  Safari: '7.83',
  'Microsoft Edge': '3',
  Opera: '1.31',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 5',
  'Google Chrome': '51.76',
  'Internet Explorer': '19.36',
  Firefox: '16.44',
  Safari: '7.78',
  'Microsoft Edge': '3.15',
  Opera: '1.44',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 6',
  'Google Chrome': '52.11',
  'Internet Explorer': '15.66',
  Firefox: '16.99',
  Safari: '9.43',
  'Microsoft Edge': '3.96',
  Opera: '1.78',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 7',
  'Google Chrome': '52.41',
  'Internet Explorer': '15.14',
  Firefox: '17.12',
  Safari: '9.69',
  'Microsoft Edge': '3.81',
  Opera: '1.77',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 8',
  'Google Chrome': '51.83',
  'Internet Explorer': '19.4',
  Firefox: '15.93',
  Safari: '8.38',
  'Microsoft Edge': '3.13',
  Opera: '1.27',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 9',
  'Google Chrome': '52.01',
  'Internet Explorer': '19.2',
  Firefox: '16.01',
  Safari: '8.25',
  'Microsoft Edge': '3.09',
  Opera: '1.37',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 10',
  'Google Chrome': '52.92',
  'Internet Explorer': '18.52',
  Firefox: '15.94',
  Safari: '8.23',
  'Microsoft Edge': '2.93',
  Opera: '1.41',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 11',
  'Google Chrome': '52.71',
  'Internet Explorer': '18.9',
  Firefox: '16.17',
  Safari: '7.94',
  'Microsoft Edge': '2.88',
  Opera: '1.35',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 12',
  'Google Chrome': '52.37',
  'Internet Explorer': '18.63',
  Firefox: '16.65',
  Safari: '7.84',
  'Microsoft Edge': '3.01',
  Opera: '1.45',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 13',
  'Google Chrome': '52.73',
  'Internet Explorer': '14.69',
  Firefox: '17.33',
  Safari: '9.38',
  'Microsoft Edge': '3.92',
  Opera: '1.87',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 14',
  'Google Chrome': '52.73',
  'Internet Explorer': '14.91',
  Firefox: '17.06',
  Safari: '9.67',
  'Microsoft Edge': '3.85',
  Opera: '1.71',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 15',
  'Google Chrome': '52.68',
  'Internet Explorer': '17.65',
  Firefox: '16.58',
  Safari: '8.54',
  'Microsoft Edge': '3.2',
  Opera: '1.31',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 16',
  'Google Chrome': '53.18',
  'Internet Explorer': '18.24',
  Firefox: '16.32',
  Safari: '7.98',
  'Microsoft Edge': '2.93',
  Opera: '1.3',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 17',
  'Google Chrome': '52.8',
  'Internet Explorer': '18.68',
  Firefox: '16.37',
  Safari: '7.83',
  'Microsoft Edge': '2.97',
  Opera: '1.3',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 18',
  'Google Chrome': '52.85',
  'Internet Explorer': '18.74',
  Firefox: '16.32',
  Safari: '7.74',
  'Microsoft Edge': '2.93',
  Opera: '1.37',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 19',
  'Google Chrome': '52.5',
  'Internet Explorer': '18.49',
  Firefox: '16.7',
  Safari: '7.75',
  'Microsoft Edge': '3.08',
  Opera: '1.43',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 20',
  'Google Chrome': '52.15',
  'Internet Explorer': '15.14',
  Firefox: '17.4',
  Safari: '9.45',
  'Microsoft Edge': '3.89',
  Opera: '1.9',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 21',
  'Google Chrome': '52.1',
  'Internet Explorer': '14.95',
  Firefox: '17.14',
  Safari: '10.17',
  'Microsoft Edge': '3.9',
  Opera: '1.66',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 22',
  'Google Chrome': '52.35',
  'Internet Explorer': '18.91',
  Firefox: '16.41',
  Safari: '8.05',
  'Microsoft Edge': '2.97',
  Opera: '1.25',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 23',
  'Google Chrome': '52',
  'Internet Explorer': '19.19',
  Firefox: '16.48',
  Safari: '8.07',
  'Microsoft Edge': '2.97',
  Opera: '1.25',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 24',
  'Google Chrome': '52.27',
  'Internet Explorer': '18.8',
  Firefox: '16.71',
  Safari: '7.93',
  'Microsoft Edge': '2.96',
  Opera: '1.28',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 25',
  'Google Chrome': '52.2',
  'Internet Explorer': '18.84',
  Firefox: '16.71',
  Safari: '7.91',
  'Microsoft Edge': '2.98',
  Opera: '1.31',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 26',
  'Google Chrome': '52.28',
  'Internet Explorer': '18.37',
  Firefox: '16.95',
  Safari: '7.86',
  'Microsoft Edge': '3.09',
  Opera: '1.39',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 27',
  'Google Chrome': '52.63',
  'Internet Explorer': '14.89',
  Firefox: '17.29',
  Safari: '9.44',
  'Microsoft Edge': '3.9',
  Opera: '1.77',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Feb 28',
  'Google Chrome': '52.74',
  'Internet Explorer': '14.98',
  Firefox: '16.83',
  Safari: '9.89',
  'Microsoft Edge': '3.85',
  Opera: '1.64',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Feb 29',
  'Google Chrome': '53.2',
  'Internet Explorer': '18.53',
  Firefox: '16.18',
  Safari: '7.88',
  'Microsoft Edge': '3',
  Opera: '1.17',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 1',
  'Google Chrome': '53.27',
  'Internet Explorer': '18.36',
  Firefox: '16.23',
  Safari: '7.99',
  'Microsoft Edge': '2.92',
  Opera: '1.18',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 2',
  'Google Chrome': '53.37',
  'Internet Explorer': '18.14',
  Firefox: '16.21',
  Safari: '8.14',
  'Microsoft Edge': '2.89',
  Opera: '1.19',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 3',
  'Google Chrome': '53.03',
  'Internet Explorer': '18.51',
  Firefox: '16.26',
  Safari: '7.98',
  'Microsoft Edge': '2.97',
  Opera: '1.22',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 4',
  'Google Chrome': '52.47',
  'Internet Explorer': '18.63',
  Firefox: '16.48',
  Safari: '7.92',
  'Microsoft Edge': '3.13',
  Opera: '1.32',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 5',
  'Google Chrome': '52.43',
  'Internet Explorer': '14.9',
  Firefox: '17.03',
  Safari: '9.82',
  'Microsoft Edge': '4.04',
  Opera: '1.72',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Mar 6',
  'Google Chrome': '52.86',
  'Internet Explorer': '14.56',
  Firefox: '16.85',
  Safari: '10.1',
  'Microsoft Edge': '3.95',
  Opera: '1.63',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 7',
  'Google Chrome': '52.59',
  'Internet Explorer': '18.9',
  Firefox: '16.25',
  Safari: '7.99',
  'Microsoft Edge': '3.01',
  Opera: '1.22',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 8',
  'Google Chrome': '52.75',
  'Internet Explorer': '18.59',
  Firefox: '16.36',
  Safari: '7.99',
  'Microsoft Edge': '3.01',
  Opera: '1.24',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 9',
  'Google Chrome': '53.16',
  'Internet Explorer': '18.21',
  Firefox: '16.39',
  Safari: '7.89',
  'Microsoft Edge': '3.05',
  Opera: '1.26',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 10',
  'Google Chrome': '52.99',
  'Internet Explorer': '18.49',
  Firefox: '16.45',
  Safari: '7.7',
  'Microsoft Edge': '3.04',
  Opera: '1.28',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 11',
  'Google Chrome': '52.42',
  'Internet Explorer': '18.46',
  Firefox: '16.68',
  Safari: '7.81',
  'Microsoft Edge': '3.22',
  Opera: '1.36',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 12',
  'Google Chrome': '51.86',
  'Internet Explorer': '15.55',
  Firefox: '17.13',
  Safari: '9.46',
  'Microsoft Edge': '4.16',
  Opera: '1.78',
  Mozilla: '0.06',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Mar 13',
  'Google Chrome': '51.95',
  'Internet Explorer': '15.09',
  Firefox: '17.03',
  Safari: '10.04',
  'Microsoft Edge': '4.17',
  Opera: '1.66',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 14',
  'Google Chrome': '51.94',
  'Internet Explorer': '19.4',
  Firefox: '16.23',
  Safari: '8.01',
  'Microsoft Edge': '3.16',
  Opera: '1.21',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 15',
  'Google Chrome': '52.49',
  'Internet Explorer': '18.73',
  Firefox: '16.42',
  Safari: '7.96',
  'Microsoft Edge': '3.11',
  Opera: '1.23',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Mar 16',
  'Google Chrome': '52.41',
  'Internet Explorer': '18.66',
  Firefox: '16.61',
  Safari: '7.85',
  'Microsoft Edge': '3.17',
  Opera: '1.26',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 17',
  'Google Chrome': '52.26',
  'Internet Explorer': '19.15',
  Firefox: '16.53',
  Safari: '7.58',
  'Microsoft Edge': '3.15',
  Opera: '1.29',
  Mozilla: '0.04',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Mar 18',
  'Google Chrome': '51.98',
  'Internet Explorer': '18.86',
  Firefox: '16.76',
  Safari: '7.69',
  'Microsoft Edge': '3.3',
  Opera: '1.37',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 19',
  'Google Chrome': '51.89',
  'Internet Explorer': '15.61',
  Firefox: '17',
  Safari: '9.39',
  'Microsoft Edge': '4.21',
  Opera: '1.79',
  Mozilla: '0.11',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Mar 20',
  'Google Chrome': '51.86',
  'Internet Explorer': '15.29',
  Firefox: '16.87',
  Safari: '9.91',
  'Microsoft Edge': '4.27',
  Opera: '1.71',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Mar 21',
  'Google Chrome': '52.74',
  'Internet Explorer': '18.37',
  Firefox: '16.28',
  Safari: '7.99',
  'Microsoft Edge': '3.22',
  Opera: '1.35',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 22',
  'Google Chrome': '52.93',
  'Internet Explorer': '18.46',
  Firefox: '16.22',
  Safari: '7.76',
  'Microsoft Edge': '3.12',
  Opera: '1.45',
  Mozilla: '0.05',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Mar 23',
  'Google Chrome': '52.65',
  'Internet Explorer': '18.47',
  Firefox: '16.28',
  Safari: '7.76',
  'Microsoft Edge': '3.22',
  Opera: '1.58',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 24',
  'Google Chrome': '52.53',
  'Internet Explorer': '18.25',
  Firefox: '16.21',
  Safari: '8.03',
  'Microsoft Edge': '3.36',
  Opera: '1.58',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 25',
  'Google Chrome': '52.15',
  'Internet Explorer': '16.95',
  Firefox: '16.75',
  Safari: '8.7',
  'Microsoft Edge': '3.76',
  Opera: '1.64',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 26',
  'Google Chrome': '52.24',
  'Internet Explorer': '15.04',
  Firefox: '17.07',
  Safari: '9.52',
  'Microsoft Edge': '4.23',
  Opera: '1.85',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 27',
  'Google Chrome': '52.56',
  'Internet Explorer': '14.27',
  Firefox: '16.98',
  Safari: '10.11',
  'Microsoft Edge': '4.26',
  Opera: '1.78',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 28',
  'Google Chrome': '53.31',
  'Internet Explorer': '17.05',
  Firefox: '16.2',
  Safari: '8.49',
  'Microsoft Edge': '3.48',
  Opera: '1.43',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 29',
  'Google Chrome': '52.98',
  'Internet Explorer': '17.82',
  Firefox: '16.37',
  Safari: '8.09',
  'Microsoft Edge': '3.23',
  Opera: '1.48',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 30',
  'Google Chrome': '53.07',
  'Internet Explorer': '17.84',
  Firefox: '16.37',
  Safari: '7.96',
  'Microsoft Edge': '3.27',
  Opera: '1.45',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Mar 31',
  'Google Chrome': '53.4',
  'Internet Explorer': '17.73',
  Firefox: '16.24',
  Safari: '7.84',
  'Microsoft Edge': '3.3',
  Opera: '1.43',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 1',
  'Google Chrome': '52.72',
  'Internet Explorer': '18.26',
  Firefox: '16.4',
  Safari: '7.72',
  'Microsoft Edge': '3.4',
  Opera: '1.47',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 2',
  'Google Chrome': '52.99',
  'Internet Explorer': '14.44',
  Firefox: '16.96',
  Safari: '9.33',
  'Microsoft Edge': '4.35',
  Opera: '1.87',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 3',
  'Google Chrome': '53.29',
  'Internet Explorer': '14.13',
  Firefox: '16.7',
  Safari: '9.8',
  'Microsoft Edge': '4.2',
  Opera: '1.82',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 4',
  'Google Chrome': '53.65',
  'Internet Explorer': '17.74',
  Firefox: '16.06',
  Safari: '7.79',
  'Microsoft Edge': '3.22',
  Opera: '1.5',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 5',
  'Google Chrome': '53.21',
  'Internet Explorer': '18.25',
  Firefox: '16.05',
  Safari: '7.71',
  'Microsoft Edge': '3.21',
  Opera: '1.54',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 6',
  'Google Chrome': '53.29',
  'Internet Explorer': '18.24',
  Firefox: '16.02',
  Safari: '7.52',
  'Microsoft Edge': '3.28',
  Opera: '1.6',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 7',
  'Google Chrome': '52.77',
  'Internet Explorer': '18.33',
  Firefox: '16.26',
  Safari: '7.56',
  'Microsoft Edge': '3.35',
  Opera: '1.69',
  Mozilla: '0.03',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 8',
  'Google Chrome': '52.41',
  'Internet Explorer': '18.15',
  Firefox: '16.82',
  Safari: '7.65',
  'Microsoft Edge': '3.5',
  Opera: '1.42',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 9',
  'Google Chrome': '52.03',
  'Internet Explorer': '15.11',
  Firefox: '17.19',
  Safari: '9.37',
  'Microsoft Edge': '4.48',
  Opera: '1.77',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 10',
  'Google Chrome': '52.62',
  'Internet Explorer': '14.85',
  Firefox: '16.78',
  Safari: '9.63',
  'Microsoft Edge': '4.42',
  Opera: '1.66',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 11',
  'Google Chrome': '52.54',
  'Internet Explorer': '18.79',
  Firefox: '16.17',
  Safari: '7.73',
  'Microsoft Edge': '3.37',
  Opera: '1.34',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 12',
  'Google Chrome': '53.2',
  'Internet Explorer': '18.04',
  Firefox: '16.1',
  Safari: '7.61',
  'Microsoft Edge': '3.34',
  Opera: '1.65',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 13',
  'Google Chrome': '53.01',
  'Internet Explorer': '17.89',
  Firefox: '16.22',
  Safari: '7.67',
  'Microsoft Edge': '3.39',
  Opera: '1.72',
  Mozilla: '0.1',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 14',
  'Google Chrome': '53.2',
  'Internet Explorer': '18.06',
  Firefox: '16.08',
  Safari: '7.53',
  'Microsoft Edge': '3.3',
  Opera: '1.68',
  Mozilla: '0.15',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 15',
  'Google Chrome': '52.96',
  'Internet Explorer': '17.82',
  Firefox: '16.32',
  Safari: '7.52',
  'Microsoft Edge': '3.46',
  Opera: '1.81',
  Mozilla: '0.12',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 16',
  'Google Chrome': '52.52',
  'Internet Explorer': '14.52',
  Firefox: '17.23',
  Safari: '9.32',
  'Microsoft Edge': '4.47',
  Opera: '1.81',
  Mozilla: '0.13',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 17',
  'Google Chrome': '52.71',
  'Internet Explorer': '14.25',
  Firefox: '16.94',
  Safari: '9.9',
  'Microsoft Edge': '4.4',
  Opera: '1.7',
  Mozilla: '0.09',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 18',
  'Google Chrome': '53.22',
  'Internet Explorer': '17.85',
  Firefox: '16.08',
  Safari: '7.79',
  'Microsoft Edge': '3.33',
  Opera: '1.66',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 19',
  'Google Chrome': '53.51',
  'Internet Explorer': '17.76',
  Firefox: '16.19',
  Safari: '7.77',
  'Microsoft Edge': '3.27',
  Opera: '1.43',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 20',
  'Google Chrome': '53.62',
  'Internet Explorer': '17.44',
  Firefox: '16.12',
  Safari: '7.81',
  'Microsoft Edge': '3.36',
  Opera: '1.57',
  Mozilla: '0.08',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 21',
  'Google Chrome': '53.4',
  'Internet Explorer': '17.94',
  Firefox: '15.94',
  Safari: '7.59',
  'Microsoft Edge': '3.47',
  Opera: '1.6',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 22',
  'Google Chrome': '52.62',
  'Internet Explorer': '18.57',
  Firefox: '16.13',
  Safari: '7.55',
  'Microsoft Edge': '3.61',
  Opera: '1.46',
  Mozilla: '0.08',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 23',
  'Google Chrome': '52.42',
  'Internet Explorer': '14.46',
  Firefox: '17.12',
  Safari: '9.44',
  'Microsoft Edge': '4.72',
  Opera: '1.75',
  Mozilla: '0.09',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 24',
  'Google Chrome': '53.43',
  'Internet Explorer': '13.62',
  Firefox: '16.79',
  Safari: '9.84',
  'Microsoft Edge': '4.59',
  Opera: '1.66',
  Mozilla: '0.07',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Apr 25',
  'Google Chrome': '53.48',
  'Internet Explorer': '17.72',
  Firefox: '15.98',
  Safari: '7.79',
  'Microsoft Edge': '3.46',
  Opera: '1.5',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 26',
  'Google Chrome': '53.5',
  'Internet Explorer': '17.81',
  Firefox: '16.13',
  Safari: '7.73',
  'Microsoft Edge': '3.4',
  Opera: '1.36',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 27',
  'Google Chrome': '53.68',
  'Internet Explorer': '17.4',
  Firefox: '16.39',
  Safari: '7.76',
  'Microsoft Edge': '3.45',
  Opera: '1.24',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 28',
  'Google Chrome': '53.08',
  'Internet Explorer': '18.16',
  Firefox: '16.31',
  Safari: '7.68',
  'Microsoft Edge': '3.49',
  Opera: '1.21',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Apr 29',
  'Google Chrome': '53.09',
  'Internet Explorer': '17.74',
  Firefox: '16.65',
  Safari: '7.59',
  'Microsoft Edge': '3.56',
  Opera: '1.28',
  Mozilla: '0.09',
  'Other/Unknown': '0'
}, {
  date: '2016 Apr 30',
  'Google Chrome': '53.1',
  'Internet Explorer': '14.09',
  Firefox: '16.82',
  Safari: '9.4',
  'Microsoft Edge': '4.86',
  Opera: '1.65',
  Mozilla: '0.1',
  'Other/Unknown': '0'
}, {
  date: '2016 May 1',
  'Google Chrome': '52.76',
  'Internet Explorer': '13.89',
  Firefox: '16.72',
  Safari: '10.07',
  'Microsoft Edge': '4.88',
  Opera: '1.58',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2016 May 2',
  'Google Chrome': '53.44',
  'Internet Explorer': '17.2',
  Firefox: '16.17',
  Safari: '8.2',
  'Microsoft Edge': '3.67',
  Opera: '1.21',
  Mozilla: '0.1',
  'Other/Unknown': '0'
}, {
  date: '2016 May 3',
  'Google Chrome': '52.97',
  'Internet Explorer': '17.96',
  Firefox: '16.21',
  Safari: '7.85',
  'Microsoft Edge': '3.52',
  Opera: '1.41',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 May 4',
  'Google Chrome': '53.55',
  'Internet Explorer': '17.62',
  Firefox: '16.01',
  Safari: '7.62',
  'Microsoft Edge': '3.57',
  Opera: '1.54',
  Mozilla: '0.08',
  'Other/Unknown': '0'
}, {
  date: '2016 May 5',
  'Google Chrome': '53.76',
  'Internet Explorer': '17.45',
  Firefox: '15.92',
  Safari: '7.6',
  'Microsoft Edge': '3.63',
  Opera: '1.57',
  Mozilla: '0.08',
  'Other/Unknown': '0'
}, {
  date: '2016 May 6',
  'Google Chrome': '52.97',
  'Internet Explorer': '17.61',
  Firefox: '16.28',
  Safari: '7.72',
  'Microsoft Edge': '3.78',
  Opera: '1.55',
  Mozilla: '0.09',
  'Other/Unknown': '0'
}, {
  date: '2016 May 7',
  'Google Chrome': '51.82',
  'Internet Explorer': '14.56',
  Firefox: '17.28',
  Safari: '9.66',
  'Microsoft Edge': '4.86',
  Opera: '1.72',
  Mozilla: '0.1',
  'Other/Unknown': '0'
}, {
  date: '2016 May 8',
  'Google Chrome': '52.46',
  'Internet Explorer': '14.09',
  Firefox: '17.17',
  Safari: '9.79',
  'Microsoft Edge': '4.69',
  Opera: '1.71',
  Mozilla: '0.09',
  'Other/Unknown': '0'
}, {
  date: '2016 May 9',
  'Google Chrome': '53.05',
  'Internet Explorer': '18.2',
  Firefox: '16.1',
  Safari: '7.92',
  'Microsoft Edge': '3.58',
  Opera: '1.08',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 May 10',
  'Google Chrome': '52.83',
  'Internet Explorer': '18.34',
  Firefox: '16.14',
  Safari: '7.77',
  'Microsoft Edge': '3.62',
  Opera: '1.24',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 May 11',
  'Google Chrome': '53.18',
  'Internet Explorer': '17.93',
  Firefox: '16.28',
  Safari: '7.67',
  'Microsoft Edge': '3.6',
  Opera: '1.26',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 May 12',
  'Google Chrome': '52.5',
  'Internet Explorer': '18.46',
  Firefox: '16.41',
  Safari: '7.53',
  'Microsoft Edge': '3.63',
  Opera: '1.4',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 May 13',
  'Google Chrome': '51.81',
  'Internet Explorer': '18.58',
  Firefox: '16.66',
  Safari: '7.6',
  'Microsoft Edge': '3.77',
  Opera: '1.48',
  Mozilla: '0.09',
  'Other/Unknown': '0'
}, {
  date: '2016 May 14',
  'Google Chrome': '50.29',
  'Internet Explorer': '15.33',
  Firefox: '17.99',
  Safari: '9.42',
  'Microsoft Edge': '4.96',
  Opera: '1.91',
  Mozilla: '0.1',
  'Other/Unknown': '0'
}, {
  date: '2016 May 15',
  'Google Chrome': '51.36',
  'Internet Explorer': '14.55',
  Firefox: '17.56',
  Safari: '9.66',
  'Microsoft Edge': '4.91',
  Opera: '1.87',
  Mozilla: '0.08',
  'Other/Unknown': '0'
}, {
  date: '2016 May 16',
  'Google Chrome': '52.3',
  'Internet Explorer': '18.24',
  Firefox: '16.41',
  Safari: '7.76',
  'Microsoft Edge': '3.73',
  Opera: '1.49',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 May 17',
  'Google Chrome': '52.05',
  'Internet Explorer': '18.88',
  Firefox: '16.42',
  Safari: '7.64',
  'Microsoft Edge': '3.57',
  Opera: '1.37',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 May 18',
  'Google Chrome': '51.99',
  'Internet Explorer': '18.87',
  Firefox: '16.43',
  Safari: '7.76',
  'Microsoft Edge': '3.64',
  Opera: '1.24',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 May 19',
  'Google Chrome': '51.67',
  'Internet Explorer': '19.25',
  Firefox: '16.18',
  Safari: '7.96',
  'Microsoft Edge': '3.77',
  Opera: '1.1',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 May 20',
  'Google Chrome': '51.75',
  'Internet Explorer': '18.51',
  Firefox: '16.52',
  Safari: '7.88',
  'Microsoft Edge': '4.09',
  Opera: '1.19',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 May 21',
  'Google Chrome': '51.83',
  'Internet Explorer': '14.13',
  Firefox: '17.6',
  Safari: '9.54',
  'Microsoft Edge': '5.19',
  Opera: '1.62',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 May 22',
  'Google Chrome': '52.46',
  'Internet Explorer': '13.53',
  Firefox: '17.44',
  Safari: '9.82',
  'Microsoft Edge': '5.07',
  Opera: '1.61',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 May 23',
  'Google Chrome': '52.6',
  'Internet Explorer': '18.04',
  Firefox: '16.37',
  Safari: '7.69',
  'Microsoft Edge': '3.95',
  Opera: '1.3',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 May 24',
  'Google Chrome': '52.83',
  'Internet Explorer': '17.85',
  Firefox: '16.46',
  Safari: '7.58',
  'Microsoft Edge': '3.91',
  Opera: '1.32',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 May 25',
  'Google Chrome': '53.16',
  'Internet Explorer': '17.45',
  Firefox: '16.35',
  Safari: '7.6',
  'Microsoft Edge': '3.99',
  Opera: '1.39',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 May 26',
  'Google Chrome': '52.81',
  'Internet Explorer': '17.8',
  Firefox: '16.38',
  Safari: '7.67',
  'Microsoft Edge': '3.95',
  Opera: '1.33',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 May 27',
  'Google Chrome': '52.54',
  'Internet Explorer': '17.27',
  Firefox: '16.86',
  Safari: '7.73',
  'Microsoft Edge': '4.17',
  Opera: '1.35',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 May 28',
  'Google Chrome': '52.58',
  'Internet Explorer': '13.24',
  Firefox: '17.76',
  Safari: '9.27',
  'Microsoft Edge': '5.33',
  Opera: '1.74',
  Mozilla: '0.09',
  'Other/Unknown': '0'
}, {
  date: '2016 May 29',
  'Google Chrome': '52.85',
  'Internet Explorer': '12.95',
  Firefox: '17.78',
  Safari: '9.34',
  'Microsoft Edge': '5.29',
  Opera: '1.72',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 May 30',
  'Google Chrome': '52.89',
  'Internet Explorer': '14.39',
  Firefox: '17.67',
  Safari: '8.53',
  'Microsoft Edge': '4.86',
  Opera: '1.59',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 May 31',
  'Google Chrome': '53.61',
  'Internet Explorer': '17.15',
  Firefox: '16.08',
  Safari: '7.6',
  'Microsoft Edge': '4.13',
  Opera: '1.37',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 1',
  'Google Chrome': '53.58',
  'Internet Explorer': '16.65',
  Firefox: '16.01',
  Safari: '7.99',
  'Microsoft Edge': '4.29',
  Opera: '1.42',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 2',
  'Google Chrome': '52.76',
  'Internet Explorer': '17.4',
  Firefox: '16.35',
  Safari: '7.81',
  'Microsoft Edge': '4.29',
  Opera: '1.33',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 3',
  'Google Chrome': '52.44',
  'Internet Explorer': '16.96',
  Firefox: '16.52',
  Safari: '7.71',
  'Microsoft Edge': '4.6',
  Opera: '1.69',
  Mozilla: '0.08',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 4',
  'Google Chrome': '52.33',
  'Internet Explorer': '12.93',
  Firefox: '17.3',
  Safari: '9.52',
  'Microsoft Edge': '5.88',
  Opera: '1.96',
  Mozilla: '0.08',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 5',
  'Google Chrome': '52.38',
  'Internet Explorer': '12.56',
  Firefox: '17.42',
  Safari: '9.66',
  'Microsoft Edge': '5.76',
  Opera: '2.14',
  Mozilla: '0.08',
  'Other/Unknown': '0.01'
}, {
  date: '2016 Jun 6',
  'Google Chrome': '52.69',
  'Internet Explorer': '17.32',
  Firefox: '16.31',
  Safari: '7.43',
  'Microsoft Edge': '4.18',
  Opera: '2.01',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 7',
  'Google Chrome': '52.95',
  'Internet Explorer': '17.47',
  Firefox: '16.28',
  Safari: '7.65',
  'Microsoft Edge': '4.05',
  Opera: '1.55',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 8',
  'Google Chrome': '52.68',
  'Internet Explorer': '17.66',
  Firefox: '16.3',
  Safari: '7.6',
  'Microsoft Edge': '4.14',
  Opera: '1.56',
  Mozilla: '0.06',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 9',
  'Google Chrome': '52.3',
  'Internet Explorer': '18.02',
  Firefox: '16.25',
  Safari: '7.85',
  'Microsoft Edge': '4.14',
  Opera: '1.38',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 10',
  'Google Chrome': '52.22',
  'Internet Explorer': '17.36',
  Firefox: '16.46',
  Safari: '7.96',
  'Microsoft Edge': '4.44',
  Opera: '1.49',
  Mozilla: '0.07',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 11',
  'Google Chrome': '52.06',
  'Internet Explorer': '13.19',
  Firefox: '17.21',
  Safari: '9.98',
  'Microsoft Edge': '5.78',
  Opera: '1.69',
  Mozilla: '0.09',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 12',
  'Google Chrome': '52.09',
  'Internet Explorer': '12.75',
  Firefox: '16.89',
  Safari: '10.65',
  'Microsoft Edge': '5.96',
  Opera: '1.6',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 13',
  'Google Chrome': '53.18',
  'Internet Explorer': '16.87',
  Firefox: '16.21',
  Safari: '8.15',
  'Microsoft Edge': '4.39',
  Opera: '1.15',
  Mozilla: '0.05',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 14',
  'Google Chrome': '53.45',
  'Internet Explorer': '17.03',
  Firefox: '16.15',
  Safari: '7.8',
  'Microsoft Edge': '4.31',
  Opera: '1.21',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}, {
  date: '2016 Jun 15',
  'Google Chrome': '53.4',
  'Internet Explorer': '18.12',
  Firefox: '16.51',
  Safari: '6.69',
  'Microsoft Edge': '3.84',
  Opera: '1.4',
  Mozilla: '0.04',
  'Other/Unknown': '0'
}];
export default browserUsage;