import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import {
  defaults,
  sizeLarge,
  variantDefault,
  variantDestructive,
  variantPrimary,
  disabledOverrides,
} from '../commonStyles';

const iconMargin = '0.5em';

export const iconButton = css`
  align-items: center;

  ${defaults};

  svg {
    height: 16px;
    width: 16px;
  }

  &.large {
    ${sizeLarge};

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &.default {
    ${variantDefault};
  }

  &.destructive {
    ${variantDestructive};
  }

  &.primary {
    ${variantPrimary};
  }

  // todo: use this rule/selector after global styling removed
  //:not(:disabled):hover {
  //  background-color: #bebebe;
  //}
  ${disabledOverrides};

  &.start {
    svg {
      margin-right: ${iconMargin};
    }
  }

  &.end {
    svg {
      order: 1;
      margin-left: ${iconMargin};
    }
  }
`;

export const StyledIconButton = styled.button`
  // todo: overrides the global button styling
  display: flex !important;

  ${iconButton};
`;

export const StyledIconButtonLink = styled(Link)`
  // todo: overrides the global button styling
  display: inline-flex !important;

  ${iconButton};
`;
