import styled from 'styled-components';

import { Tooltip } from '@visx/visx';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const StyledHorizontalBarChart = styled.div`
  position: relative;

  &.scrollable-vertical {
    max-height: 330px;
  }

  .left-axis-container {
    margin-left: 40px;

    cursor: default;
  }
`;

export const tooltipStyles: any = {
  pointerEvents: 'none',
  position: 'absolute',
  padding: '5px',
  fontSize: TYPOGRAPHY.fontSize.base,
  borderRadius: '5px',

  opacity: 0.9,
  backgroundColor: COLORS.ebony,
  color: COLORS.white,
};

export const StyledTooltip = styled(Tooltip)`
  ${tooltipStyles}
`;
